import { LoadingSpinner } from "@athena/components";
import {
  AdditionalStatus,
  Status,
  withEngineerStatuses,
} from "@athena/server/src/api/types/claimStatuses";
import styled from "@emotion/styled";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { useGridApiRef } from "@mui/x-data-grid";
import { useDebounceFn } from "ahooks";
import { getUnixTime } from "date-fns";
import React, { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { trpc } from "src/lib/api/trpc";
import { useCurrentUserContext } from "src/shared/hooks/useCurrentUserContext";
import {
  FiltersValues,
  NewEngineeringClaimFilters,
} from "../NewEngineeringClaimFilters";
import { EngineeringClaimsTable } from "./components/EngineeringClaimsTable";

import { TRPCRootRouter } from "@athena/server/src/trpc/index";
import { inferProcedureOutput } from "@trpc/server";

type Claim = inferProcedureOutput<TRPCRootRouter["claims"]["getAllClaims"]>[0];
interface ClaimBuckets {
  awaiting: Claim[];
  preparing: Claim[];
  underReview: Claim[];
  approved: Claim[];
}
export function EngineeringClaimsList() {
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const [showFilters, setShowFilters] = useState(false);
  const [filters, setFilters] = useState<FiltersValues>({});
  const [_, setParams] = useSearchParams();
  const tableApiRef = useGridApiRef();
  const { user, organisation } = useCurrentUserContext();

  const { data: engineeringCompanies, isLoading: isEngineeringLoading } =
    trpc.organisations.getEngineeringCompanies.useQuery();

  const onUpdateSearch = useDebounceFn(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setSearchText(e.target.value);
    },
    {
      leading: false,
      maxWait: 333,
    }
  );

  const engineeringClaimsQuery = trpc.claims.getAllClaims.useQuery({
    ...filters,
    search: searchText,
    dates: {
      from: filters.dateFrom ? getUnixTime(filters.dateFrom) : undefined,
      to: filters.dateTo ? getUnixTime(filters.dateTo) : undefined,
    },
  });

  const claims = (engineeringClaimsQuery.data || []).map((x) => ({
    ...x,
    created: new Date(x.created),
    updated: new Date(x.updated),
    insUpdTs: new Date(x.insUpdTs),
    completedAt: x.completedAt,
    firstSiteVisitDate:
      (x.firstSiteVisitDate && new Date(x.firstSiteVisitDate)) || undefined,
    serviceRequestDate:
      (x.serviceRequestDate && new Date(x.serviceRequestDate)) || undefined,
    exportedMaps: [],
  }));

  const isLoading = engineeringClaimsQuery.isLoading || isEngineeringLoading;

  const tableClaims = claims.map((claim) => {
    const engineeringCompany = engineeringCompanies?.find(
      (company) => company.organisationId === claim.organisationId
    );
    return {
      ...claim,
      engineeringCompany: engineeringCompany?.name || "TBC",
    };
  });

  const onExport = () => {
    if (tableApiRef.current) {
      tableApiRef.current.exportDataAsCsv();
    }
  };

  const buckets = engineeringClaimsQuery.data?.reduce(
    (acc, claim) => {
      //annoying..
      const claimWithDate = {
        ...claim,
        created: new Date(claim.created),
        updated: new Date(claim.updated),
        insUpdTs: new Date(claim.insUpdTs),
        completedAt: claim.completedAt,
        serviceRequestDate:
          (claim.serviceRequestDate && new Date(claim.serviceRequestDate)) ||
          undefined,
        firstSiteVisitDate:
          (claim.firstSiteVisitDate && new Date(claim.firstSiteVisitDate)) ||
          undefined,
      };

      if (withEngineerStatuses.includes(claimWithDate.status as Status)) {
        if (
          claim.additionalStatuses?.includes(
            AdditionalStatus.TPARequestedChanges
          )
        ) {
          acc.awaiting.push(claimWithDate);
        } else {
          acc.preparing.push(claimWithDate);
        }
      }

      if (claim.status === Status.UnderClientReview) {
        acc.underReview.push(claimWithDate);
      }
      if (
        [Status.ClientAccepted, Status.Done].includes(claim.status as Status)
      ) {
        acc.approved.push(claimWithDate);
      }
      return acc;
    },
    {
      awaiting: [],
      preparing: [],
      underReview: [],
      approved: [],
    } as ClaimBuckets
  );

  return (
    <>
      <ClaimHeader>
        <div className="claimActions">
          <TextField
            sx={{
              minHeight: "3rem",
              ".MuiOutlinedInput-root ": { height: "100%" },
            }}
            placeholder="Search Claims"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            onChange={onUpdateSearch.run}
            variant="outlined"
          />
          <Button
            variant="outlined"
            onClick={() => setShowFilters(!showFilters)}
            sx={{ width: 100 }}
          >
            Filter
          </Button>
          <Button
            variant="outlined"
            disabled={Object.keys(filters).length === 0 && !searchText}
            onClick={() => {
              setFilters({});
              setSearchText("");
              setParams({});
            }}
            sx={{ width: 100 }}
          >
            Clear
          </Button>
        </div>
        <Button variant="contained" onClick={onExport} sx={{ width: 250 }}>
          Export as CSV
        </Button>
      </ClaimHeader>
      <NewEngineeringClaimFilters
        isOpen={showFilters}
        onChange={(filters) => setFilters(filters)}
        onFiltersClosed={() => setShowFilters(false)}
        activeClaims={claims}
      />
      {isLoading ? (
        <LoadingContainer>
          <LoadingSpinner />
          <p>Loading Claims...</p>
        </LoadingContainer>
      ) : (
        <>
          {organisation?.orgType === "loss-adjuster" && (
            <ClaimStats>
              <div>
                <Link
                  to={"/claims?statusGroup=withEngineer"}
                  onClick={() => {
                    setFilters({
                      statusGroup: "withEngineer",
                      excludeAdditionalStatuses: [
                        AdditionalStatus.TPARequestedChanges,
                      ],
                    });
                    setSearchText("");
                    setParams({
                      statusGroup: "withEngineer",
                      excludeAdditionalStatuses: [
                        AdditionalStatus.TPARequestedChanges,
                      ],
                    });
                  }}
                >
                  With an Engineer
                </Link>
                <h3>{buckets?.preparing.length}</h3>
              </div>
              <div>
                <Link
                  to={"/claims?statuses=Under+Client+Review"}
                  onClick={() => {
                    setFilters({
                      statuses: [Status.UnderClientReview],
                    });
                    setSearchText("");
                    setParams({
                      statuses: "Under Client Review",
                    });
                  }}
                >
                  Under Review
                </Link>
                <h3>{buckets?.underReview.length}</h3>
              </div>
              <div>
                <Link
                  to={
                    "/claims?statusGroup=awaiting&additionalStatuses=TPA+Requested+Changes"
                  }
                  onClick={() => {
                    setFilters({
                      statusGroup: "awaiting",
                      additionalStatuses: [
                        AdditionalStatus.TPARequestedChanges,
                      ],
                    });
                    setSearchText("");
                    setParams({
                      statusGroup: "awaiting",
                      additionalStatuses: "TPA Requested Changes",
                    });
                  }}
                >
                  Awaiting Comment
                </Link>
                <h3>{buckets?.awaiting.length}</h3>
              </div>
              <div>
                <Link
                  to={"/claims?statuses=Done&includeDone=true"}
                  onClick={() => {
                    setFilters({
                      statuses: [Status.Done],
                      includeDone: true,
                    });
                    setSearchText("");
                    setParams({
                      statuses: "Done",
                      includeDone: "true",
                    });
                  }}
                >
                  Approved
                </Link>
                <h3>{buckets?.approved.length}</h3>
              </div>
            </ClaimStats>
          )}

          <EngineeringClaimsTable
            claims={tableClaims}
            pageSize={25}
            tableApiRef={tableApiRef}
          />
        </>
      )}
    </>
  );
}
const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  height: 70vh;
`;

const ClaimHeader = styled.div`
  background-color: white;
  border-bottom: 1px solid lightgray;
  padding: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .claimActions {
    display: flex;
    gap: 1rem;
  }
`;

const ClaimStats = styled.div`
  background-color: #f4f6ff;
  border-bottom: 1px solid lightgray;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  a {
    text-decoration: none;
    color: #0067df;
    font-weight: 500;
  }
  h3 {
    margin: 0;
    font-size: 2rem;
  }
  div {
    flex: 1;
    border-right: 1px solid lightgray;
    padding: 1rem 1.5rem;
  }

  @media (max-width: 800px) {
    h3 {
      font-size: 1.5rem;
    }
    a {
      font-size: 0.8rem;
    }
  }
`;
