import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import HomeIcon from "@mui/icons-material/Home";
import ListIcon from "@mui/icons-material/List";
import MapIcon from "@mui/icons-material/Map";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";
import { useCurrentUser } from "src/shared/hooks/useCurrentUser";

export const LossAdjusterMenu = () => {
  const { userData, isFetched } = useCurrentUser();
  const { user } = userData || {};

  if (!isFetched || !user) {
    return null;
  }

  return (
    <>
      <Link to="/">
        <Tooltip title="Home" placement="right">
          <IconButton
            edge="start"
            aria-label="menu"
            sx={{
              display: "flex",
              width: "4rem",
              height: "3rem",
              alignItems: "center",
              justifyItems: "center",
              margin: 0,

              color: "#0067DF",
            }}
          >
            <HomeIcon fontSize="medium" sx={{}} />
          </IconButton>
        </Tooltip>
      </Link>
      <Link to="/home">
        <Tooltip title="Overview" placement="right">
          <IconButton
            edge="start"
            aria-label="menu"
            sx={{
              display: "flex",
              width: "4rem",
              height: "3rem",
              alignItems: "center",
              justifyItems: "center",
              margin: 0,

              color: "#0067DF",
            }}
          >
            <EqualizerIcon fontSize="medium" sx={{}} />
          </IconButton>
        </Tooltip>
      </Link>
      <Link to="/map">
        <Tooltip title="Spatial Portal" placement="right">
          <IconButton
            edge="start"
            aria-label="menu"
            sx={{
              display: "flex",
              width: "4rem",
              height: "3rem",
              alignItems: "center",
              justifyItems: "center",
              margin: 0,
              color: "#0067DF",
            }}
          >
            <MapIcon fontSize="medium" sx={{}} />
          </IconButton>
        </Tooltip>
      </Link>
      <Link to="/claims?includeDone=true">
        <IconButton
          edge="start"
          aria-label="menu"
          sx={{
            display: "flex",
            width: "4rem",
            height: "3rem",
            alignItems: "center",
            justifyItems: "center",
            margin: 0,
            color: "#0067DF",
          }}
        >
          <ListIcon fontSize="medium" sx={{}} />
        </IconButton>
      </Link>
      {user.roles.includes("admin") && (
        <Link to="/admin/general">
          <IconButton
            edge="start"
            aria-label="menu"
            sx={{
              display: "flex",
              width: "4rem",
              height: "3rem",
              alignItems: "center",
              justifyItems: "center",
              margin: 0,
              color: "#0067DF",
            }}
          >
            <AdminPanelSettingsIcon fontSize="medium" sx={{}} />
          </IconButton>
        </Link>
      )}
    </>
  );
};
