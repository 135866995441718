import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import EventIcon from "@mui/icons-material/Event";
import HomeIcon from "@mui/icons-material/Home";
import ListIcon from "@mui/icons-material/List";
import PolicyIcon from "@mui/icons-material/Policy";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import { useCurrentUser } from "src/shared/hooks/useCurrentUser";

import Tooltip from "@mui/material/Tooltip";

export const InsurerMenu = () => {
  const { userData, isFetched } = useCurrentUser();
  const { user, organisation } = userData || {};

  if (!isFetched || !user) {
    return null;
  }

  return (
    <>
      <Link to="/">
        <Tooltip title="Home" placement="right">
          <IconButton
            edge="start"
            aria-label="menu"
            sx={{
              display: "flex",
              width: "4rem",
              height: "3rem",
              alignItems: "center",
              justifyItems: "center",
              margin: 0,

              color: "#0067DF",
            }}
          >
            <HomeIcon fontSize="medium" sx={{}} />
          </IconButton>
        </Tooltip>
      </Link>
      <Link to="/engineering-claims">
        <IconButton
          edge="start"
          aria-label="menu"
          sx={{
            display: "flex",
            width: "4rem",
            height: "3rem",
            alignItems: "center",
            justifyItems: "center",
            margin: 0,
            color: "#0067DF",
          }}
        >
          <ListIcon fontSize="medium" sx={{}} />
        </IconButton>
      </Link>
      {organisation?.orgType === "insurer" && (
        <>
          <Link to="/insurance-policies">
            <IconButton
              edge="start"
              aria-label="menu"
              sx={{
                display: "flex",
                width: "4rem",
                height: "3rem",
                alignItems: "center",
                justifyItems: "center",
                margin: 0,
                color: "#0067DF",
              }}
            >
              <PolicyIcon fontSize="medium" sx={{}} />
            </IconButton>
          </Link>
          <Link to="/insurance-claims">
            <IconButton
              edge="start"
              aria-label="menu"
              sx={{
                display: "flex",
                width: "4rem",
                height: "3rem",
                alignItems: "center",
                justifyItems: "center",
                margin: 0,
                color: "#0067DF",
              }}
            >
              <ReceiptLongIcon fontSize="medium" sx={{}} />
            </IconButton>
          </Link>
        </>
      )}
      <Link to="/intelligence">
        <IconButton
          edge="start"
          aria-label="menu"
          sx={{
            display: "flex",
            width: "4rem",
            height: "3rem",
            alignItems: "center",
            justifyItems: "center",
            margin: 0,
            color: "#0067DF",
          }}
        >
          <QueryStatsIcon fontSize="medium" sx={{}} />
        </IconButton>
      </Link>
      <Link to="/events">
        <IconButton
          edge="start"
          aria-label="menu"
          sx={{
            display: "flex",
            width: "4rem",
            height: "3rem",
            alignItems: "center",
            justifyItems: "center",
            margin: 0,
            color: "#0067DF",
          }}
        >
          <EventIcon fontSize="medium" sx={{}} />
        </IconButton>
      </Link>
      {user.roles.includes("admin") && (
        <Link to="/admin/users">
          <IconButton
            edge="start"
            aria-label="menu"
            sx={{
              display: "flex",
              width: "4rem",
              height: "3rem",
              alignItems: "center",
              justifyItems: "center",
              margin: 0,
              color: "#0067DF",
            }}
          >
            <AdminPanelSettingsIcon fontSize="medium" sx={{}} />
          </IconButton>
        </Link>
      )}
    </>
  );
};
