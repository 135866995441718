import { ConfirmationDialog } from "@athena/components";
import { Status } from "@athena/server/src/api/types/claimStatuses";
import { acceptedImages } from "@athena/server/src/api/types/mimeTypes";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Paper,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useRef, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { axiosClient } from "src/lib/axiosClient";
import { useCurrentUserContext } from "src/shared/hooks/useCurrentUserContext";
import { useClaimId } from "../hooks/useClaimId";
import { usePresignedUpload } from "../hooks/usePresignedUpload";
import { useOfflineClaim } from "../useOfflineClaim";
import { Report } from "./ClaimReport";

type ClaimReportActionsProps = {
  claimReport?: Report;
  onSaveReport: () => void;
  onDownload: () => void;
  onStatusChange: (status: Partial<Report & { isDone?: boolean }>) => void;
  onPreviewPdf: () => void;
  onVersionChange: (version: string) => void;
  selectedVersion?: string;
  isSubmitting?: boolean;
};

export const ClaimReportActions = (props: ClaimReportActionsProps) => {
  const claimId = useClaimId();
  const { claim } = useOfflineClaim(claimId);
  const {
    claimReport,
    onDownload,
    onSaveReport,
    onStatusChange,
    onVersionChange,
    selectedVersion,
    onPreviewPdf,
    isSubmitting,
  } = props;
  const { user } = useCurrentUserContext();
  const isReviewingEngineer = claim?.reviewingEngineerIds?.includes(
    user.userId
  );

  const isAssessingEngineer = claim?.assignedEngineerIds?.includes(user.userId);
  const [showClientChangesModal, setShowClientChangesModal] = useState(false);
  const fileInput = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);
  const acceptedFiles = ["application/pdf"].concat(acceptedImages);
  const { uploadFile } = usePresignedUpload(acceptedFiles);
  const queryClient = useQueryClient();

  const { mutate: completeClaim } = useMutation(async () => {
    // const res = (
    //   await axiosClient.put(`/claims/${claim?.claimId}/status`, {
    //     status: Status.Done,
    //   })
    // ).data;

    onStatusChange({
      isClientApproved: true,
      isDone: true,
    });

    // return res;
  });

  const uploadAttachment = async () => {
    if (!file) return;

    await uploadFile(
      file,
      async (file, key) => {
        await axiosClient.post(
          `/claims/${claimId}/report/${claimReport?.reportId}/client-request/completed`,
          { fileName: file.name, key }
        );
        queryClient.invalidateQueries({
          queryKey: [`claim/${claimId}/report`],
        });
        queryClient.invalidateQueries({
          queryKey: [`claim${claimId}`],
        });
      },
      "client-request"
    );
  };

  const canCompleteClaim = !!claim && claim.status === Status.ClientAccepted;
  const engineerLA =
    (claim?.lossAdjusterId &&
      claim?.lossAdjusterUserName &&
      !claim?.lossAdjusterUserId) ||
    !claim?.lossAdjusterId;

  return (
    <Paper elevation={4} sx={{ padding: 2, minWidth: 300 }}>
      <input
        type="file"
        accept={acceptedFiles.join(",")}
        name="files"
        onChange={(event) => {
          if (!event.target.files?.length) return;

          setFile(event.target.files[0]);
          //   uploadSignature(event.target.files[0]);
        }}
        ref={fileInput}
        style={{ display: "none" }}
      />
      {showClientChangesModal && (
        <Dialog
          open
          onClose={() => setShowClientChangesModal(false)}
          maxWidth={false}
        >
          <DialogTitle>Client Requested Changes</DialogTitle>
          <DialogContent>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Upload a pdf or image file of requested changes from the client
            </Typography>
            <Stack direction="column" spacing={2}>
              Selected: {file && `${file?.name}`}
              <Button
                variant="contained"
                onClick={() => fileInput.current?.click()}
                sx={{ ml: 1 }}
              >
                {file ? "Choose another file" : "Choose file"}
              </Button>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{ width: 100, height: 48 }}
              onClick={() => {
                uploadAttachment();
              }}
              disabled={!file}
            >
              Save
            </Button>
            <Button
              variant="outlined"
              sx={{ width: 100 }}
              onClick={() => {
                setFile(null);
                setShowClientChangesModal(false);
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Stack>
        <Button
          variant="contained"
          fullWidth
          onClick={() => {
            onSaveReport();
          }}
        >
          Save
        </Button>

        {isReviewingEngineer && claimReport?.isReviewing && (
          <>
            <ConfirmationDialog
              title="Approve Report"
              trigger={
                <Button variant="contained" fullWidth disabled={isSubmitting}>
                  Approve Report
                </Button>
              }
              message={
                <Stack>
                  <span>
                    Are you sure you want to approve this report and submit it
                    to the assigned loss adjuster?
                  </span>
                  {engineerLA && (
                    <Typography variant="body2">
                      The loss adjuster assigned is not on-boarded, this claim
                      will still be managed by the engineer.
                    </Typography>
                  )}
                  <Typography variant="body2">
                    This action is not reversible.
                  </Typography>
                </Stack>
              }
              onConfirm={() =>
                onStatusChange({
                  isReviewing: false,
                  isApproved: true,
                })
              }
            />
            <Button
              variant="outlined"
              fullWidth
              disabled={isSubmitting}
              onClick={() =>
                onStatusChange({
                  isReviewing: false,
                  isApproved: false,
                })
              }
            >
              Request Changes
            </Button>
          </>
        )}
        {claimReport?.isApproved && (
          <>
            {claim?.status !== Status.ClientAccepted &&
              claim?.status !== Status.Done && (
                <Tooltip
                  title={
                    !engineerLA
                      ? "This claim is with the assigned loss adjuster and cannot be approved."
                      : ""
                  }
                >
                  <span>
                    <ConfirmationDialog
                      title="Approve Claim"
                      trigger={
                        <Button
                          disabled={!engineerLA || isSubmitting}
                          variant="contained"
                          fullWidth
                        >
                          Client Approved
                        </Button>
                      }
                      message={`Are you sure you want to complete this claim and approve it?`}
                      onConfirm={() =>
                        onStatusChange({
                          isClientApproved: true,
                        })
                      }
                    />
                  </span>
                </Tooltip>
              )}
            <Tooltip
              title={
                !engineerLA
                  ? "This claim is with the assigned loss adjuster and requested changes cannot be added."
                  : ""
              }
            >
              <span>
                <Button
                  variant="outlined"
                  disabled={!engineerLA || isSubmitting}
                  fullWidth
                  onClick={() => {
                    setShowClientChangesModal(true);
                  }}
                >
                  Add Client Change Request
                </Button>
              </span>
            </Tooltip>

            {(user.roles.includes("coordinator") ||
              user.roles.includes("data-entry")) && (
              <Tooltip
                title={
                  canCompleteClaim
                    ? "Click to complete the claim"
                    : "The claim needs to be in Client Accepted before it can be completed"
                }
              >
                <div>
                  <Button
                    fullWidth
                    onClick={() => completeClaim()}
                    variant="contained"
                    disabled={!canCompleteClaim || isSubmitting}
                  >
                    Complete Claim
                  </Button>
                </div>
              </Tooltip>
            )}
          </>
        )}
        {!claimReport?.isApproved && isAssessingEngineer && (
          <Button
            variant="outlined"
            fullWidth
            disabled={isSubmitting}
            onClick={() =>
              onStatusChange({
                isReviewing: !claimReport?.isReviewing,
                isApproved: false,
              })
            }
          >
            {!claimReport || !claimReport?.isReviewing
              ? "Submit for Review"
              : "Unsubmit for Review"}
          </Button>
        )}
        <ConfirmationDialog
          title="Preview PDF"
          onConfirm={onPreviewPdf}
          message={
            <Typography variant="body1">
              This will generate a temporary preview of the current report, this
              may take a few moments.
            </Typography>
          }
          trigger={
            <Button fullWidth variant="outlined">
              Preview PDF
            </Button>
          }
        ></ConfirmationDialog>

        <Button fullWidth onClick={onDownload} variant="outlined">
          Download Current Version
        </Button>
        <Typography variant="body1">
          Select an older version to download:
        </Typography>
        <Select
          variant="outlined"
          placeholder="Select an older version to download:"
          size="small"
          displayEmpty
          value={selectedVersion}
          onChange={(e) => onVersionChange(e.target.value)}
        >
          {(claimReport?.versions.slice(0, -1) || []).map((version) => {
            return (
              <MenuItem key={version.s3Key} value={version.publicVersion}>
                {mapVersion(version.publicVersion)}
              </MenuItem>
            );
          })}
        </Select>
      </Stack>
    </Paper>
  );
};

export const mapVersion = (version: string) => {
  // given version format of 0.0.1
  // if two leading zeroes, return Internal Draft 1
  // If one leading zero, return Client Draft 1
  // if no leading zeros return final version 1
  const [finalVersion, clientDraftVersion, internalDraftVersion] = version
    .split(".")
    .map(Number);
  if (finalVersion > 0 && clientDraftVersion + internalDraftVersion === 0) {
    return `Final V${finalVersion}.0.0`;
  }
  return `Draft V${finalVersion}.${clientDraftVersion}.${internalDraftVersion}`;
};
