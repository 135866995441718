import { LoadingSpinner } from "@athena/components";
import {
  AdditionalStatus,
  Status,
  withEngineerStatuses,
} from "@athena/server/src/api/types/claimStatuses";
import styled from "@emotion/styled";
import EngineeringIcon from "@mui/icons-material/Engineering";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import ShieldIcon from "@mui/icons-material/Shield";
import { Button, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { PageNameContext } from "src/App";
import { trpc } from "src/lib/api/trpc";
import { useCurrentUserContext } from "src/shared/hooks/useCurrentUserContext";
import { ClaimStatusChips } from "../claims/ClaimStatusChips";
import Menu from "../menu/Menu";

import { TRPCRootRouter } from "@athena/server/src/trpc/index";
import { inferProcedureOutput } from "@trpc/server";

type Claim = inferProcedureOutput<TRPCRootRouter["claims"]["getAllClaims"]>[0];
interface ClaimBuckets {
  awaiting: Claim[];
  preparing: Claim[];
  underReview: Claim[];
  approved: Claim[];
}

export function LossAdjusterHome() {
  const { setPageName } = useContext(PageNameContext);
  useEffect(() => {
    setPageName("Home");
  }, []);
  const { user } = useCurrentUserContext();

  const engineeringClaimsQuery = trpc.claims.getAllClaims.useQuery({
    mineOnly: true,
    includeDone: true,
  });
  const engineeringCompanies =
    trpc.organisations.getEngineeringCompanies.useQuery();

  const buckets = engineeringClaimsQuery.data?.reduce(
    (acc, claim) => {
      //annoying..
      const claimWithDate = {
        ...claim,
        created: new Date(claim.created),
        updated: new Date(claim.updated),
        insUpdTs: new Date(claim.insUpdTs),
        completedAt: claim.completedAt,
        serviceRequestDate:
          (claim.serviceRequestDate && new Date(claim.serviceRequestDate)) ||
          undefined,
        firstSiteVisitDate:
          (claim.firstSiteVisitDate && new Date(claim.firstSiteVisitDate)) ||
          undefined,
      };

      if (withEngineerStatuses.includes(claimWithDate.status as Status)) {
        if (
          claim.additionalStatuses?.includes(
            AdditionalStatus.TPARequestedChanges
          )
        ) {
          acc.awaiting.push(claimWithDate);
        } else {
          acc.preparing.push(claimWithDate);
        }
      }

      if (claim.status === Status.UnderClientReview) {
        acc.underReview.push(claimWithDate);
      }
      if (
        [Status.ClientAccepted, Status.Done].includes(claim.status as Status)
      ) {
        acc.approved.push(claimWithDate);
      }
      return acc;
    },
    {
      awaiting: [],
      preparing: [],
      underReview: [],
      approved: [],
    } as ClaimBuckets
  );

  const findEngineeringCompany = (id: string) =>
    engineeringCompanies.data?.find((company) => company.organisationId === id)
      ?.name;

  return (
    <>
      <Menu>
        {
          <WelcomeContainer>
            <Typography
              variant="h1"
              component="h1"
              sx={{
                borderBottom: "1px solid lightgray",
                marginBottom: "1.5rem",
                pb: "1.5rem",
              }}
            >
              Welcome back, {user.firstName}
            </Typography>
            <StatContainer>
              <Link
                to={
                  "/claims?statusGroup=withEngineer&mineOnly=true&excludeAdditionalStatuses=TPA+Requested+Changes"
                }
              >
                <p>With an Engineer</p>

                <h3>{buckets?.preparing?.length}</h3>

                <p className="link">See your claims</p>
              </Link>
              <Link to={"/claims?statuses=Under+Client+Review&mineOnly=true"}>
                <p>Under Review</p>

                <h3>{buckets?.underReview?.length}</h3>

                <p className="link">See your claims</p>
              </Link>
              <Link
                to={
                  "/claims?statusGroup=awaiting&additionalStatuses=TPA+Requested+Changes&mineOnly=true"
                }
              >
                <p>Awaiting Comment</p>

                <h3>{buckets?.awaiting?.length}</h3>

                <p className="link">See your claims</p>
              </Link>
              <Link to={"/claims?statuses=Done&mineOnly=true&includeDone=true"}>
                <p>Approved</p>

                <h3>{buckets?.approved?.length}</h3>

                <p className="link">See your claims</p>
              </Link>
            </StatContainer>

            <ClaimContainer>
              <Paper
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "fit-content",
                  mt: 4,
                }}
              >
                <PaperHeading>Claims to awaiting your review:</PaperHeading>
                <Box
                  sx={{
                    width: "100%",
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: "1.5rem",
                    alignItems: "center",
                    justifyContent: "center",
                    flex: 1,
                  }}
                >
                  {engineeringClaimsQuery.isLoading ? (
                    <LoadingSpinner />
                  ) : (
                    <>
                      {buckets?.underReview?.map((claim) => (
                        <ClaimBucket
                          key={claim.claimId}
                          claim={claim}
                          company={findEngineeringCompany(
                            claim.organisationId || ""
                          )}
                        />
                      ))}
                      {buckets?.underReview?.length === 0 && (
                        <p>No claims awaiting your review</p>
                      )}
                    </>
                  )}
                </Box>
              </Paper>
            </ClaimContainer>
            <ClaimContainer>
              <Paper
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  height: "fit-content",
                  mt: 4,
                }}
              >
                <PaperHeading>Claims awaiting comment:</PaperHeading>
                <Box
                  sx={{
                    width: "100%",
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: "1.5rem",
                    alignItems: "center",
                    justifyContent: "center",
                    flex: 1,
                  }}
                >
                  {engineeringClaimsQuery.isLoading ? (
                    <LoadingSpinner />
                  ) : (
                    <>
                      {buckets?.awaiting?.map((claim) => (
                        <ClaimBucket
                          key={claim.claimId}
                          claim={claim}
                          company={findEngineeringCompany(
                            claim.organisationId || ""
                          )}
                        />
                      ))}
                      {buckets?.awaiting?.length === 0 && (
                        <p>No claims awaiting comment</p>
                      )}
                    </>
                  )}
                </Box>
              </Paper>
            </ClaimContainer>
          </WelcomeContainer>
        }
      </Menu>
    </>
  );
}

export const ClaimBucket = (props: {
  claim: Claim;
  company: string | undefined;
}) => {
  const { claim, company } = props;
  return (
    <ClaimRequest key={claim.claimId}>
      <ClaimRequestParent>
        <h3>
          <Link to={`/claim/${claim.organisationId}/${claim.claimId}`}>
            Claim {claim.reference}
          </Link>
        </h3>
        <ClaimStatusChips
          status={claim.status as Status}
          additionalStatuses={claim.additionalStatuses || []}
        />
      </ClaimRequestParent>
      <ClaimRequestBody>
        <ClaimRequestInfo>
          <LocationOnOutlinedIcon />
          <p>{claim.location?.address}</p>
        </ClaimRequestInfo>
        <div>
          <ClaimRequestInfoDivider>
            {company && (
              <ClaimRequestInfo>
                <EngineeringIcon />
                <p>{company}</p>
              </ClaimRequestInfo>
            )}

            {claim?.insurer?.name && (
              <ClaimRequestInfo>
                <ShieldIcon />
                <p>{claim?.insurer?.name}</p>
              </ClaimRequestInfo>
            )}
          </ClaimRequestInfoDivider>
        </div>
      </ClaimRequestBody>
      <div></div>

      <ClaimFooter>
        <Link to={`/claim/${claim.organisationId}/${claim.claimId}?page=1`}>
          <Button
            variant="outlined"
            sx={{ minWidth: 200 }}
            onClick={() => undefined}
          >
            <span>Request Changes</span>
          </Button>
        </Link>
        {claim.status === Status.UnderClientReview && (
          <Link to={`/claim/${claim.organisationId}/${claim.claimId}?page=1`}>
            <Button
              variant="contained"
              sx={{ minWidth: 200 }}
              onClick={() => undefined}
            >
              <span>Accept</span>
            </Button>
          </Link>
        )}
      </ClaimFooter>
    </ClaimRequest>
  );
};

const WelcomeContainer = styled.div`
  max-width: 1600px;
  width: 100%;
  margin: 1.5rem auto;
  padding: 0 1.5rem;
`;

const StatContainer = styled.div`
  display: grid;
  margin: 1.5rem 0;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1.5rem;
  a {
    padding: 1.25rem 1.5rem;
    background: #ffffff;
    flex: 1;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.12);
    border-radius: 12px;
  }
  p,
  h3 {
    margin: 0;
    color: #333;
  }
  h3 {
    font-size: 2rem;
    margin-top: 0.5rem;
  }
  p.link {
    border-top: 1px solid lightgray;
    color: #0067df;
    margin-top: 0.5rem;
    padding-top: 1rem;
  }
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
  a:hover {
    .link {
      text-decoration: underline;
    }
  }
`;

const ClaimContainer = styled.div`
  display: grid;

  grid-template-columns: 1fr;
  gap: 1.5rem;

  .MuiPaper-root {
    padding: 1.5rem;
  }
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;
const PaperHeading = styled.h4`
  font-size: 1.25rem;
  font-weight: 600;
  border-bottom: 1px solid lightgray;
  padding-bottom: 1.25rem;
  margin-bottom: 1.25rem;
`;

const ClaimRequest = styled.div`
  border: 1px solid lightgray;
  border-radius: 12px;
`;

const ClaimRequestBody = styled.div`
  display: flex;
  padding: 1rem;
  gap: 1rem;
  flex-direction: column;
`;
const ClaimRequestParent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 1rem;
  border-bottom: 1px solid lightgray;
  align-items: center;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: start;
    gap: 1rem;
  }
`;

const ClaimRequestInfoDivider = styled.div`
  display: flex;
  flex-direction: row;
  gap: 2rem;
  @media (max-width: 600px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

const ClaimRequestInfo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  a {
    font-weight: 500;
  }
`;

const ClaimFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-top: 1px solid lightgray;
  padding: 1rem;
  @media (max-width: 600px) {
    flex-direction: column;
    gap: 1rem;
    button {
      order: 2;
    }
  }
`;
