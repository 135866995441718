import { InputLabel, Switch } from "@mui/material";
import {
  Control,
  Controller,
  FieldValues,
  Path,
  PathValue,
} from "react-hook-form";
import styled from "@emotion/styled";
import { ReactNode } from "react";

interface FormSwitchProps<T extends FieldValues> {
  name: Path<T>;
  control: Control<T>;
  label: string | ReactNode;
  placeholder?: string;
  type?: string;
  defaultValue?: PathValue<T, Path<T>> | undefined;
  direction?: "row" | "column" | "row-reverse" | "column-reverse";
  onChange?: (value: boolean) => void;
}

export function FormSwitch<T extends FieldValues>(props: FormSwitchProps<T>) {
  const {
    control,
    label,
    name,
    placeholder,
    defaultValue,
    direction = "row",
    onChange,
  } = props;
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <SwitchContainer
          style={{
            flexDirection: direction,
            minWidth: "18rem",
            flex: "1",
            flexWrap: "nowrap",
          }}
        >
          {" "}
          <InputLabel
            id={`${name}-label`}
            shrink
            sx={{
              display: "flex",
              whiteSpace: "nowrap",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Switch
              id={name}
              placeholder={placeholder}
              {...field}
              checked={field.value}
              onChange={(e) => {
                field.onChange(e.target.checked);
                onChange?.(e.target.checked);
              }}
            />

            {label}
          </InputLabel>
        </SwitchContainer>
      )}
    />
  );
}

const SwitchContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;
  max-height: 2.5rem;
  flex-wrap: wrap;

  label {
    padding: 0;
    line-height: 1;
  }

  @media (max-width: 600px) {
    max-height: initial;
  }
`;
