import { LoadingSpinner } from "@athena/components";
import styled from "@emotion/styled";
import { Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Document } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { AnnotationData } from "src/modules/claims/claimReport/components/Annotation.tsx";
import { PageContainer } from "src/modules/claims/claimReport/components/PageContainer.tsx";
import { usePdfWorker } from "src/shared/hooks/usePdfWorker.ts";

interface PDFViewerProps {
  PDFLink: string | Blob | undefined;
  annotations: AnnotationData[];
  createAnnotation: (c: AnnotationData) => void;
  setSelectedAnnotation?: (id: string) => void;
  selectedAnnotation?: string;
  watchResize?: boolean;
  annotating?: boolean;
}

export const PDFViewer = (props: PDFViewerProps) => {
  usePdfWorker();
  const {
    PDFLink,
    createAnnotation,
    annotations,
    watchResize,
    annotating = false,
    setSelectedAnnotation,
    selectedAnnotation,
  } = props;
  const [size, setSize] = useState({ width: 0, height: 0 });
  const [numberOfPages, setNumberOfPages] = React.useState(0);
  const [rendering, setRendering] = React.useState(true);

  useEffect(() => {
    const handleResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    if (watchResize) {
      window.addEventListener("resize", handleResize);
      handleResize();
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [watchResize]);

  const CurrentDoc = React.useMemo(
    () => (
      <Document
        file={PDFLink}
        onLoadError={console.error}
        onLoadStart={console.info}
        loading={<div />}
        renderMode="canvas"
        onLoadSuccess={(pdf) => {
          setNumberOfPages(pdf.numPages);
        }}
      >
        <PageWrapper>
          {Array.from({ length: numberOfPages }, (_, i) => i + 1).map(
            (page) => (
              <PageContainer
                key={`page-${page}`}
                page={page}
                annotations={annotations}
                createAnnotation={createAnnotation}
                setSelectedAnnotation={setSelectedAnnotation}
                selectedAnnotation={selectedAnnotation}
                onRenderFinish={() => {
                  setRendering(false);
                }}
                annotating={annotating}
              />
            )
          )}
        </PageWrapper>
      </Document>
    ),
    [
      PDFLink,
      size.height,
      size.width,
      annotations,
      numberOfPages,
      annotating,
      selectedAnnotation,
    ]
  );
  return (
    <>
      <PdfLoaderSkeleton rendering={rendering} />
      {CurrentDoc}
    </>
  );
};

export const PdfLoaderSkeleton = ({ rendering }: { rendering: boolean }) => (
  <PageWrapper style={{ display: !rendering ? "none" : "flex" }}>
    {Array.from({ length: 2 }).map((_, i) => (
      <Paper
        key={`page-${i}`}
        elevation={4}
        sx={{
          flex: "1",
          alignItems: "center",
          flexGrow: 0,
          flexShrink: 0,
          marginBottom: "1rem",
          minWidth: 627,
          height: 866,
        }}
      >
        <LoadingSpinner />
      </Paper>
    ))}
  </PageWrapper>
);

const PageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;
