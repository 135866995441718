import { acceptedAttachments } from "@athena/server/src/api/types/mimeTypes";
import { axiosClient } from "src/lib/axiosClient";
import {
  enqueueErrorSnackbar,
  enqueueSavingSnackbar,
  enqueueSuccessSnackbar,
} from "src/shared/snackbar/SnackbarHelper";
import { useClaimId } from "./useClaimId";

export const usePresignedUpload = (mimeTypes?: string[]) => {
  const attachmentTypes = mimeTypes || acceptedAttachments;
  const claimId = useClaimId();

  const uploadFile = async (
    file: File,
    callback?: (file: File, key: string) => Promise<void>,
    prefix = "file"
  ) => {
    if (!attachmentTypes.includes(file.type)) {
      enqueueErrorSnackbar("Invalid file type. Please try again.");
      return;
    }

    const finishedSaving = enqueueSavingSnackbar();
    try {
      const { signedUrl, key } = (
        await axiosClient.post(`/claims/${claimId}/presigned-upload-url`, {
          fileName: file.name,
          prefix,
        })
      ).data;
      await fetch(signedUrl, {
        method: "PUT",
        body: file,
        headers: {
          "Content-Type": file.type,
        },
      });

      await callback?.(file, key);
    } catch (_) {
      finishedSaving();
      enqueueErrorSnackbar("Something went wrong. Please try again.");
      return;
    }
    finishedSaving();
    enqueueSuccessSnackbar("Uploaded");
  };

  return { uploadFile };
};
