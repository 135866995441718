import styled from "@emotion/styled";

import * as React from "react";
import { useCurrentUser } from "src/shared/hooks/useCurrentUser";
import { Toolbar } from "../../shared/toolbar/Toolbar";

import { Box } from "@mui/system";
import { EngineeringMenu } from "./EngineeringMenu";
import { InsurerMenu } from "./InsurerMenu";
import { LossAdjusterMenu } from "./LossAdjusterMenu";

export default function Menu({
  children,
  hideOverflow = false,
}: {
  children: React.ReactNode;
  hideOverflow?: boolean;
}) {
  const { userData, isFetched } = useCurrentUser();
  const { user, organisation } = userData || {};

  const Menus = {
    engineering: <EngineeringMenu />,
    "loss-adjuster": <LossAdjusterMenu />,
    eqc: <InsurerMenu />,
    insurer: <InsurerMenu />,
  };

  if (!isFetched || !user) {
    return null;
  }

  return (
    <>
      <Toolbar />
      <Main>
        <Sidebar>
          <div className="SidebarChildren">
            {Menus[organisation?.orgType || "engineering"]}
          </div>
        </Sidebar>

        <Box
          sx={{
            backgroundColor: "#ececec",
            overflow: hideOverflow ? "hidden" : "unset",
            position: "relative",
          }}
        >
          {children}
        </Box>
      </Main>
    </>
  );
}

const Sidebar = styled.div`
  border-right: 1px solid lightgray;
  width: auto;
  background-color: white;
  .SidebarChildren {
    position: sticky;
    top: 64px;
  }
  @media (max-width: 960px) {
    display: none;
  }
`;

const Main = styled.div`
  display: grid;
  grid-template-columns: 64px 1fr;
  min-height: calc(100vh - 65px);
  @media (max-width: 960px) {
    grid-template-columns: 1fr;
  }
`;

const Content = styled.div`
  background-color: #ececec;
  position: relative;
`;
