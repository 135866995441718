import { ClaimDetailsSection } from "@athena/components";
import { acceptedAttachments } from "@athena/server/src/api/types/mimeTypes";
import { TRPCRootRouter } from "@athena/server/src/trpc";
import styled from "@emotion/styled";
import { Box, Button, Paper, Stack } from "@mui/material";
import { inferRouterOutputs } from "@trpc/server";
import { enqueueSnackbar } from "notistack";
import { useRef } from "react";
import { useMutation } from "react-query";
import { axiosClient } from "src/lib/axiosClient";
import { config } from "src/lib/config";
import { usePresignedUpload } from "src/modules/claims/hooks/usePresignedUpload";
import { useViewMode } from "src/shared/hooks/useViewMode";
import {
  enqueueErrorSnackbar,
  enqueueSavingSnackbar,
} from "src/shared/snackbar/SnackbarHelper";

interface Props {
  claim: inferRouterOutputs<TRPCRootRouter>["claims"]["getClaim"];
  refetch: () => void;
}

export function Attachments({ claim, refetch }: Props) {
  const { viewMode } = useViewMode();
  const fileInput = useRef<HTMLInputElement>(null);
  const { uploadFile } = usePresignedUpload(acceptedAttachments);

  const { mutate: uploadAttachments } = useMutation(
    async (filesTopload: any) => {
      if (!filesTopload || !claim?.claimId) return;
      await uploadFile(
        filesTopload[0],
        async (file, key) => {
          await axiosClient.post(
            `/claims/${claim.claimId}/${claim.organisationId}/attachment/completed`,
            {
              fileName: file.name,
              key,
            }
          );
        },
        "attachment"
      );
    },
    {
      onSettled: () => refetch(),
    }
  );

  const { mutate: removeAttachment } = useMutation(
    async (id: string | undefined) => {
      if (!id || !claim?.claimId) return;
      if (!claim.attachments?.find((x) => x.attachmentId === id)?.tpa)
        return enqueueErrorSnackbar("You can't remove this attachment");

      const finishedSaving = enqueueSavingSnackbar();
      await axiosClient.delete(
        `/claims/${claim.claimId}/${claim.organisationId}/details-attachment/${id}`
      );
      finishedSaving();
    },
    {
      onSuccess: () => {
        enqueueSnackbar("Deleted successfully", { variant: "success" });
      },
      onSettled: () => refetch(),
    }
  );

  return (
    <Box
      sx={{
        flexGrow: 1,
        mb: 3,
      }}
    >
      <Paper
        elevation={4}
        sx={{
          padding: "1.5rem",
          mb: "1.5rem",
          scrollMarginTop: "5.5rem",
        }}
        id="attachments"
      >
        <ClaimDetailsSection title="Attachments">
          <Stack
            direction={"column"}
            sx={{
              maxWidth: "1000px",
              mb: 2,
            }}
          >
            <AttachmentContainer>
              {claim.attachments?.map((x) => (
                <section key={x.key}>
                  <div>
                    <img
                      src="/images/icons/common-file-double.svg"
                      alt="file"
                    />
                    <a
                      href={`${config.apiUrl}/${x.url}/${claim.organisationId}/${claim.claimId}`}
                      // onClick={(e) => {
                      //   e.preventDefault();
                      //   downloadFile(x.url, x.name);
                      // }}
                      rel="noreferrer noopener"
                      download={x.name}
                      target="_blank"
                    >
                      {x.name}
                    </a>
                  </div>
                  {x.tpa && (
                    <Button
                      variant="outlined"
                      sx={{ minWidth: "200px" }}
                      onClick={() => {
                        removeAttachment(x.attachmentId);
                      }}
                    >
                      Remove
                    </Button>
                  )}
                </section>
              ))}
            </AttachmentContainer>
          </Stack>
          {viewMode === "loss-adjuster" && (
            <>
              <input
                type="file"
                name="files"
                accept={acceptedAttachments.join(",")}
                onChange={(event) => {
                  if (!event.target.files) return;
                  if (claim) {
                    uploadAttachments(event.target.files);
                  }
                }}
                ref={fileInput}
                style={{ display: "none" }}
              />
              <Button
                variant="outlined"
                onClick={() => {
                  if (fileInput.current) {
                    fileInput.current.click();
                  }
                }}
              >
                Add Attachment
              </Button>
            </>
          )}
        </ClaimDetailsSection>
      </Paper>
    </Box>
  );
}

const AttachmentContainer = styled.div`
  margin-bottom: 1rem;
  section:first-of-type {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  section:last-of-type {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-bottom: 1px solid lightgray;
  }

  section {
    width: 100%;
    padding: 1rem;
    border-left: 1px solid lightgray;
    border-right: 1px solid lightgray;
    border-top: 1px solid lightgray;
    display: flex;
    justify-content: space-between;

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
    }
    img {
      width: 2rem;
      height: 2rem;
    }
    p {
      text-align: left;
    }
  }
`;
