import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import SpeakerNotesIcon from "@mui/icons-material/SpeakerNotes";
import { Fade, Paper, Popper } from "@mui/material";
import React, { useEffect, useState } from "react";

export interface AnnotationData {
  id: string;
  text: string;
  location: { x: number; y: number };
  page: number;
  timestamp: number;
  createdBy: string;
}

type AnnotationProps = {
  annotation: AnnotationData;
  selectedAnnotation?: string;
  setSelectedAnnotation?: (id: string) => void;
};

export const Annotation = (props: AnnotationProps) => {
  const { annotation, selectedAnnotation, setSelectedAnnotation } = props;
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selectedAnnotation === annotation.id) {
      setOpen(true);
      anchorRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [selectedAnnotation]);

  return (
    <ClickAwayListener
      onClickAway={() => {
        if (!open) return;
        setOpen(false);
        if (selectedAnnotation === annotation.id) setSelectedAnnotation?.("");
      }}
    >
      <div
        ref={anchorRef}
        style={{
          zIndex: 5,
          cursor: "pointer",
          pointerEvents: "all",
          position: "absolute",
          top: annotation.location.y,
          left: annotation.location.x,
          transformOrigin: "center",
        }}
        onMouseUp={(e) => {
          e.stopPropagation();
          setOpen(!open);
          setSelectedAnnotation?.(open ? "" : annotation.id);
        }}
      >
        <SpeakerNotesIcon
          className="material-icons-outlined"
          sx={{
            color: "#0067DF",
          }}
        />
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          transition
          placement={"auto"}
          sx={{
            padding: "0.1rem",
          }}
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps}>
              <Paper
                sx={{
                  paddingY: "0.5rem",
                  paddingX: "1rem",
                  fontSize: 13,
                  borderRadius: "0.25rem",
                  zIndex: 7,
                  maxWidth: "20rem",
                  boxShadow: "0 0 0.5rem 0.25rem rgba(0, 0, 0, 0.1)",
                }}
              >
                {annotation.text}
              </Paper>
            </Fade>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  );
};
