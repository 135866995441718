import { Box } from "@mui/material";
import { mergeAttributes, Node } from "@tiptap/core";
import { Node as ProseMirrorNode } from "@tiptap/pm/model";
import { NodeViewWrapper, ReactNodeViewRenderer } from "@tiptap/react";
import { Plugin } from "prosemirror-state";
import React, { ReactElement, useState } from "react";
import { renderToString } from "react-dom/server";
import { useParams } from "react-router-dom";
import { config } from "src/lib/config";
import { useOfflineClaim } from "src/modules/claims/useOfflineClaim";
import { enqueueWarningSnackbar } from "src/shared/snackbar/SnackbarHelper";

import { CreateClaim } from "@athena/server/src/api/types/claim";
import {
  BridgeAndCulvert,
  BuildingDamage,
  CrossSection,
  ExportedMap,
  Landslip,
  RetainingWall,
  ServiceDamage,
  UpdateClaim,
} from "@athena/server/src/api/types/claimAssessment";
import {
  address,
  addressRegion,
  addressRegionEx,
  aerialPhotographQuantity,
  annotatedAerialPhotographs,
  appurtenantStructuresDamage,
  areaOfInsuredLandAtImminentRisk,
  areaOfInsuredLandDamaged,
  assessmentDate,
  authorName,
  bridgeCulvertDamageLength,
  bridgeCulvertDamageType,
  bridgeCulvertImminentDamageLength,
  bridgeCulvertNumber,
  bridgeCulvertType,
  clientId,
  constructabilityEasy,
  constructabilityHard,
  constructabilityMedium,
  constructabilityNA,
  constructionAccessEasy,
  constructionAccessHard,
  constructionAccessMedium,
  constructionAccessNA,
  customerName,
  damageTypes,
  dateExported,
  dwellingDamage,
  earthworksEasy,
  earthworksHard,
  earthworksMedium,
  earthworksNA,
  engagedName,
  eventDate,
  eventType,
  groundDetails,
  hasNaturalDisasterText,
  headerNumber,
  insurerClaimNumber,
  insurerCompany,
  insurerLegalName,
  inundatedLand,
  isNaturalDisaster,
  jobNumber,
  landslipAreaOfInsuredLandAtImminentRiskEvacuation,
  landslipAreaOfInsuredLandAtImminentRiskNewInundated,
  landslipAreaOfInsuredLandAtImminentRiskOnOrSupportingMainAccessWayEvacuation,
  landslipAreaOfInsuredLandAtImminentRiskOnOrSupportingMainAccessWayNewInundated,
  landslipAreaOfInsuredLandAtImminentRiskOnOrSupportingMainAccessWayReInundated,
  landslipAreaOfInsuredLandAtImminentRiskReInundated,
  landslipAreaOfInsuredLandDamagedEvacuated,
  landslipAreaOfInsuredLandDamagedInundated,
  landslipAreaOfInsuredLandDamagedOnOrSupportingMainAccessWayEvacuated,
  landslipAreaOfInsuredLandDamagedOnOrSupportingMainAccessWayInundated,
  landslipMaterial,
  landslipWidth,
  lossAdjusterCompanyName,
  lossAdjusterEmail,
  lossAdjusterName,
  modifiedDate,
  organisationName,
  pageNumber,
  photographQuantity,
  photographs,
  retainingWallDamaged,
  retainingWallDamageLength,
  retainingWallDamageType,
  retainingWallImminentDamage,
  retainingWallImminentRiskLength,
  retainingWallInsuredWall,
  retainingWallNumber,
  retainingWallRetainedHeight,
  retainingWallWholeWallLength,
  reviewerName,
  serviceDamageLength,
  serviceDamageType,
  serviceImminentRiskLength,
  serviceName,
  serviceType,
  siteDescription,
  sketches,
  sketchQuantity,
  stormAndFloodEvacuatedLand,
  stormAndFloodEvacuatedLand60m,
  stormAndFloodInundatedLand,
  stormAndFloodInundatedLand60m,
  summaryTable,
  totalPages,
  variables,
  visitDates,
} from "@athena/server/src/api/types/templateVariables";
import styled from "@emotion/styled";
import { format } from "date-fns";
import { formatDate } from "src/lib/date";
import {
  AcceesswayLegend,
  AccesswayLayer,
  AppurtenantDamageLineLayer,
  AppurtenantDamagePointLayer,
  AppurtenantDamagePolyLayer,
  AppurtenantStructureDamageLineLegend,
  AppurtenantStructureDamagePointLegend,
  AppurtenantStructureDamagePolyLegend,
  AppurtenantStructurePointLayer,
  AppurtenantStructurePointLegend,
  AppurtenantStructurePolyLayer,
  AppurtenantStructurePolyLegend,
  BridgeAndCulvertLineLayer,
  BridgeAndCulvertLineLegend,
  BridgeAndCulvertPointLayer,
  BridgeAndCulvertPointLegend,
  BridgeAndCulvertPolyLayer,
  BridgeAndCulvertPolyLegend,
  ConceptualRemedialSolutionLineLayer,
  ConceptualRemedialSolutionLineLegend,
  ConceptualRemedialSolutionPointLayer,
  ConceptualRemedialSolutionPointLegend,
  ConceptualRemedialSolutionPolyLayer,
  ConceptualRemedialSolutionPolyLegend,
  CrossSectionLineLayer,
  CrossSectionLineLegend,
  DwellingDamageLineLayer,
  DwellingDamageLineLegend,
  DwellingDamagePointLayer,
  DwellingDamagePointLegend,
  DwellingDamagePolyLayer,
  DwellingDamagePolyLegend,
  EvacuatedLandLayer,
  EvacuatedLandLegend,
  GeneralObservationLineLegend,
  GeneralObservationPointLegend,
  GeneralObservationPolyLegend,
  GeneralObservationsHistoricHeadScarpLineLayer,
  GeneralObservationsLineLayer,
  GeneralObservationsPointLayer,
  GeneralObservationsPolyLayer,
  HeadscarpLayer,
  HeadscarpLegend,
  ImminentRiskOfEvacuationLayer,
  ImminentRiskofEvacuationLegend,
  ImminentRiskOfInundationLayer,
  ImminentRiskOfNewInundationLayer,
  ImminentRiskofNewInundationLegend,
  ImminentRiskofReInundationLegend,
  InsuredAreaLegend,
  InsuredBufferLayer,
  InsuredLayer,
  InundatedLandLayer,
  InundatedLandLegend,
  MainDwellingLayer,
  MainDwellingLegend,
  PropertyBoundaryLayer,
  PropertyBoundaryLegend,
  RetainingWallLayer,
  RetainingWallLegend,
  ServiceDamageLineLayer,
  ServiceDamageLineLegend,
  ServiceDamagePointLayer,
  ServiceDamagePointLegend,
  StormFloodEvacuatedLandLayer,
  StormFloodInundatedLandLayer,
  TensionCracksLayer,
  TensionCracksLegend,
} from "src/modules/claims/Maps/Config";
import { NorthIcon } from "src/modules/claims/Maps/Config/icons/NorthIcon";
import { getAddressForClaim } from "../Claim";
import { useCurrentUserContext } from "../hooks/useCurrentUserContext";
import { CantBeEdited } from "./lockedVariableEditComponents/CantBeEdited";
import { EditStatusBar } from "./lockedVariableEditComponents/EditStatusBar";
import { NavigateToTab } from "./lockedVariableEditComponents/NavigateToTab";
import { Photographs } from "./Photographs";

export const LockedValue = Node.create({
  name: "lockedValue",
  group: "inline",
  content: "inline*",
  draggable: true,
  inline: true,
  atom: true,

  addAttributes() {
    return {
      property: {
        default: null,
        parseHTML: (el) => {
          return (el as HTMLSpanElement).getAttribute("property");
        },
        renderHTML: (attributes) => {
          return {
            property: attributes.property,
          };
        },
      },
      propertyId: {
        default: null,
        parseHTML: (el) => {
          return (el as HTMLSpanElement).getAttribute("propertyId");
        },
        renderHTML: (attributes) => {
          return {
            propertyId: attributes.propertyId,
          };
        },
      },
      fontSize: {
        default: null,
        parseHTML: (el) => {
          return (el as HTMLSpanElement).getAttribute("fontSize");
        },
        renderHTML: (attributes) => {
          return {
            fontSize: attributes.fontSize,
          };
        },
      },
      isBold: {
        default: false,
        parseHTML: (el) => {
          return (el as HTMLSpanElement).getAttribute("isBold");
        },
        renderHTML: (attributes) => {
          return {
            isBold: attributes.isBold,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "span[property]",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const data = this.options.getData?.();

    const html = data
      ? getPropertyFromClaim(
          HTMLAttributes.property,
          HTMLAttributes.propertyId,
          data?.claim,
          data?.org
        )
      : "";

    const divWrapper = document.createElement("div");
    divWrapper.innerHTML = renderToString(html as ReactElement);
    const childNodes = Array.from(divWrapper.childNodes);

    return [
      "span",
      mergeAttributes(
        {
          ...HTMLAttributes,
          style: `font-size: ${
            HTMLAttributes.fontSize ? HTMLAttributes.fontSize + "pt" : "inherit"
          }; font-weight: ${HTMLAttributes.isBold ? "bold" : "inherit"};`,
        },
        {
          contenteditable: "false",
        }
      ),
      ...childNodes,
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer((props: any) => <Component {...props} />);
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        props: {
          handleKeyDown: (view: any, event) => {
            if (event.key === "Backspace") {
              const { $from, $to } = view.state.selection;
              const node = $from.nodeBefore;

              // Check if the cursor is at the start of the custom node or within the node
              if (
                node &&
                node.type.name === "lockedValue" &&
                ($from.pos === $to.pos ||
                  $from.parent.type.name === "lockedValue")
              ) {
                event.preventDefault();
                enqueueWarningSnackbar(
                  "Unable to modify content that contains a nested locked value."
                );
                return true;
              }
            }

            if (event.which === 8 || event.which === 46) {
              view.state.deleting = true;
            }

            return false;
          },
        },

        filterTransaction: (transaction, state: any) => {
          if (!state.deleting) {
            return true;
          }

          let result = true;

          transaction.mapping.maps.forEach((map) => {
            map.forEach((oldStart, oldEnd) => {
              const startNode = state.doc.resolve(oldStart).nodeAfter;
              const endNode = state.doc.resolve(oldEnd).nodeBefore;

              // Ensure the current node is not outside of the selection
              if (!startNode || !endNode) {
                return;
              }

              state.doc.nodesBetween(
                oldStart,
                oldEnd,
                (node: ProseMirrorNode, pos: number) => {
                  // Only check nodes within the selection
                  if (
                    pos >= oldStart - 1 &&
                    pos <= oldEnd + 1 &&
                    hasNestedLockedValue(node)
                  ) {
                    result = false;
                  }
                }
              );
            });
          });
          if (!result) {
            enqueueWarningSnackbar(
              "Unable to modify content that contains a nested locked value."
            );
          }
          return result;
        },
      }),
    ];
  },
});

function hasNestedLockedValue(node: ProseMirrorNode) {
  if (node.type.name === "lockedValue") {
    return true;
  }

  let hasNested = false;
  node.forEach((child) => {
    if (hasNestedLockedValue(child)) {
      hasNested = true;
    }
  });

  return hasNested;
}

const getLandslipMeasurementValue = (value?: string) => {
  return value ? (
    <>
      {value} m<sup>2</sup>
    </>
  ) : (
    "Nil"
  );
};

function getPropertyFromClaim(
  property: string,
  propertyId: string,
  claim: UpdateClaim,
  org: { logoUrl: string; name: string }
): React.ReactNode {
  const attachments =
    claim.assessmentAttachments?.filter(
      (photograph) => photograph.includeInReport
    ) || [];
  const landslip = (claim.landslips?.find(
    (landslip) => landslip.landslipId === propertyId
  ) || {}) as Landslip;
  const retainingWall = (claim.retainingWalls?.find(
    (rw) => rw.retainingWallId === propertyId
  ) || {}) as RetainingWall;
  const bridgeAndCulvert = (claim.bridgeAndCulverts?.find(
    (bc) => bc.bridgesAndCulvertsId === propertyId
  ) || {}) as BridgeAndCulvert;
  const serviceDamage = (claim.serviceDamage?.find(
    (bc) => bc.serviceDamageId === propertyId
  ) || {}) as ServiceDamage;

  const buildingDamage = (claim.buildingDamage?.find(
    (bd) => bd.buildingDamageId === propertyId
  ) || {}) as BuildingDamage;

  switch (property) {
    case totalPages.value:
      return <span className="totalPages"></span>;
    case pageNumber.value:
      return <span className="pageNumber"></span>;
    case stormAndFloodEvacuatedLand.value:
      return getLandslipMeasurementValue(claim.stormAndFlood?.evacuatedLand);
    case landslipAreaOfInsuredLandDamagedEvacuated.value:
      return getLandslipMeasurementValue(landslip.evacuatedLand);
    case landslipAreaOfInsuredLandDamagedInundated.value:
      return getLandslipMeasurementValue(landslip.inundatedLand);

    case landslipAreaOfInsuredLandAtImminentRiskEvacuation.value:
      return getLandslipMeasurementValue(landslip.imminentEvacuatedLand);

    case landslipAreaOfInsuredLandAtImminentRiskNewInundated.value:
      return getLandslipMeasurementValue(landslip.imminentRiskOfNewInundation);

    case landslipAreaOfInsuredLandAtImminentRiskReInundated.value:
      return getLandslipMeasurementValue(landslip.imminentRiskOfInundation);

    case landslipAreaOfInsuredLandDamagedOnOrSupportingMainAccessWayEvacuated.value:
      return getLandslipMeasurementValue(landslip.sixtyMetersEvacuatedLand);
    case landslipAreaOfInsuredLandDamagedOnOrSupportingMainAccessWayInundated.value:
      return getLandslipMeasurementValue(landslip.sixtyMetersInundatedLand);
    case landslipAreaOfInsuredLandAtImminentRiskOnOrSupportingMainAccessWayEvacuation.value:
      return getLandslipMeasurementValue(
        landslip.sixtyMetersImminentEvacuatedLand
      );
    case landslipAreaOfInsuredLandAtImminentRiskOnOrSupportingMainAccessWayReInundated.value:
      return getLandslipMeasurementValue(
        landslip.sixtyMetersImminentRiskOfInundation
      );
    case landslipAreaOfInsuredLandAtImminentRiskOnOrSupportingMainAccessWayNewInundated.value:
      return getLandslipMeasurementValue(
        landslip.sixtyMetersImminentRiskOfNewInundation
      );

    case landslipWidth.value:
      return `${landslip.width} m`;
    case serviceType.value:
      return `${serviceDamage.serviceType}`;
    case serviceName.value:
      return `${serviceDamage.serviceType}`;
    case clientId.value:
      return "To do";
    case jobNumber.value:
      return claim.reference;
    case headerNumber.value:
      return propertyId;
    case address.value:
      return (
        claim.location.address ||
        claim.location.manual?.address ||
        claim.location.parcelAddress ||
        "Invalid Address"
      );

    case addressRegion.value:
      return (claim.location.region || claim.location.manual?.region)
        ?.replace("Region", "")
        .trimEnd();
    case addressRegionEx.value:
      return claim.location.region || claim.location.manual?.region;
    case customerName.value:
      return claim.customers[0].name;
    case dateExported.value:
      return format(new Date(), "dd MMMM yyyy");
    case engagedName.value:
      return "To do";
    case lossAdjusterEmail.value:
      return claim.lossAdjusterUserEmail;
    case lossAdjusterName.value:
      return claim.lossAdjusterUserName;
    case lossAdjusterCompanyName.value:
      return claim.lossAdjusterName;
    case assessmentDate.value:
      return claim.siteVisits && claim.siteVisits[0].visitDate
        ? formatDate(claim.siteVisits[0].visitDate)
        : "No assessment date";
    case siteDescription.value:
      return claim.siteDescription;
    case isNaturalDisaster.value:
      return claim.naturalDisasterDamage;
    case areaOfInsuredLandDamaged.value:
      return "To do";
    case inundatedLand.value:
      return "To do";
    case areaOfInsuredLandAtImminentRisk.value:
      return "To do";
    case summaryTable.value:
      return "To do";
    case insurerCompany.value:
      return claim.insurer?.name;
    case modifiedDate.value:
      return "To do";
    case insurerClaimNumber.value:
      return claim.insurerReference;
    case eventType.value:
      return `Natural Disaster (${claim.lossCause?.name}) Damage`;
    case organisationName.value:
      return org.name;
    case visitDates.value:
      return claim.siteVisits
        ?.filter((siteVisit) => !!siteVisit.visitDate)
        .map((siteVisit) =>
          format(new Date(siteVisit.visitDate!), "dd MMMM yyyy")
        )
        .join(", ");
    case damageTypes.value:
      return [
        ...(claim.landslips?.length || 0 > 0 ? ["Landslip"] : []),
        ["Storm/Flood"],
      ].join(", ");
    case eventDate.value:
      return claim.events
        ?.filter((event) => !!event.dateOfEvent)
        .map((event) => format(new Date(event.dateOfEvent!), "dd MMMM yyyy"))
        .join(", ");
    case groundDetails.value:
      return "To do";
    case landslipMaterial.value:
      return claim.landslips?.map((landslip) => landslip.material).join(", ");
    case insurerLegalName.value:
      return claim.insurer?.name;
    case authorName.value:
      return claim.assignedEngineers?.map((eng) => eng.name).join(", ");
    case reviewerName.value:
      return claim.reviewingEngineers?.map((eng) => eng.name).join(", ");
    case photographQuantity.value:
      return attachments.length ? `(1 - ${attachments.length})` : "N/A";
    case aerialPhotographQuantity.value:
      return claim.exportedMaps?.length
        ? `(1 - ${claim.exportedMaps?.length})`
        : "N/A";
    case annotatedAerialPhotographs.value:
      if (!claim.exportedMaps) return null;
      return (
        <>
          {claim.exportedMaps?.map((map, index) => {
            return (
              <AnnotatedAerial
                key={map.exportedMapId}
                map={map}
                claim={claim}
                index={index}
                org={org}
              />
            );
          })}
        </>
      );
    case sketchQuantity.value:
      return claim.crossSections?.length
        ? `(1 - ${claim.crossSections?.length})`
        : "N/A";
    case constructabilityEasy.value:
      return claim.constructabilityReinstatement === "easy" ? "X" : "";
    case constructabilityMedium.value:
      return claim.constructabilityReinstatement === "medium" ? "X" : "";
    case constructabilityHard.value:
      return claim.constructabilityReinstatement === "hard" ? "X" : "";
    case constructabilityNA.value:
      return claim.constructabilityReinstatement === "n/a" ? "X" : "";
    case earthworksEasy.value:
      return claim.constructabilityEarthworks === "easy" ? "X" : "";
    case earthworksMedium.value:
      return claim.constructabilityEarthworks === "medium" ? "X" : "";
    case earthworksHard.value:
      return claim.constructabilityEarthworks === "hard" ? "X" : "";
    case earthworksNA.value:
      return claim.constructabilityEarthworks === "n/a" ? "X" : "";
    case constructionAccessEasy.value:
      return claim.constructabilityConstructionAccess === "easy" ? "X" : "";
    case constructionAccessMedium.value:
      return claim.constructabilityConstructionAccess === "medium" ? "X" : "";
    case constructionAccessHard.value:
      return claim.constructabilityConstructionAccess === "hard" ? "X" : "";
    case constructionAccessNA.value:
      return claim.constructabilityConstructionAccess === "n/a" ? "X" : "";
    case photographs.value:
      return (
        <Photographs
          photographs={attachments}
          address={getAddressForClaim(claim)}
        />
      );
    case sketches.value:
      return <Sketches sketches={claim.crossSections || []} />;
    case dwellingDamage.value:
      return buildingDamage?.structureDamage;
    case appurtenantStructuresDamage.value:
      return buildingDamage?.structureDamage;
    case retainingWallDamageLength.value:
      return getPropertyWithNil(retainingWall.damagedLength);
    case retainingWallNumber.value:
      return (
        (claim.retainingWalls?.findIndex(
          (rw) => rw.retainingWallId === propertyId
        ) || 0) + 1
      );
    case retainingWallDamageType.value:
      return retainingWall.damageType;
    case serviceImminentRiskLength.value:
      return getPropertyWithNil(serviceDamage.imminentRiskOfDamageLength);
    case serviceDamageLength.value:
      return getPropertyWithNil(serviceDamage.damagedLength);
    case serviceDamageType.value:
      return serviceDamage.damageType;
    case bridgeCulvertDamageLength.value:
      return getPropertyWithNil(bridgeAndCulvert.damagedLength);
    case bridgeCulvertType.value:
      return bridgeAndCulvert.itemType;
    case bridgeCulvertImminentDamageLength.value:
      return getPropertyWithNil(bridgeAndCulvert.imminentRiskLength);
    case bridgeCulvertNumber.value:
      return (
        (claim.bridgeAndCulverts?.findIndex(
          (bc) => bc.bridgesAndCulvertsId === propertyId
        ) || 0) + 1
      );
    case bridgeCulvertDamageType.value:
      return bridgeAndCulvert?.damageType;
    case stormAndFloodEvacuatedLand60m.value:
      return getLandslipMeasurementValue(
        claim.stormAndFlood?.sixtyMetersEvacuatedLand
      );
    case stormAndFloodInundatedLand.value:
      return getLandslipMeasurementValue(claim.stormAndFlood?.inundatedLand);
    case stormAndFloodInundatedLand60m.value:
      return getLandslipMeasurementValue(
        claim.stormAndFlood?.sixtyMetersInundatedLand
      );
    case retainingWallDamaged.value:
      return (
        <>
          {retainingWall.damagedFaceArea ? (
            <>
              {retainingWall.damagedFaceArea} m<sup>2</sup>
            </>
          ) : (
            "Nil"
          )}
        </>
      );
    case retainingWallRetainedHeight.value:
      return `${retainingWall.minimumRetainedHeight} m to ${retainingWall.maximumRetainedHeight} m`;
    case retainingWallWholeWallLength.value:
      return getPropertyWithNil(retainingWall.totalLength);
    case retainingWallImminentDamage.value:
      return getLandslipMeasurementValue(retainingWall.imminentDamageFaceArea);
    case retainingWallInsuredWall.value:
      return getPropertyWithNil(retainingWall.insuredLength);
    case retainingWallImminentRiskLength.value:
      return getPropertyWithNil(retainingWall.imminentRiskOfDamageLength);
    case hasNaturalDisasterText.value:
      return claim.naturalDisasterDamage ? "has" : "may have";
    default:
      return "";
  }
}

const getPropertyWithNil = (value: string, metric = "m") => {
  return value ? `${value} ${metric}` : "Nil";
};

type AnnotatedAerialProps = {
  map: ExportedMap;
  claim: UpdateClaim;
  index: number;
  org: { logoUrl: string };
};

const AerialContainer = styled.div`
  /* width: 20.75cm;
    height: 14.35cm;
    border: 1px solid lightgray;
    display: flex;
    flex-direction: row;
    background-color: #fff;
    margin: 0.25cm;
    font-size: 6px;

    @media print {
      border: none;
      display: block;
      page-break-inside: avoid;
      background-color: transparent;
      padding: 0;
      width: 41.5cm;
      height: 28.7cm;
      margin: 0.5cm;
      font-size: 12px;
    } */
`;

const Image = styled.img`
  /* width: 14.35cm;
    height: 14.35cm;

    @media print {
      width: 28.7cm;
      height: 28.7cm;
    } */
`;

const AnnotatedAerial = ({ map, claim, index, org }: AnnotatedAerialProps) => {
  const mapLegends = map.legendFeatures?.reduce((acc, feature) => {
    const legend = getLegendImage(feature);
    if (!!legend) {
      const found = acc.find((l) => l.type.name === legend.type.name);
      if (!found) {
        acc.push(legend);
      }
    }
    return acc;
  }, [] as JSX.Element[]);

  return (
    <AerialContainer
      style={{
        width: "40.4cm",
        height: "28.2cm",
        display: "flex",
        flexDirection: "row",
        paddingTop: "0.7cm",
        marginLeft: "0.8cm",
      }}
      className="AerialContainer"
    >
      {/* <div
        style={{ width: "100%", height: "4cm", border: "1px solid lightgray" }}
      ></div> */}
      <Image
        style={{
          border: "1px solid lightgray",
          width: "28.2cm",
          height: "28.2cm",
        }}
        className="MapContainer"
        src={`${config.apiUrl}/${map.annotatedImageUrl}`}
      />{" "}
      <div
        style={{
          display: "inline-block",
          flex: 1,
          width: "9cm",
          border: "1px solid lightgray",
        }}
        className="SidebarContainer"
      >
        <div
          style={{
            borderBottom: "1px solid lightgray",
            padding: "0.5rem",
            height: 80,
          }}
        >
          <Box
            style={{
              maxHeight: 60,
              marginLeft: -80,
              position: "absolute",
              backgroundColor: "#ffffffc6",
              padding: "0.5rem",
              borderRadius: "40%",
            }}
            className="NorthContainer"
          >
            <NorthIcon />
          </Box>
          <img
            src={`${config.apiUrl}/${org.logoUrl}`}
            style={{ maxHeight: 60 }}
          />
        </div>
        <div
          style={{
            padding: "0.5rem",
            height: "14.75cm",
            borderBottom: "1px solid lightgray",
          }}
        >
          <h4 style={{ marginBottom: "0.5cm" }}>LEGEND</h4>
          <div
            style={{
              display: "flex",
              gap: "0.5rem",
              flexWrap: "wrap",
            }}
            className="LegendContainer"
          >
            {mapLegends?.map((legend, index) => (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
                key={index}
              >
                {/* Do we need to base64 encode these images here? */}
                <span>{legend}</span>
              </div>
            ))}
          </div>
        </div>
        <div
          style={{
            borderBottom: "1px solid lightgray",
            padding: "0.5rem",
          }}
          className="DescriptionContainer"
        >
          <strong>NOTES</strong>
          <div>
            1. Reference data parcels sourced from LINZ, licenced under CC BY
            4.0.
            <br />
            2. Aerial Basemap © LINZ CC BY 4.0, © Imagery Basemap contributors.
            <br />
            {map.description ? <>3. {map.description}</> : ""}
            <br />
            <strong>SCALE </strong>1:{map.scale}@A3
          </div>
        </div>

        <div
          style={{
            borderBottom: "1px solid lightgray",
          }}
        >
          <div
            style={{
              width: 130,
              borderRight: "1px solid lightgray",
              display: "inline-block",
              padding: "0.5rem",
            }}
          >
            Created On:
          </div>
          <div
            style={{
              display: "inline-block",
              padding: "0.5rem",
            }}
          >
            {formatDate(map.created)}
          </div>
        </div>
        <div
          style={{
            borderBottom: "1px solid lightgray",
          }}
        >
          <div
            style={{
              width: 130,
              borderRight: "1px solid lightgray",
              display: "inline-block",
              padding: "0.5rem",
            }}
          >
            Created By:{" "}
          </div>
          <div
            style={{
              display: "inline-block",
              padding: "0.5rem",
            }}
          >
            {claim.assignedEngineers?.map((eng) => eng.name).join(", ")}
          </div>
        </div>
        <div
          style={{
            borderBottom: "1px solid lightgray",
          }}
        >
          <div
            style={{
              width: 130,
              borderRight: "1px solid lightgray",
              display: "inline-block",
              padding: "0.5rem",
            }}
          >
            Approved By:{" "}
          </div>
          <div
            style={{
              display: "inline-block",
              padding: "0.5rem",
            }}
          >
            {claim.reviewingEngineers?.map((eng) => eng.name).join(", ")}
          </div>
        </div>
        <div
          style={{
            borderBottom: "1px solid lightgray",
          }}
        >
          <div
            style={{
              width: 130,
              borderRight: "1px solid lightgray",
              display: "inline-block",
              padding: "0.5rem",
            }}
          >
            Project Number:
          </div>
          <div
            style={{
              display: "inline-block",
              padding: "0.5rem",
            }}
          >
            {claim.reference}
          </div>
        </div>
        <div
          style={{
            padding: "0.5rem",
          }}
        >
          <div>
            <div style={{ width: 180, display: "inline-block" }}>
              <strong>TITLE</strong>
            </div>
            <div style={{ display: "inline-block" }}>
              Aerial {index + 1}: {map.mapName}
            </div>
          </div>
          <div>
            <div style={{ width: 180, display: "inline-block" }}>
              <strong>CLIENT</strong>
            </div>
            <div style={{ display: "inline-block" }}>{claim.insurer?.name}</div>
          </div>
          <div>
            <div style={{ width: 180, display: "inline-block" }}>
              <strong>PROJECT</strong>
            </div>
            <div style={{ display: "inline-block" }}>{claim.claimName}</div>
          </div>
          <div>
            <div style={{ width: 180, display: "inline-block" }}>
              <strong>Insurer Claim no:</strong>
            </div>
            <div style={{ display: "inline-block" }}>
              {claim.insurerReference}
            </div>
            <style>
              {`
                    @media screen {
                      .AerialContainer {
                        width: auto !important;
                        height: auto !important;
                        margin: 0 !important;
                        padding: 0 !important;
                        flex-direction: row !important;
                        flex-wrap: wrap;

                      }
                      .MapContainer {
                        object-fit: contain !important;
                        width: 70% !important;
                        height: auto !important;

                      }
                      .SidebarContainer {
                        width: 70% !important;
                        position: relative;

                      }

                      .SketchContainer {
                      width: auto !important;
                      padding: 0 !important;
                      }
                      .LegendContainer {
                        height: auto !important;
                      }
                      .DescriptionContainer {
                        height: auto !important;
                      }
                      .NorthContainer {
                        margin-right: 0 !important;
                        left: 0;
                        bottom: 0;


                      }

                    }
                  `}
            </style>
          </div>
        </div>
      </div>
    </AerialContainer>
  );
};

const getLegendImage = (layerName: string) => {
  switch (layerName) {
    case HeadscarpLayer:
      return <HeadscarpLegend />;
    case StormFloodEvacuatedLandLayer:
    case EvacuatedLandLayer:
      return <EvacuatedLandLegend />;
    case StormFloodInundatedLandLayer:
    case InundatedLandLayer:
      return <InundatedLandLegend />;
    case ImminentRiskOfEvacuationLayer:
      return <ImminentRiskofEvacuationLegend />;
    case ImminentRiskOfInundationLayer: //These are switched for loading the correct legend icon
      return <ImminentRiskofNewInundationLegend />;
    case ImminentRiskOfNewInundationLayer:
      return <ImminentRiskofReInundationLegend />;
    case TensionCracksLayer:
      return <TensionCracksLegend />;
    case RetainingWallLayer:
      return <RetainingWallLegend />;
    case DwellingDamagePolyLayer:
      return <DwellingDamagePolyLegend />;
    case DwellingDamagePointLayer:
      return <DwellingDamagePointLegend />;
    case DwellingDamageLineLayer:
      return <DwellingDamageLineLegend />;
    case AppurtenantDamagePolyLayer:
      return <AppurtenantStructureDamagePolyLegend />;
    case AppurtenantDamagePointLayer:
      return <AppurtenantStructureDamagePointLegend />;
    case AppurtenantDamageLineLayer:
      return <AppurtenantStructureDamageLineLegend />;
    case ServiceDamageLineLayer:
      return <ServiceDamageLineLegend />;
    case ServiceDamagePointLayer:
      return <ServiceDamagePointLegend />;
    case BridgeAndCulvertPolyLayer:
      return <BridgeAndCulvertPolyLegend />;
    case BridgeAndCulvertLineLayer:
      return <BridgeAndCulvertLineLegend />;
    case BridgeAndCulvertPointLayer:
      return <BridgeAndCulvertPointLegend />;
    case MainDwellingLayer:
      return <MainDwellingLegend />;
    case InsuredLayer:
      return <InsuredAreaLegend />;
    case AccesswayLayer:
      return <AcceesswayLegend />;
    case AppurtenantStructurePointLayer:
      return <AppurtenantStructurePointLegend />;
    case AppurtenantStructurePolyLayer:
      return <AppurtenantStructurePolyLegend />;
    case PropertyBoundaryLayer:
      return <PropertyBoundaryLegend />;
    case InsuredBufferLayer:
      return <InsuredAreaLegend />;
    case CrossSectionLineLayer:
      return <CrossSectionLineLegend />;
    case ConceptualRemedialSolutionPolyLayer:
      return <ConceptualRemedialSolutionPolyLegend />;
    case ConceptualRemedialSolutionLineLayer:
      return <ConceptualRemedialSolutionLineLegend />;
    case ConceptualRemedialSolutionPointLayer:
      return <ConceptualRemedialSolutionPointLegend />;
    case GeneralObservationsPolyLayer:
      return <GeneralObservationPolyLegend />;
    case GeneralObservationsLineLayer:
      return <GeneralObservationLineLegend />;
    case GeneralObservationsPointLayer:
      return <GeneralObservationPointLegend />;
    case GeneralObservationsHistoricHeadScarpLineLayer:
      return <GeneralObservationPointLegend />;
    default:
      return undefined;
  }
};

type SketchesProps = {
  sketches: CrossSection[];
};

const Sketches = ({ sketches }: SketchesProps) => {
  if (!sketches.length) return null;
  return (
    <div style={{ width: "28.7cm" }} className="SketchContainer">
      {sketches.map((sketch, i) => {
        return (
          <div
            key={sketch.imageUrl}
            style={
              i > 0
                ? {
                    pageBreakBefore: "always",
                  }
                : {}
            }
          >
            {/* {i === 0 && (
              <Typography variant="subtitle1">
                Sketches 1 to {sketches.length}
              </Typography>
            )} */}

            <div
              style={{
                border: "1px solid black",
                // flex: 1,
                // display: "flex",
                // alignItems: "center",
                // justifyContent: "center",
                // flexDirection: "column",
                height: "19.9cm",
                width: "28.7cm",
                marginTop: "0.5cm",
                marginLeft: "0.5cm",
              }}
              className="SketchContainer"
            >
              <img
                style={{
                  height: "17cm",
                  width: "23.7333cm",
                  marginLeft: "4cm",
                  marginTop: "0.25cm",
                  marginBottom: "0.25cm",
                }}
                src={`${config.apiUrl}/${sketch.imageUrl}`}
                className="SketchContainer"
              />
              <div
                style={{
                  padding: 12,
                  borderTop: "1px solid black",
                  height: "2cm",
                  zIndex: 9999999,
                }}
              >
                {sketch.legendItems?.map((legendItem) => {
                  return (
                    <div
                      style={{ display: "inline-flex", marginRight: 8 }}
                      key={legendItem}
                    >
                      <div style={getSketchLegendStyle(legendItem)}></div>
                      {legendItem
                        .split("-")
                        .map((word) => {
                          return word.charAt(0).toUpperCase() + word.slice(1);
                        })
                        .join(" ")}
                    </div>
                  );
                })}
              </div>
              <style></style>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const getSketchLegendStyle = (legendName: string) => {
  if (legendName === "headscarp") {
    return {
      height: 25,
      width: 3,
      backgroundColor: "#df0800",
      marginRight: 8,
    };
  }

  if (legendName === "tension-cracks") {
    return {
      height: 25,
      width: 2,
      marginRight: 8,
      border: "2px dashed black", // Add dashed border style
    };
  }

  if (legendName === "8m-line") {
    return {
      height: 25,
      width: 2,
      marginRight: 8,
      border: "2px dashed #ffd700", // Add dashed border style
    };
  }

  if (legendName === "inundated-land") {
    return {
      height: 25,
      width: 25,
      marginRight: 8,
      borderRadius: "50%",
      backgroundColor: "rgba(178, 100, 178, 0.5)", // Add dashed border style
      border: "rgba(178, 100, 178, 1)",
    };
  }
  if (legendName === "evacuated-land") {
    return {
      height: 25,
      width: 25,
      marginRight: 8,
      borderRadius: "50%",
      backgroundColor: "rgba(223, 8,0, 0.5)", // Add dashed border style
      border: "1px solid rgba(223, 8,0, 1)",
    };
  }
  if (legendName === "imminent-risk-of-evacuation") {
    return {
      height: 25,
      width: 25,
      marginRight: 8,
      borderRadius: "50%",
      backgroundColor: "rgba(239, 89, 5, 0.5)", // Add dashed border style
      border: "1px solid rgba(239, 89, 5, 1)",
    };
  }
  if (legendName === "imminent-risk-of-re-inundation") {
    return {
      height: 25,
      width: 25,
      marginRight: 8,
      borderRadius: "50%",
      border: "3px dotted #4679e2",
    };
  }
  if (legendName === "imminent-risk-of-new-inundation") {
    return {
      height: 25,
      width: 25,
      marginRight: 8,
      borderRadius: "50%",
      backgroundColor: "rgba(70, 121, 226, 0.5)", // Add dashed border style
      border: "1px solid rgba(70, 121, 226, 1)",
    };
  }
  return {};
};

const isUpdatingClaim = (
  claim: CreateClaim | UpdateClaim
): claim is UpdateClaim => {
  return (claim as UpdateClaim).claimId !== undefined;
};

// ReactNodeView doesnt seem to have good typing
const Component = (props: any) => {
  const { claimId } = useParams();
  const { organisation } = useCurrentUserContext();
  const { claim } = useOfflineClaim(claimId);
  const [anchorEl, setAnchorEl] = useState<HTMLSpanElement | null>(null);

  const isComplexValue = [
    photographs.value,
    sketches.value,
    annotatedAerialPhotographs.value,
  ].includes(props.node.attrs.property);

  if (!claim || !isUpdatingClaim(claim) || !organisation)
    throw new Error("Locked Value can't use CreateClaim");

  const inlineValue =
    props.node.attrs.property !== siteDescription.value && !isComplexValue;
  return (
    <NodeViewWrapper
      className="react-component"
      contentEditable={false}
      style={inlineValue ? { display: "inline-block" } : {}}
    >
      {inlineValue && (
        <span
          id={props.node.attrs.property}
          className="label"
          style={{
            cursor: "pointer",
            fontSize: props.node.attrs.fontSize
              ? props.node.attrs.fontSize + "pt"
              : "inherit",
            fontWeight: props.node.attrs.isBold ? "bold" : "inherit",
            ...(isComplexValue
              ? {}
              : {
                  backgroundColor: "lightgrey",
                  paddingLeft: "0.25rem",
                  paddingRight: "0.25rem",
                  borderRadius: 4,
                }),
          }}
          onClick={(event: React.MouseEvent<HTMLSpanElement>) => {
            setAnchorEl(event.currentTarget);
          }}
        >
          {claim
            ? getPropertyFromClaim(
                props.node.attrs.property,
                props.node.attrs.propertyId,
                claim,
                organisation
              )
            : " "}
        </span>
      )}
      {!inlineValue && (
        <div
          id={props.node.attrs.property}
          className="label"
          style={{
            cursor: "pointer",
            whiteSpace: "normal",
            fontSize: props.node.attrs.fontSize
              ? props.node.attrs.fontSize + "pt"
              : "inherit",
            ...(isComplexValue
              ? {}
              : {
                  backgroundColor: "lightgrey",
                  paddingLeft: "0.25rem",
                  paddingRight: "0.25rem",
                  borderRadius: 4,
                }),
          }}
          onClick={(event: React.MouseEvent<HTMLSpanElement>) => {
            setAnchorEl(event.currentTarget);
          }}
        >
          {claim
            ? getPropertyFromClaim(
                props.node.attrs.property,
                props.node.attrs.propertyId,
                claim,
                organisation
              )
            : " "}
        </div>
      )}

      {!!anchorEl &&
        getEditComponent(
          props.node.attrs.property,
          claimId || "",
          () => setAnchorEl(null),
          anchorEl
        )}
    </NodeViewWrapper>
  );
};

const getEditComponent = (
  property: string,
  claimId: string,
  onClose: VoidFunction,
  anchorEl: HTMLSpanElement
) => {
  const variable = variables.find((variable) => variable.value === property);
  switch (variable?.redirectPage) {
    case "details":
      return (
        <NavigateToTab
          claimId={claimId}
          onClose={onClose}
          propertyLabel={
            variables.find((variable) => variable.value === property)?.label ||
            ""
          }
          propertyValue={property}
          tabName="details"
          anchorEl={anchorEl}
        />
      );
    case "no-edit":
      return <CantBeEdited onClose={onClose} anchorEl={anchorEl} />;

    case "status-bar":
      return (
        <EditStatusBar
          onClose={onClose}
          propertyLabel={
            variables.find((variable) => variable.value === property)?.label ||
            ""
          }
          anchorEl={anchorEl}
        />
      );
    case "assessment":
      return (
        <NavigateToTab
          claimId={claimId}
          onClose={onClose}
          propertyLabel={
            variables.find((variable) => variable.value === property)?.label ||
            ""
          }
          propertyValue={property}
          tabName="assessment"
          anchorEl={anchorEl}
        />
      );
  }
};
