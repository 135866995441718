import { ClaimProgress, LoadingSpinner } from "@athena/components";
import { Urgency } from "@athena/server/src/api/types/claim.ts";
import styled from "@emotion/styled";
import {
  Box,
  Chip,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { trpc } from "src/lib/api/trpc";
import { ClaimStatusChips } from "src/modules/claims/ClaimStatusChips";
import { useCurrentUser } from "src/shared/hooks/useCurrentUser";
import {
  enqueueSavingSnackbar,
  enqueueSuccessSnackbar,
} from "src/shared/snackbar/SnackbarHelper";
import { Attachments } from "./components/Attachments";
import { Company } from "./components/Company";
import { Customer } from "./components/Customer";
import { EventDetails } from "./components/EventDetails";
import { General } from "./components/General";
import { Insurer } from "./components/Insurer";
import { Location } from "./components/Location";
import { LossAdjuster } from "./components/LossAdjuster";
import { ReportDownloadButton } from "./components/ReportDownload";
import { SiteVisits } from "./components/SiteVisits";
import { TimelineView } from "./components/Timeline";

export function EngineeringClaimDetails() {
  const { userData } = useCurrentUser();

  const { claimId, organisationId } = useParams();
  const [savingSnackbar, setSavingSnackbar] =
    useState<ReturnType<typeof enqueueSavingSnackbar>>();
  if (!claimId || !organisationId) {
    throw new Error("");
  }

  const {
    isLoading,
    data,
    refetch: refetchClaim,
  } = trpc.claims.getClaim.useQuery({
    claimId,
    organisationId,
  });

  const eqcMode =
    userData?.organisation?.orgType == "eqc" &&
    data?.insurer?.id != userData?.organisation?.organisationId;

  const updateUrgencyMutation = trpc.claims.updateUrgency.useMutation({
    onSuccess: () => {
      refetchClaim();
      savingSnackbar?.();
      enqueueSuccessSnackbar("Saved");
    },
    onError: () => {
      enqueueSnackbar("Something went wrong", { variant: "error" });
    },
  });

  const handleUrgencyChange = async (e: any) => {
    if (eqcMode)
      //Stop the user for re-enabling the dropdown
      return;
    setSavingSnackbar(enqueueSavingSnackbar("Saving"));
    await updateUrgencyMutation.mutateAsync({
      claimId,
      organisationId,
      urgency: e.target.value,
    });
  };

  const sections = [
    {
      completed: true,
      title: "Event Details",
      id: "event",
    },
    {
      completed: true,
      title: "Location",
      id: "location",
    },
    {
      completed: true,
      title: "Customer",
      id: "customer",
    },
    {
      completed: true,
      title: "Insurer",
      id: "insurer",
    },
    {
      completed: true,
      title: "Loss Adjuster",
      id: "lossadjuster",
    },
    {
      completed: true,
      title: "Company Details",
      id: "company",
    },
    {
      completed: true,
      title: "General Details",
      id: "general",
    },
    {
      completed: true,
      title: "Attachments",
      id: "attachments",
    },
    {
      completed: true,
      title: "Site Visits",
      id: "siteVisits",
    },
    {
      completed: true,
      title: "Timeline",
      id: "timeline",
    },
  ].filter((section) => {
    if (eqcMode) {
      return section.id != "general" && section.id != "attachments";
    }
    return true;
  });

  if (isLoading || !data) {
    return <LoadingSpinner />;
  }

  return (
    <Container
      sx={{
        marginTop: 3,
        maxWidth: "1200px",
      }}
      maxWidth={false}
    >
      <Paper
        sx={{
          padding: 1.5,
          display: "flex",
          alignItems: "center",
          width: "100%",
          mb: 3,
          justifyContent: "space-between",
        }}
        elevation={4}
      >
        <Box>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-simple-select-helper-label1" shrink>
              Status
            </InputLabel>
            <StatusChips>
              <ClaimStatusChips
                additionalStatuses={data.additionalStatuses || []}
                status={data.status!}
                size="small"
              />
            </StatusChips>
            {/* <TextField
              value={data.status}
              contentEditable={false}
              InputProps={{
                readOnly: true,
              }}
            /> */}
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-simple-select-helper-label1" shrink>
              Urgency
            </InputLabel>
            <Select
              readOnly={eqcMode}
              labelId="demo-multiple-chip-label1"
              id="demo-multiple-chip1"
              value={data.urgency}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  <Chip key={selected} label={selected} size="small" />
                </Box>
              )}
              onChange={handleUrgencyChange}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 48 * 4.5 + 8,
                    width: 250,
                  },
                },
              }}
            >
              {Object.values(Urgency).map((urgency) => (
                <MenuItem key={`cdDropdown-${urgency}`} value={urgency}>
                  {urgency}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {data && (
          <ReportDownloadButton
            claim={{
              claimId: data.claimId,
              status: data.status,
              organisationId: data.organisationId,
            }}
            variant="button"
          />
        )}
      </Paper>

      <Paper
        elevation={4}
        sx={{
          padding: "1.5rem",
          mb: "1.5rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        {" "}
        <Typography variant="h2" component="h2">
          Claim Details
        </Typography>
      </Paper>
      <ClaimGrid>
        <ClaimProgress claimProgressItems={sections} />

        <Box
          sx={{
            flexGrow: 1,
            mb: 3,
          }}
        >
          <div>
            <EventDetails claim={data} />
            <Location claim={data} />
            <Customer claim={data} />
            <Insurer claim={data} />
            <LossAdjuster claim={data} />
            <Company claim={data} />
            {!eqcMode && (
              <>
                <General claim={data} />
                <Attachments claim={data} refetch={refetchClaim} />
              </>
            )}
            <SiteVisits claim={data} />
            <TimelineView claim={data} />
            {/* <Paper
          elevation={4}
          sx={{
            padding: "1.5rem",
            mb: "1.5rem",
            scrollMarginTop: "5.5rem",
          }}
          id="location"
        >
          <ClaimDetailsSection
            title="Location details"
            tooltip="Enter the claim location, through the address lookup, parcel look up, or manually entering the address"
          >
            <AddressContainer>
              <Box>
                <MapPlaceholder>
                  {claimId !== "new" ? (
                    <OLRProvider
                      map={existingClaimOlrMap}
                      baseMaps={basemaps}
                    >
                      <ClaimDetailsMap
                        pinCoordinates={claimCoords}
                        onLocationChange={(loc) => {workspaces/client/src/modules/intel/pages/components/EventDetails.tsx
                          setValue("location.geo.coordinates", loc, {
                            shouldDirty: true,
                          });
                          setClaimCoords(loc);
                        }}
                      ></ClaimDetailsMap>
                    </OLRProvider>
                  ) : (
                    <OLRProvider map={newClaimOlrMap} baseMaps={basemaps}>
                      <ClaimDetailsMap
                        pinCoordinates={claimCoords}
                        onLocationChange={(loc) => {
                          setValue("location.geo.coordinates", loc, {
                            shouldDirty: true,
                          });
                          setClaimCoords(loc);
                        }}
                      ></ClaimDetailsMap>
                    </OLRProvider>
                  )}
                </MapPlaceholder>
                <InfoText style={{ marginTop: "1rem", width: 250 }}>
                  <InfoOutlinedIcon sx={{ mr: 1 }} />
                  Click the pin to move it
                </InfoText>
              </Box>
            </AddressContainer>
          </ClaimDetailsSection>
        </Paper>
        {claim && (
          <div
            id="siteVisits"
            style={{
              scrollMarginTop: "5.5rem",
            }}
          >
            <SiteVisits />
          </div>
        )} */}
          </div>
        </Box>
      </ClaimGrid>
    </Container>
  );
}

const MapPlaceholder = styled.div`
  height: 320px;
  overflow: hidden;
  border-radius: 12px;
  border: 2px solid lightgray;
`;
const AddressContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`;

const AttachmentContainer = styled.div`
  margin-bottom: 1.5rem;

  section:first-of-type {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  section:last-of-type {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    border-bottom: 1px solid lightgray;
  }

  section {
    width: 100%;
    padding: 1rem;
    border-left: 1px solid lightgray;
    border-right: 1px solid lightgray;
    border-top: 1px solid lightgray;
    display: flex;
    justify-content: space-between;

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
    }
    img {
      width: 2rem;
      height: 2rem;
    }
    p {
      text-align: left;
    }
  }
`;
const ClaimGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 3.5fr;
  gap: 1.5rem;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`;

const SaveContainer = styled.div`
  padding: 1rem 1.5rem;
  position: sticky;
  bottom: 0;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  background: white;

  @media screen and (max-width: 600px) {
    .closeButton {
      display: none;
    }
    button {
      flex: 1;
      margin: 0;
    }
  }
`;

const InfoText = styled.div`
  display: flex;
  align-items: center;
  background-color: #d8e9ff;
  border-radius: 12px;
  padding: 0.5rem;
  border: 1px solid black;
`;

export const ClaimSection = styled.section`
  :not(:last-child) {
    border-bottom: 1px solid #d3d3d3;
    padding-bottom: 0.75rem;
  }
`;

export const ClaimSectionContentVertical = styled.div`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  display: flex;
  flex-direction: column;
`;

export const ClaimSectionContentHorizontal = styled.div`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ClaimSectionHeader = styled.h4`
  margin-top: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
`;

export const ClaimSectionBody = styled.div`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
`;
const StatusChips = styled.div`
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding: 7.25px 14px;
`;
