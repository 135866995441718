import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import DescriptionIcon from "@mui/icons-material/Description";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import HomeIcon from "@mui/icons-material/Home";
import ListIcon from "@mui/icons-material/List";
import MapIcon from "@mui/icons-material/Map";
import SettingsIcon from "@mui/icons-material/Settings";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import { useCurrentUser } from "src/shared/hooks/useCurrentUser";

import Tooltip from "@mui/material/Tooltip";

export const EngineeringMenu = () => {
  const { userData, isFetched } = useCurrentUser();
  const { user } = userData || {};

  if (!isFetched || !user) {
    return null;
  }

  return (
    <>
      <Link to="/">
        <Tooltip title="Home" placement="right">
          <IconButton
            edge="start"
            aria-label="menu"
            sx={{
              display: "flex",
              width: "4rem",
              height: "3rem",
              alignItems: "center",
              justifyItems: "center",
              margin: 0,

              color: "#0067DF",
            }}
          >
            <HomeIcon fontSize="medium" sx={{}} />
          </IconButton>
        </Tooltip>
      </Link>

      <Link to="/claim">
        <Tooltip title="Claims" placement="right">
          <IconButton
            edge="start"
            aria-label="menu"
            sx={{
              display: "flex",
              width: "4rem",
              height: "3rem",
              alignItems: "center",
              justifyItems: "center",
              margin: 0,
              color: "#0067DF",
            }}
          >
            <ListIcon fontSize="medium" sx={{}} />
          </IconButton>
        </Tooltip>
      </Link>
      <Link to="/reports">
        <Tooltip title="Reports" placement="right">
          <IconButton
            edge="start"
            aria-label="menu"
            sx={{
              display: "flex",
              width: "4rem",
              height: "3rem",
              alignItems: "center",
              justifyItems: "center",
              margin: 0,
              color: "#0067DF",
            }}
          >
            <DescriptionIcon fontSize="medium" sx={{}} />
          </IconButton>
        </Tooltip>
      </Link>

      <Link to="/map">
        <Tooltip title="Spatial Portal" placement="right">
          <IconButton
            edge="start"
            aria-label="menu"
            sx={{
              display: "flex",
              width: "4rem",
              height: "3rem",
              alignItems: "center",
              justifyItems: "center",
              margin: 0,
              color: "#0067DF",
            }}
          >
            <MapIcon fontSize="medium" sx={{}} />
          </IconButton>
        </Tooltip>
      </Link>
      {(user.roles.includes("coordinator") || user.roles.includes("admin")) && (
        <Link to="/performance">
          <IconButton
            edge="start"
            aria-label="menu"
            sx={{
              display: "flex",
              width: "4rem",
              height: "3rem",
              alignItems: "center",
              justifyItems: "center",
              margin: 0,
              color: "#0067DF",
            }}
          >
            <EqualizerIcon fontSize="medium" sx={{}} />
          </IconButton>
        </Link>
      )}
      <Link to="/settings/general">
        <Tooltip title="Settings" placement="right">
          <IconButton
            edge="start"
            aria-label="menu"
            sx={{
              display: "flex",
              width: "4rem",
              height: "3rem",
              alignItems: "center",
              justifyItems: "center",
              margin: 0,
              color: "#0067DF",
            }}
          >
            <SettingsIcon fontSize="medium" sx={{}} />
          </IconButton>
        </Tooltip>
      </Link>
      {user.roles.includes("admin") && (
        <Link to="/admin/reportTemplates">
          <IconButton
            edge="start"
            aria-label="menu"
            sx={{
              display: "flex",
              width: "4rem",
              height: "3rem",
              alignItems: "center",
              justifyItems: "center",
              margin: 0,
              color: "#0067DF",
            }}
          >
            <AdminPanelSettingsIcon fontSize="medium" sx={{}} />
          </IconButton>
        </Link>
      )}
    </>
  );
};
