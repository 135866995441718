import { useEffect, useState } from "react";
import { useCurrentUser } from "./useCurrentUser";

type ViewModeProps = {
  insurerId?: string;
  organisationId?: string;
};

export const useViewMode = () => {
  const { userData } = useCurrentUser();
  const [viewMode, setViewMode] = useState<
    "insurer" | "engineering" | "eqc" | "loss-adjuster" | "default"
  >("default");

  const compute = (props?: ViewModeProps) => {
    switch (userData?.organisation?.orgType) {
      case "eqc":
        if (props?.insurerId) {
          userData?.organisation.organisationId === props.insurerId
            ? setViewMode("insurer")
            : setViewMode("eqc");
        } else {
          setViewMode("eqc");
        }
        return;
      default:
        setViewMode(userData?.organisation?.orgType || "default");
        return;
    }
  };

  useEffect(() => {
    compute();
  }, [userData]);

  return { viewMode, revalidate: compute };
};
