import {
  AutoSave,
  ConfirmationDialog,
  LoadingSpinner,
} from "@athena/components";
import { Status } from "@athena/server/src/api/types/claimStatuses";
import { acceptedImages } from "@athena/server/src/api/types/mimeTypes";
import styled from "@emotion/styled";

import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useRef, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { trpc } from "src/lib/api/trpc";
import { axiosClient } from "src/lib/axiosClient.ts";
import { AnnotationData } from "src/modules/claims/claimReport/components/Annotation.tsx";
import {
  enqueueErrorSnackbar,
  enqueueSavingSnackbar,
  enqueueSuccessSnackbar,
} from "src/shared/snackbar/SnackbarHelper.tsx";
import { usePresignedUpload } from "../hooks/usePresignedUpload";
import { mapVersion } from "./ClaimReportActions";
import { AnnotationItem } from "./components/AnnotationItem";
import { NoReportCard } from "./components/NoReportCard";
import { PDFViewer } from "./components/PDFViewer";

export type ClaimReportInfo = {
  url: string;
  versions: string[];
  current: string;
} & {
  message: string;
};

export interface AnnotationRequest {
  annotations: {
    lossAdjuster: AnnotationData[];
    engineering: AnnotationData[];
  };
  submitted: {
    lossAdjuster: boolean;
    engineering: boolean;
  };
  latest: string;
}

export default function ClaimReportPDF() {
  const { claimId, organisationId } = useParams();
  const queryClient = useQueryClient();
  const [annotating, setAnnotating] = useState(false);
  const [claimReportInfo, setClaimReportInfo] = useState<ClaimReportInfo>();
  const [selectedAnnotation, setSelectedAnnotation] = useState<string>();
  const [selectedVersion, setSelectedVersion] = useState<string>("latest");
  const [showClientChangesModal, setShowClientChangesModal] = useState(false);
  const [noReport, setNoReport] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const fileInput = useRef<HTMLInputElement>(null);
  const acceptedFiles = ["application/pdf"].concat(acceptedImages);
  const { uploadFile } = usePresignedUpload(acceptedFiles);

  if (!claimId || !organisationId) {
    throw new Error("");
  }

  const { data: claim } = trpc.claims.getClaim.useQuery({
    claimId,
    organisationId,
  });

  const [annotationState, setAnnotationState] = useState({
    lossAdjuster: false,
    engineering: false,
    latest: "",
  });
  const [snackbarHandle, setSnackbarHandle] = useState<() => void>();
  const { control, handleSubmit, setValue, ...rest } = useForm<{
    annotations: AnnotationData[];
  }>();

  const {
    fields: annotations,
    append: addAnnotation,
    update: updateAnnotation,
    remove: removeAnnotation,
  } = useFieldArray({
    name: "annotations",
    control: control,
  });

  const latestVersion =
    selectedVersion !== "latest"
      ? selectedVersion !== annotationState.latest
      : annotationState.latest !== claimReportInfo?.current;
  let readOnly = annotationState.lossAdjuster;
  if (
    (claim?.status &&
      ![Status.ClientAccepted, Status.UnderClientReview, Status.Done].includes(
        claim?.status
      )) ||
    claim?.status === Status.ClientAccepted
  ) {
    readOnly = true;
  }

  const uploadAttachment = async () => {
    if (rest.formState.isDirty && !readOnly) {
      await handleSubmit((data) => saveAnnotations(data.annotations))();
    }
    if (!file) {
      setSnackbarHandle(enqueueSavingSnackbar("Submitting change request"));
      try {
        await axiosClient.post(
          `/claims/${claimId}/${organisationId}/${selectedVersion}/annotations/submit`,
          { skipUpload: true }
        );

        queryClient.invalidateQueries(`${claimId}/reportAnnotations`);
      } catch (error) {
        snackbarHandle?.();
        enqueueErrorSnackbar("Failed to submit change request");
        setSnackbarHandle(undefined);
        setFile(null);
      }
      snackbarHandle?.();
      setSnackbarHandle(undefined);
      enqueueSuccessSnackbar("Change request submitted");
      queryClient.invalidateQueries(`${claimId}/reportAnnotations`);
      setFile(null);
      return;
    }

    await uploadFile(
      file,
      async (file, key) => {
        setSnackbarHandle(enqueueSavingSnackbar("Submitting change request"));
        try {
          await axiosClient.post(
            `/claims/${claimId}/${organisationId}/${selectedVersion}/annotations/submit`,
            { fileName: file.name, key }
          );

          queryClient.invalidateQueries(`${claimId}/reportAnnotations`);
        } catch (error) {
          snackbarHandle?.();
          enqueueErrorSnackbar("Failed to submit change request");
          setSnackbarHandle(undefined);
          setFile(null);
        }
        snackbarHandle?.();
        setSnackbarHandle(undefined);
        enqueueSuccessSnackbar("Change request submitted");
        queryClient.invalidateQueries(`${claimId}/reportAnnotations`);
        setFile(null);
      },
      "client-request"
    );
  };

  const { mutate: acceptReport } = useMutation(
    async () => {
      if (readOnly) {
        enqueueErrorSnackbar(
          "This version of the report is read-only. Please select the latest version to make changes."
        );
        return;
      }

      setSnackbarHandle(enqueueSavingSnackbar("Accepting Report"));
      try {
        return await axiosClient.put(
          `/claims/${claimId}/${organisationId}/${selectedVersion}/accept`
        );
      } catch {
        snackbarHandle?.();
        enqueueErrorSnackbar("Failed to accept report");
      }
    },
    {
      onSuccess: () => {
        snackbarHandle?.();
        setSnackbarHandle(undefined);
        enqueueSuccessSnackbar("Report accepted");
      },
    }
  );

  const { isLoading: loadingAnnotations, refetch: refetchAnnotations } =
    useQuery(
      `${claimId}/reportAnnotations`,
      async (): Promise<AnnotationRequest> =>
        (
          await axiosClient.get(
            `/claims/${claimId}/${organisationId}/${selectedVersion}/annotations`
          )
        ).data,
      {
        onSuccess: (data) => {
          setAnnotationState({
            ...data.submitted,
            latest: data.latest,
          });
          rest.reset({ annotations: data.annotations.lossAdjuster || [] });
        },
        onError(err) {
          enqueueErrorSnackbar("Failed to load annotations");
        },
      }
    );

  const { mutate: saveAnnotations } = useMutation(
    async (data: AnnotationData[]) => {
      if (readOnly) {
        enqueueErrorSnackbar(
          "This version of the report is read-only. Please select the latest version to make changes."
        );
        return;
      }

      setSnackbarHandle(enqueueSavingSnackbar("Saving Annotations"));
      try {
        return await axiosClient.put(
          `/claims/${claimId}/${organisationId}/${selectedVersion}/annotations`,
          data
        );
      } catch {
        snackbarHandle?.();
        enqueueErrorSnackbar("Failed to save annotations");
      }
    },
    {
      onSuccess: () => {
        snackbarHandle?.();
        setSnackbarHandle(undefined);
        enqueueSuccessSnackbar("Annotations saved");
      },
    }
  );

  const handleAddAnnotation = (annotation: AnnotationData) => {
    // setAnnotating(false);
    addAnnotation(annotation);
  };

  useEffect(() => {
    try {
      axiosClient
        .get<ClaimReportInfo>(
          `/claims/${claimId}/${organisationId}/report/${selectedVersion}`,
          {}
        )
        .then((response) => {
          setClaimReportInfo(response.data);
          refetchAnnotations();
          setNoReport(false);
        })
        .catch(() => {
          setNoReport(true);
        });
    } catch (error) {
      setNoReport(true);
    }
  }, [selectedVersion]);

  if (noReport) {
    return <NoReportCard />;
  }

  if (!claimReportInfo) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <FormProvider
        {...rest}
        control={control}
        handleSubmit={handleSubmit}
        setValue={setValue}
      >
        <AutoSave
          onSubmit={handleSubmit((data) => saveAnnotations(data.annotations))}
        />
      </FormProvider>
      <Paper
        elevation={4}
        sx={{
          padding: "1.5rem",
          mb: "1.5rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <input
          type="file"
          accept={acceptedFiles.join(",")}
          name="files"
          onChange={(event) => {
            if (!event.target.files?.length) return;

            setFile(event.target.files[0]);
            //   uploadSignature(event.target.files[0]);
          }}
          ref={fileInput}
          style={{ display: "none" }}
        />
        <Typography variant="h2" component="h2">
          View Claim Report
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Typography variant="h6" component="h2">
            Report Version:
          </Typography>
          <Select
            variant={"outlined"}
            sx={{
              minWidth: "8rem",
              marginLeft: "auto",
            }}
            value={claimReportInfo.current}
            onChange={(e) => setSelectedVersion(e.target.value as string)}
          >
            {claimReportInfo?.versions.map((version) => (
              <MenuItem value={version} key={version}>
                {mapVersion(version)}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Paper>
      {annotating && (
        <Paper
          sx={{
            padding: "1rem",
            marginBottom: "1.5rem",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            position: "sticky",
            top: "5rem",
            zIndex: 20,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <EditIcon />
            <p>
              <strong>Annotate mode active:</strong> Click on a spot on the
              report to add a comment
            </p>
          </Box>
          <a onClick={() => setAnnotating(!annotating)}>Cancel</a>
        </Paper>
      )}
      <ReportReviewGrid>
        <Box>
          <PDFViewer
            PDFLink={claimReportInfo.url}
            annotations={annotations || []}
            createAnnotation={handleAddAnnotation}
            setSelectedAnnotation={setSelectedAnnotation}
            selectedAnnotation={selectedAnnotation}
            annotating={annotating}
            watchResize
          />
        </Box>
        <Stack spacing={2}>
          <Stack>
            <Box
              sx={{
                display: "block",
              }}
            >
              <Paper
                elevation={4}
                sx={{
                  padding: 2,
                  overflow: "auto",
                }}
              >
                <Stack spacing={0.5}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontWeight: "bold",
                      marginRight: 1,
                      display: "inline-block",
                    }}
                    component="div"
                  >
                    Actions
                  </Typography>
                  <Divider />
                  <ConfirmationDialog
                    title="Request changes"
                    message="Are you sure you want to request changes and return this report to the assigned engineer?"
                    trigger={
                      <Button
                        variant="outlined"
                        disabled={!latestVersion ? false : true}
                      >
                        Request Changes
                      </Button>
                    }
                    onConfirm={() => setShowClientChangesModal(true)}
                  />
                  <ConfirmationDialog
                    title="Accept Report"
                    message={
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 2,
                        }}
                      >
                        Are you sure you want to accept this report?
                        <Typography variant="body2">
                          This will mark the report as complete and return it to
                          the assigned engineer.
                        </Typography>
                        <Typography variant="caption">
                          Note: This action cannot be undone.
                        </Typography>
                      </Box>
                    }
                    trigger={
                      <Button variant="contained" disabled={readOnly}>
                        Accept
                      </Button>
                    }
                    onConfirm={acceptReport}
                  />
                </Stack>
              </Paper>
            </Box>
          </Stack>
          <Stack sx={{ flex: 1 }}>
            <Box
              style={{
                display: "block",
                top: "5rem",
                position: "sticky",
                ...(annotating && { top: "9.5rem" }),
              }}
            >
              <Paper
                elevation={4}
                sx={{
                  padding: 2,
                  overflow: "auto",
                  maxHeight: "calc(60vh)",
                }}
              >
                <Stack spacing={0.5}>
                  {!readOnly && (
                    <>
                      <Typography
                        variant="subtitle2"
                        sx={{
                          fontWeight: "bold",
                          marginRight: 1,
                          display: "inline-block",
                        }}
                        component="div"
                      >
                        Reviewing Actions
                      </Typography>
                      <Divider />

                      <Button
                        variant="outlined"
                        onClick={() => setAnnotating(!annotating)}
                      >
                        {annotating ? "Stop Annotating" : "Annotate"}
                      </Button>
                      <Divider />
                    </>
                  )}
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontWeight: "bold",
                      marginRight: 1,
                      display: "inline-block",
                    }}
                    component="div"
                  >
                    Annotations
                  </Typography>
                  <Stack spacing={0.5}>
                    {annotations.map((annotation, i) => (
                      <AnnotationItem
                        key={i}
                        annotation={annotation}
                        selectedAnnotation={selectedAnnotation}
                        setSelectedAnnotation={setSelectedAnnotation}
                        removeAnnotation={() => {
                          const index = annotations.indexOf(annotation);
                          if (index > -1) removeAnnotation(index);
                        }}
                        updateAnnotation={(data) => {
                          const index = annotations.indexOf(annotation);
                          if (index > -1) {
                            updateAnnotation(index, {
                              ...annotation,
                            });
                          }
                        }}
                        readOnly={readOnly}
                      />
                    ))}
                  </Stack>
                </Stack>
              </Paper>
            </Box>
          </Stack>
        </Stack>
      </ReportReviewGrid>
      {showClientChangesModal && (
        <Dialog
          open
          onClose={() => setShowClientChangesModal(false)}
          maxWidth={false}
        >
          <DialogTitle>Client Requested Changes</DialogTitle>
          <DialogContent>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Upload optional supporting documents for the requested changes.
            </Typography>
            <Stack direction="column" spacing={2}>
              Selected: {file && `${file?.name}`}
              <Button
                variant="contained"
                onClick={() => fileInput.current?.click()}
                sx={{ ml: 1 }}
              >
                {file ? "Choose another file" : "Choose file"}
              </Button>
            </Stack>
          </DialogContent>
          <DialogActions
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button
              variant="contained"
              sx={{ width: 150, height: 48 }}
              onClick={() => {
                uploadAttachment();
                setShowClientChangesModal(false);
              }}
            >
              Skip upload
            </Button>
            <Box>
              <Button
                variant="contained"
                sx={{ width: 100, height: 48, marginRight: 2 }}
                onClick={() => {
                  uploadAttachment();
                  setShowClientChangesModal(false);
                }}
                disabled={!file}
              >
                Save
              </Button>
              <Button
                variant="outlined"
                sx={{ width: 100 }}
                onClick={() => {
                  setFile(null);
                  setShowClientChangesModal(false);
                }}
              >
                Cancel
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

const ReportReviewGrid = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 1.5rem;
  margin: auto;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`;
