import { Button, Chip } from "@mui/material";
import { FC } from "react";
import { Link } from "react-router-dom";

import {
  Status,
  underAssessmentStatuses,
} from "@athena/server/src/api/types/claimStatuses";
import { trpc } from "src/lib/api/trpc";
import { config } from "src/lib/config";
import { GeoJsonCollectionName } from "src/modules/spatialPortal/mapLib/api/constants";
import { GeoJsonCollectionPickingInfoPicked } from "src/modules/spatialPortal/mapLib/layers/GeoJsonCollectionLayer";
import { useCurrentUser } from "src/shared/hooks/useCurrentUser";
import { useViewMode } from "src/shared/hooks/useViewMode";
import {
  PopupSection,
  PopupSectionBody,
  PopupSectionContentHorizontal,
  PopupSectionContentVertical,
  PopupSectionHeader,
} from "../../../../mapLib/components/FeaturePopup/components/styled";
import { Attachments } from "../../Attachments";
import { AdditionalStatusChips } from "./AdditionalStatuesChips";

enum AvailableReport {
  None,
  Draft,
  Final,
}

interface ClaimPopupProps {
  pickInfo: GeoJsonCollectionPickingInfoPicked<GeoJsonCollectionName.ClaimPointV1>;
}

export const ClaimPopup: FC<ClaimPopupProps> = ({ pickInfo }) => {
  const { userData } = useCurrentUser();
  const { viewMode } = useViewMode();
  const { organisation } = userData || {};

  if (!organisation) {
    return "Error fetching claim data";
  }
  const passOrg = ["insurer", "loss-adjuster", "eqc"].includes(
    organisation.orgType
  );

  const feature = pickInfo.object;
  const { engineering_org_id, claim_id } = feature.properties;

  if (!engineering_org_id || !claim_id) {
    return "Error fetching claim data";
  }

  const [claimData, claimQuery] = trpc.claims.getClaim.useSuspenseQuery({
    claimId: feature.properties.claim_id,
    organisationId: passOrg ? engineering_org_id : undefined,
  });

  if (!claimData || claimQuery.isError) {
    return "Error fetching claim data";
  }

  let availableReport = AvailableReport.None;

  if (claimData.status) {
    if (
      viewMode === "engineering" &&
      underAssessmentStatuses.includes(claimData.status)
    ) {
      availableReport = AvailableReport.Draft;
    }
    if (
      [Status.UnderInternalReview, Status.UnderClientReview].includes(
        claimData.status
      )
    ) {
      availableReport = AvailableReport.Draft;
    } else if (
      [Status.ClientAccepted, Status.Done].includes(claimData.status)
    ) {
      availableReport = AvailableReport.Final;
    }
  }
  let showLink = false;

  switch (availableReport) {
    case AvailableReport.Draft:
      showLink = !["engineering"].includes(viewMode) ? false : true;
      showLink =
        ["loss-adjuster", "insurer"].includes(viewMode) &&
        claimData.status === Status.UnderClientReview
          ? true
          : showLink;
      break;
    case AvailableReport.Final:
      showLink = true;
      break;
    default:
      showLink = false;
  }

  const claimLink = passOrg
    ? `/claim/${engineering_org_id}/${claim_id}`
    : `/claim/${claim_id}/details`;

  const reportLink = passOrg
    ? `/claims/${claimData.claimId}/latest-report/${engineering_org_id}`
    : `/claims/${claimData.claimId}/latest-report`;

  //Append params to attachment urls for insurers
  let attachments = claimData.assessmentAttachments?.map((attachment) => {
    if (passOrg) {
      return {
        ...attachment,
        imageUrl: `${attachment.imageUrl}/${engineering_org_id}/${claim_id}`,
        annotatedImageUrl:
          attachment.annotatedImageUrl &&
          `${attachment.annotatedImageUrl}/${engineering_org_id}/${claim_id}`,
      };
    }
    return attachment;
  });

  if (viewMode === "eqc" && claimData.status != Status.Done) attachments = [];

  return (
    <>
      <PopupSection>
        <PopupSectionContentHorizontal>
          <PopupSectionHeader>Engineering Project Number</PopupSectionHeader>
          <Link to={claimLink}>{claimData.reference || "TBC"}</Link>
        </PopupSectionContentHorizontal>
      </PopupSection>
      <PopupSection>
        <PopupSectionContentVertical>
          <PopupSectionHeader>Insurer</PopupSectionHeader>
          <PopupSectionBody>{claimData.insurer?.name || ""}</PopupSectionBody>
        </PopupSectionContentVertical>
      </PopupSection>
      <PopupSection>
        <PopupSectionContentVertical>
          <PopupSectionHeader>Claim Location</PopupSectionHeader>
          <PopupSectionBody>
            {/* TODO: verify if this is the right address */}
            {claimData.location.manual?.address ||
              claimData.location.address ||
              ""}
          </PopupSectionBody>
        </PopupSectionContentVertical>
      </PopupSection>
      <PopupSection>
        <PopupSectionContentVertical>
          <PopupSectionHeader>Loss Cause</PopupSectionHeader>
          <PopupSectionBody>{claimData.lossCause.name || ""}</PopupSectionBody>
        </PopupSectionContentVertical>
      </PopupSection>
      <PopupSection>
        <PopupSectionContentVertical>
          <PopupSectionHeader>Claim Status</PopupSectionHeader>
          <PopupSectionBody>
            <div
              id="chipsplit"
              style={{
                display: "flex",
                gap: "0.5rem",
                flexWrap: "wrap",
                marginTop: "0.5rem",
              }}
            >
              <Chip
                label={claimData.status}
                sx={{
                  borderRadius: "4px",
                  fontSize: "1rem",
                  lineHeight: "1.5rem",
                  fontWeight: "600",
                }}
              />
              <AdditionalStatusChips statuses={claimData.additionalStatuses} />
            </div>
          </PopupSectionBody>
        </PopupSectionContentVertical>
      </PopupSection>
      <PopupSection>
        <PopupSectionContentVertical>
          <PopupSectionHeader>
            Assigned Engineer
            {(claimData.assignedEngineers?.length || 0) > 0 && "s"}
          </PopupSectionHeader>
          <PopupSectionBody>
            {claimData.assignedEngineers?.map((e) => e.name).join(", ")}
          </PopupSectionBody>
        </PopupSectionContentVertical>
      </PopupSection>
      <Attachments attachments={attachments?.filter((x) => x.featureId)} />
      <Button
        variant="outlined"
        disabled={!showLink}
        sx={
          availableReport === AvailableReport.None
            ? {
                width: "100%",
                marginTop: "1rem",
                color: "#9A9A9A!important",
                borderColor: "#9A9A9A!important",
              }
            : {
                width: "100%",
                marginTop: "1rem",
              }
        }
        href={showLink ? `${config.apiUrl}${reportLink}` : ""}
        target="_blank"
      >
        {(availableReport === AvailableReport.None || !showLink) && (
          <span>Report Not Available</span>
        )}
        {availableReport === AvailableReport.Draft && showLink && (
          <span>Download Draft Report</span>
        )}
        {availableReport === AvailableReport.Final && showLink && (
          <span>Download Report</span>
        )}
      </Button>
    </>
  );
};
