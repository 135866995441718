import { LoadingSpinner, PrivacyChip } from "@athena/components";
import styled from "@emotion/styled";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { MenuItem, Select, Tab } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { axiosClient } from "src/lib/axiosClient.ts";
import { ClaimPreview } from "src/modules/claims/ClaimPreview.tsx";
import { ClaimTimeline } from "src/modules/claims/ClaimTimeline.tsx";
import { ClaimReportInfo } from "src/modules/claims/claimReport/ClaimReportPDF.tsx";
import { useOfflineClaim } from "src/modules/claims/useOfflineClaim.tsx";
import { mapVersion } from "./claimReport/ClaimReportActions";
import { NoReportCard } from "./claimReport/components/NoReportCard";

export default function ClaimReview() {
  const queryClient = useQueryClient();
  const { claimId } = useParams();
  const { claim, loading } = useOfflineClaim(claimId);
  const [selectedVersion, setSelectedVersion] = useState("latest");
  const [claimReportInfo, setClaimReportInfo] = useState<ClaimReportInfo>();
  const [reportReady, setReportReady] = useState(false);

  useEffect(() => {
    try {
      axiosClient
        .get<ClaimReportInfo>(
          `/claims/${claimId}/report/${selectedVersion}/link`
        )
        .then((response) => {
          setClaimReportInfo(response.data);
          setReportReady(true);
          // refetchAnnotations();
        })
        .catch(() => {
          setReportReady(false);
        });
    } catch (error) {
      setReportReady(false);
    }
  }, [selectedVersion]);

  const [selectedTab, setSelectedTab] = useState("1");
  if (loading || !claim) return <LoadingSpinner />;
  return (
    <>
      <Paper
        elevation={4}
        sx={{
          padding: "1.5rem",
          mb: "1.5rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={{
            marginRight: 1,
            display: "flex",
            alignItems: "center",
            gap: "0.25rem",
          }}
        >
          <Typography variant="h2" component="p">
            Claim Report Review
          </Typography>

          <PrivacyChip shared />
        </Box>
        {claimReportInfo && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            {claimReportInfo?.versions ? (
              <>
                <Typography variant="h6" component="h2">
                  Report Version:
                </Typography>

                <Select
                  variant={"outlined"}
                  sx={{
                    minWidth: "8rem",
                    marginLeft: "auto",
                  }}
                  value={claimReportInfo.current}
                  onChange={(e) => setSelectedVersion(e.target.value as string)}
                >
                  {claimReportInfo?.versions.map((version) => (
                    <MenuItem value={version} key={version}>
                      {mapVersion(version)}
                    </MenuItem>
                  ))}
                </Select>
              </>
            ) : (
              <Typography variant="h5" component="h2">
                No report available
              </Typography>
            )}
          </Box>
        )}
      </Paper>
      <TabContext value={selectedTab}>
        <TabList
          onChange={(_, newTab) => {
            setSelectedTab(newTab);
          }}
          variant={"fullWidth"}
        >
          <Tab label="Timeline" value="1" />
          {reportReady && <Tab label="Report Review" value="2" />}
        </TabList>
        <TabPanel
          value="2"
          sx={{
            paddingX: 0,
          }}
        >
          {reportReady ? (
            <ClaimPreview
              claim={claim}
              reportReady={reportReady}
              claimReport={claimReportInfo}
              version={
                selectedVersion === "latest"
                  ? claimReportInfo?.current || "latest"
                  : selectedVersion
              }
            />
          ) : (
            <NoReportCard />
          )}
        </TabPanel>
        <TabPanel
          value="1"
          sx={{
            paddingX: 0,
          }}
        >
          <ClaimTimeline claim={claim} />
        </TabPanel>
      </TabContext>
    </>
  );
}

export const ReviewGrid = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 1rem;
  margin: auto;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`;
