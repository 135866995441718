import { IconOnlyChip } from "@athena/components";
import { Urgency } from "@athena/server/src/api/types/claim";
import {
  AdditionalStatus,
  Status,
  postVisitStatuses,
  preVisitStatuses,
  underClientReviewStatuses,
  underInternalReviewStatuses,
} from "@athena/server/src/api/types/claimStatuses";
import styled from "@emotion/styled";
import { ErrorOutline } from "@mui/icons-material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DirectionsWalkIcon from "@mui/icons-material/DirectionsWalk";
import EngineeringIcon from "@mui/icons-material/Engineering";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import ReportIcon from "@mui/icons-material/Report";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import UpdateIcon from "@mui/icons-material/Update";
// import WarningIcon from "@mui/icons-material/Warning";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import { Badge, Chip } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

type ClaimStatusChipsProps = {
  status: Status;
  additionalStatuses: AdditionalStatus[];
  size?: "small" | "medium";
  urgency?: Urgency;
  onStatusClick?: (status: string) => void;
  onAdditionalStatusClick?: (additionalStatus: string) => void;
  onUrgencyClick?: (urgency: string) => void;
  engineerControlled?: boolean;
};

export const getChipColor = (status: Status) => {
  if (preVisitStatuses.includes(status)) {
    return "#E8E8E8";
  }

  if (postVisitStatuses.includes(status)) {
    return "#E8E8E8";
  }

  if (underInternalReviewStatuses.includes(status)) {
    return "#D8EAFF";
  }

  if (underClientReviewStatuses.includes(status)) {
    return "#D8EAFF";
  }

  if (status === Status.Done) {
    return "#D7FFD7";
  }
};

export function ClaimStatusChips(props: ClaimStatusChipsProps) {
  const {
    status,
    additionalStatuses = [],
    size = "medium",
    onAdditionalStatusClick,
    onStatusClick,
    onUrgencyClick,
    urgency,
    engineerControlled,
  } = props;
  return (
    <ChipContainer>
      <Chip
        label={status}
        size={size}
        sx={{ backgroundColor: getChipColor(status as Status) }}
        onClick={
          onStatusClick
            ? () => {
                onStatusClick(status);
              }
            : undefined
        }
      />
      {additionalStatuses.includes(
        AdditionalStatus.AdditionalDetailsRequested
      ) && (
        <Tooltip title={AdditionalStatus.AdditionalDetailsRequested}>
          <span>
            <IconOnlyChip
              icon={<HelpOutlineIcon />}
              sx={{ width: "2rem" }}
              size={size}
              onClick={
                onAdditionalStatusClick
                  ? () => {
                      onAdditionalStatusClick?.(
                        AdditionalStatus.AdditionalDetailsRequested
                      );
                    }
                  : undefined
              }
            />
          </span>
        </Tooltip>
      )}
      {additionalStatuses.includes(AdditionalStatus.SiteRevisit) && (
        <Tooltip title={AdditionalStatus.SiteRevisit}>
          <span>
            <IconOnlyChip
              icon={<DirectionsWalkIcon />}
              sx={{ width: "2rem" }}
              size={size}
              onClick={
                onAdditionalStatusClick
                  ? () => {
                      onAdditionalStatusClick?.(AdditionalStatus.SiteRevisit);
                    }
                  : undefined
              }
            />
          </span>
        </Tooltip>
      )}
      {additionalStatuses.includes(AdditionalStatus.DetailsIncomplete) && (
        <Tooltip title={AdditionalStatus.DetailsIncomplete}>
          <span>
            <IconOnlyChip
              icon={<PendingActionsIcon />}
              sx={{ width: "2rem" }}
              size={size}
              onClick={
                onAdditionalStatusClick
                  ? () => {
                      onAdditionalStatusClick?.(
                        AdditionalStatus.DetailsIncomplete
                      );
                    }
                  : undefined
              }
            />
          </span>
        </Tooltip>
      )}
      {additionalStatuses.includes(AdditionalStatus.TimeExempt) && (
        <Tooltip title={AdditionalStatus.TimeExempt}>
          <span>
            <IconOnlyChip
              icon={<UpdateIcon sx={{ fill: "#7a7a7a" }} />}
              sx={{ width: "2rem" }}
              size={size}
              onClick={
                onAdditionalStatusClick
                  ? () => {
                      onAdditionalStatusClick?.(AdditionalStatus.TimeExempt);
                    }
                  : undefined
              }
            />
          </span>
        </Tooltip>
      )}
      {additionalStatuses.includes(
        AdditionalStatus.AdditionalRequestFromClient
      ) && (
        <Tooltip title={AdditionalStatus.AdditionalRequestFromClient}>
          <span>
            <IconOnlyChip
              icon={<ErrorOutline />}
              sx={{ width: "2rem" }}
              size={size}
              onClick={
                onAdditionalStatusClick
                  ? () => {
                      onAdditionalStatusClick?.(
                        AdditionalStatus.AdditionalRequestFromClient
                      );
                    }
                  : undefined
              }
            />
          </span>
        </Tooltip>
      )}
      {additionalStatuses.includes(AdditionalStatus.ConflictOfInterest) && (
        <Tooltip title={AdditionalStatus.ConflictOfInterest}>
          <span>
            <IconOnlyChip
              icon={<ReportIcon />}
              sx={{ width: "2rem" }}
              size={size}
              onClick={
                onAdditionalStatusClick
                  ? () => {
                      onAdditionalStatusClick?.(
                        AdditionalStatus.ConflictOfInterest
                      );
                    }
                  : undefined
              }
            />
          </span>
        </Tooltip>
      )}
      {additionalStatuses.includes(AdditionalStatus.Overdue) && (
        <Tooltip title={AdditionalStatus.Overdue}>
          <span>
            <IconOnlyChip
              icon={<AccessTimeIcon />}
              sx={{ width: "2rem" }}
              size={size}
              onClick={
                onAdditionalStatusClick
                  ? () => {
                      onAdditionalStatusClick?.(AdditionalStatus.Overdue);
                    }
                  : undefined
              }
            />
          </span>
        </Tooltip>
      )}
      {additionalStatuses.includes(AdditionalStatus.AutoOverdue) && (
        <Tooltip title={AdditionalStatus.Overdue}>
          <span>
            <IconOnlyChip
              icon={<AccessTimeIcon />}
              sx={{ width: "2rem" }}
              size={size}
              onClick={
                onAdditionalStatusClick
                  ? () => {
                      onAdditionalStatusClick?.(AdditionalStatus.AutoOverdue);
                    }
                  : undefined
              }
            />
          </span>
        </Tooltip>
      )}
      {urgency === Urgency.Urgent && (
        <Tooltip title={"Urgent"}>
          <span>
            <IconOnlyChip
              icon={
                <AccessTimeIcon sx={{ color: "#ffffff" }} color="primary" />
              }
              sx={{ width: "2rem", backgroundColor: "red" }}
              size={size}
              onClick={
                onAdditionalStatusClick
                  ? () => {
                      onUrgencyClick?.(Urgency.Urgent);
                    }
                  : undefined
              }
            />
          </span>
        </Tooltip>
      )}

      <TrackingStatusChips
        statuses={additionalStatuses}
        size={size}
        engineerControlled={engineerControlled}
      />
    </ChipContainer>
  );
}

interface TrackingStatusChipsProps {
  statuses: AdditionalStatus[];
  size?: "small" | "medium";
  insurer?: boolean;
  engineerControlled?: boolean;
}

export const TrackingStatusChips = (props: TrackingStatusChipsProps) => {
  const { statuses, size, insurer, engineerControlled } = props;
  if (!statuses) return null;

  const changes = [
    {
      status: AdditionalStatus.EngineerRequestedChanges,
      str: "This report has had changes requested by the reviewing engineer {0}",
      icon: <EngineeringIcon />,
      badge: true,
    },
    {
      status: AdditionalStatus.TPARequestedChanges,
      str: "This report has had changes requested by the assigned TPA {0}",
      icon: <SyncAltIcon />,
      badge: true,
    },
  ];

  if (insurer) {
    changes.push({
      status: AdditionalStatus.AutoOverdue,
      str: "Overdue",
      icon: <AccessTimeIcon />,
      badge: false,
    });

    changes.push({
      status: AdditionalStatus.Overdue,
      str: "Overdue",
      icon: <AccessTimeIcon />,
      badge: false,
    });

    changes.push({
      status: AdditionalStatus.TimeExempt,
      str: "Time Exempt",
      icon: <UpdateIcon sx={{ fill: "#7a7a7a" }} />,
      badge: false,
    });
  }

  if (engineerControlled) {
    changes.push({
      status: AdditionalStatus.DetailsIncomplete,
      str: "The status / stage gates of this claim may be under engineer control",
      icon: <ReportGmailerrorredIcon color="warning" />,
      badge: false,
    });
  }

  return changes.map((change) => {
    const count = statuses.filter((status) => status === change.status).length;
    const tooltip = change.str.replace("{0}", `${count} time(s)`);
    if (count === 0) return null;
    return (
      <Tooltip title={tooltip} key={change.status}>
        <Badge
          badgeContent={count}
          color="primary"
          overlap="circular"
          sx={{
            ".MuiBadge-badge": {
              visibility: change.badge ? "visible" : "hidden",
              fontSize: "0.5rem",
              width: "10px",
              minWidth: "12px",
              height: "12px",
            },
          }}
        >
          <IconOnlyChip icon={change.icon} sx={{ width: "2rem" }} size={size} />
        </Badge>
      </Tooltip>
    );
  });
};

const ChipContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-wrap: nowrap;
  @media (max-width: 600px) {
    flex-wrap: wrap;
    div:last-child {
      order: -1;
    }
  }
`;
