import { AutoSave, LoadingSpinner } from "@athena/components";
import { EmailPreference } from "@athena/server/src/api/types/email.ts";
import { zodResolver } from "@hookform/resolvers/zod";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import { Box } from "@mui/system";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import {
  Control,
  Form,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { axiosClient } from "src/lib/axiosClient";
import { FormSwitch } from "src/shared/form/FormSwitch.tsx";
import { FormTextField } from "src/shared/form/FormTextField.tsx";
import { useCurrentUserContext } from "src/shared/hooks/useCurrentUserContext.ts";
import { useViewMode } from "src/shared/hooks/useViewMode";
import {
  enqueueSavingSnackbar,
  enqueueSuccessSnackbar,
} from "src/shared/snackbar/SnackbarHelper.tsx";
import { v4 as uuid } from "uuid";
import { z } from "zod";

const emailPreferenceSchema = z.object({
  id: z.string(),
  name: z.string(),
  email: z.string(),
  preferences: z.object({
    assignedEngineer: z.boolean(),
    assignedReviewer: z.boolean(),
    internalChangesRequested: z.boolean(),
    externalChangesRequested: z.boolean(),
    reviewReady: z.boolean(),
    readyToSend: z.boolean(),
    claimRejected: z.boolean(),
  }),
});

const formSchema = z.object({
  emails: z.array(emailPreferenceSchema),
});

const addEmailSchema = z.object({
  name: z.string().min(5, "Name must be at least 5 characters"),
  email: z.string().email("Invalid email address"),
});

type EmailForm = {
  emails: EmailPreference[];
};
type EmailPreferencesProps = {
  loadingOrgEmails: boolean;
  orgEmails: EmailPreference[];
};

export const EmailPreferences = (props: EmailPreferencesProps) => {
  const { loadingOrgEmails, orgEmails } = props;
  const { user } = useCurrentUserContext();
  const { viewMode } = useViewMode();
  const queryClient = useQueryClient();

  const [currentDeletion, setCurrentDeletion] = useState<number>(-1);
  const [showAddEmail, setShowAddEmail] = useState(false);
  const { control, reset, watch, handleSubmit, ...rest } = useForm<EmailForm>({
    defaultValues: {
      emails: orgEmails,
    },
    resolver: zodResolver(formSchema),
  });

  const emails = watch("emails");
  const { append, fields, remove } = useFieldArray({
    control,
    name: "emails",
  });

  const handleDelete = (index: number) => {
    setCurrentDeletion(index);
  };

  const handleAddEmail = (data: EmailPreference) => {
    append(data);
    setShowAddEmail(false);
  };
  const handleClose = () => {
    setShowAddEmail(false);
  };
  const { mutate: saveEmails } = useMutation(
    async (): Promise<undefined> => {
      const finishSaving = enqueueSavingSnackbar("Save emails");

      const res = (
        await axiosClient.post(
          `/organisations/${user.organisation.id}/emails`,
          emails
        )
      ).data;
      finishSaving();
      enqueueSuccessSnackbar("Saved");
      return res;
    },
    {
      onError: () => {
        enqueueSnackbar("Something went wrong", { variant: "error" });
      },
      onSettled: () => {
        reset({ emails });
        queryClient.invalidateQueries({
          queryKey: [`orgPreferences`],
        });
      },
    }
  );

  return (
    <section
      style={{
        borderTop: "1px solid lightgray",
        paddingTop: "1.5rem",
        display: "grid",
        gridTemplateColumns: "1fr 2fr",
        gap: "3rem",
        margin: "1.5rem",
      }}
    >
      {currentDeletion > -1 && (
        <Dialog
          open={currentDeletion > -1}
          onClose={() => setCurrentDeletion(-1)}
        >
          <DialogTitle>Confirm deletion</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to remove all email notifications for the
              following email?
            </Typography>
            <li>{fields[currentDeletion].name}</li>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setCurrentDeletion(-1)}>Cancel</Button>
            <Button
              variant={"contained"}
              color={"warning"}
              onClick={() => {
                remove(currentDeletion);
                setCurrentDeletion(-1);
              }}
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <AddEmailDialog
        open={showAddEmail}
        onClose={handleClose}
        onSave={handleAddEmail}
      />
      <div>
        <Typography
          variant="h2"
          sx={{
            mb: "0.5rem",
          }}
        >
          Email Settings
        </Typography>
        <Typography
          variant="body1"
          sx={{
            mb: "1.5rem",
            pb: "1.5rem",
          }}
        >
          These settings are used to manage your organisations email preferences
        </Typography>
      </div>

      <Paper sx={{ overflow: "hidden" }}>
        <FormProvider
          {...rest}
          reset={reset}
          watch={watch}
          control={control}
          handleSubmit={handleSubmit}
        >
          <AutoSave onSubmit={() => saveEmails()} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "1.5rem",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography variant="h4">Emails</Typography>
              <Typography variant="body1" sx={{ mt: "0.5rem" }}>
                List of default emails that will be sent a carbon copy of all
                emails sent to users in this organisation
              </Typography>
            </Box>
            {!loadingOrgEmails && (
              <div style={{ display: "flex", gap: "1rem", marginTop: "1rem" }}>
                <Button
                  sx={{ width: 150 }}
                  variant={"contained"}
                  onClick={() => setShowAddEmail(true)}
                >
                  Add Email
                </Button>

                <Button
                  sx={{
                    width: 150,
                    display: rest.formState.isDirty ? "block" : "none",
                  }}
                  variant={"outlined"}
                  onClick={() => {
                    saveEmails();
                  }}
                >
                  Save
                </Button>
              </div>
            )}
          </Box>
          {loadingOrgEmails ? (
            <LoadingSpinner />
          ) : (
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow
                    sx={{
                      borderTop: "1px solid rgba(224, 224, 224, 1)",
                    }}
                  >
                    <TableCell sx={{ width: 20 }} />
                    <TableCell align={"left"}>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell sx={{ width: 100 }}>Notifications</TableCell>
                    <TableCell sx={{ width: 50 }}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {emails.length > 0 ? (
                    emails.map((row, index) => (
                      <CollapsableTableRow
                        data={row}
                        key={row.id}
                        control={control}
                        index={index}
                        onDelete={() => handleDelete(index)}
                      />
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={4}>
                        No emails preferences set...
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </FormProvider>
      </Paper>
    </section>
  );
};

type CollapsableTableRowProps = {
  data: EmailPreference;
  index: number;
  control: Control<EmailForm>;
  onDelete: () => void;
};

const CollapsableTableRow = (props: CollapsableTableRowProps) => {
  const { data, index, control, onDelete } = props;
  const { viewMode } = useViewMode();
  const [open, setOpen] = useState(false);
  return (
    <>
      <StyledTableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            color="primary"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{data.name}</TableCell>
        <TableCell>{data.email}</TableCell>
        <TableCell align={"center"}>
          {Object.values(data.preferences).filter((d) => d).length}
        </TableCell>
        <TableCell align={"center"}>
          <DeleteForeverIcon color={"error"} onClick={onDelete} />
        </TableCell>
      </StyledTableRow>
      <StyledTableRow>
        <TableCell colSpan={5} style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            sx={{ width: "100%" }}
          >
            <div
              style={{
                margin: "0.5rem",
                paddingBottom: "0.5rem",
                borderBottom: "1px solid lightgray",
              }}
            >
              <h3>Notifications</h3>
              <p>
                Notify <strong>{data.email}</strong> when the following status
                changes happen:
              </p>
            </div>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                columnGap: "1rem",
                textAlign: "center",
                alignItems: "center",
                alignContent: "center",
                placeItems: "start",
              }}
            >
              {viewMode !== "loss-adjuster" && (
                <>
                  {" "}
                  <FormSwitch
                    name={`emails.${index}.preferences.assignedEngineer`}
                    control={control}
                    label={"Assigned Engineer"}
                  />
                  <FormSwitch
                    name={`emails.${index}.preferences.assignedReviewer`}
                    control={control}
                    label={"Assigned Reviewer"}
                  />
                  <FormSwitch
                    name={`emails.${index}.preferences.internalChangesRequested`}
                    control={control}
                    label={"Internal Changes Requested"}
                  />
                  <FormSwitch
                    name={`emails.${index}.preferences.externalChangesRequested`}
                    control={control}
                    label={"External Changes Requested"}
                  />
                  <FormSwitch
                    name={`emails.${index}.preferences.claimRejected`}
                    control={control}
                    label={"Claim Rejected"}
                  />
                  <FormSwitch
                    name={`emails.${index}.preferences.clientAccepted`}
                    control={control}
                    label={"Client Accepted"}
                  />
                </>
              )}
              <FormSwitch
                name={`emails.${index}.preferences.reviewReady`}
                control={control}
                label={"Review Ready"}
              />
              <FormSwitch
                name={`emails.${index}.preferences.readyToSend`}
                control={control}
                label={"Ready to Send"}
              />
              <FormSwitch
                name={`emails.${index}.preferences.claimCompleted`}
                control={control}
                label={"Claim Completed"}
              />
            </Box>
          </Collapse>
        </TableCell>
      </StyledTableRow>
    </>
  );
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#fcfcfc",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

type AddEmailDialogProps = {
  open: boolean;
  onClose: () => void;
  onSave: (data: EmailPreference) => void;
};

const ErrorText = styled(Typography)({
  color: "red",
  marginTop: "-1rem",
  fontSize: 12,
});

const AddEmailDialog = (props: AddEmailDialogProps) => {
  const { open, onClose, onSave } = props;
  const {
    control,
    handleSubmit,
    reset,

    formState: { errors },
  } = useForm<EmailPreference>({
    resolver: zodResolver(addEmailSchema),
  });

  const handleClose = () => {
    onClose();
    reset(undefined, { keepDefaultValues: false });
  };

  const onSubmit = (data: EmailPreference) => {
    onSave({
      id: uuid(),
      name: data.name,
      email: data.email,
      preferences: {
        assignedEngineer: false,
        assignedReviewer: false,
        internalChangesRequested: false,
        externalChangesRequested: false,
        reviewReady: false,
        readyToSend: false,
        claimRejected: false,
        claimCompleted: false,
        clientAccepted: false,
      },
    });
    reset();
  };

  return (
    <Dialog open={open} sx={{ margin: "2rem" }} onClose={handleClose}>
      <DialogTitle>Add Email</DialogTitle>
      <DialogContent sx={{ minWidth: "30rem" }}>
        <Form
          onSubmit={() => {
            handleSubmit(onSubmit)();
          }}
          control={control}
          style={{ display: "flex", gap: "1rem", flexDirection: "column" }}
        >
          <FormTextField name={"name"} control={control} label={"Name"} />
          {errors.name && (
            <ErrorText color={"error"}>{errors.name.message}</ErrorText>
          )}
          <FormTextField name={"email"} control={control} label={"Email"} />
          {errors.email && <ErrorText>{errors.email.message}</ErrorText>}
          <Box>
            <Button onClick={handleClose}>Cancel</Button>
            <Button variant={"contained"} type={"submit"}>
              Save
            </Button>
          </Box>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
