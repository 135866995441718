import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Button from "@mui/material/Button";
import { HTMLInputElement } from "happy-dom";

export interface InputDialogProps {
  title: string | React.ReactNode;
  body?: React.ReactNode;
  input: React.ReactNode;
  initialValue?: string;
  trigger?: string | React.ReactNode;
  onConfirm?: (data: string) => void;
  onCancel?: (data: string) => void;
}

export const InputDialog = (props: InputDialogProps) => {
  const { title, body, initialValue, input, trigger, onConfirm, onCancel } =
    props;
  const [open, setOpen] = React.useState(false);
  const [inputValue, setInputValue] = React.useState(initialValue || "");
  const inputRef = React.useRef<HTMLInputElement>(null);

  const Trigger = () => {
    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      setOpen(!open);
    };
    if (!trigger) return <Button onClick={handleClick}>Open</Button>;
    if (typeof trigger === "string") {
      return <Button onClick={handleClick}>{trigger}</Button>;
    }
    if (React.isValidElement(trigger)) {
      const oldClick = trigger.props.onClick;
      return React.cloneElement(trigger, {
        onClick: (event) => {
          oldClick?.(event);
          handleClick(event);
        },
      } as React.DOMAttributes<HTMLElement>);
    }
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          onCancel?.(inputValue);
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          {body}
          {React.isValidElement(input) &&
            React.cloneElement(input, {
              // @ts-ignore naughty type
              onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                input.props.onChange?.(event);
                setInputValue(event.target.value);
              },
              value: inputValue,
            })}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setOpen(false);
              onConfirm?.(inputValue);
            }}
          >
            Confirm
          </Button>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              setOpen(false);
              onCancel?.(inputValue);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Trigger />
    </>
  );
};
