import styled from "@emotion/styled";
import { Box, Button, TextField } from "@mui/material";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { axiosClient } from "src/lib/axiosClient";
import { useViewMode } from "src/shared/hooks/useViewMode";
import {
  enqueueErrorSnackbar,
  enqueueSavingSnackbar,
  enqueueSuccessSnackbar,
} from "src/shared/snackbar/SnackbarHelper";

const ReplyContainer = styled.div`
  display: flex;
  border-top: 1px solid lightgray;
  padding: 0.75rem 1.5rem;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  div.splitter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1rem;
  }
  @media (max-width: 900px) {
    padding: 1rem;
    gap: 0.5rem;
    .splitter {
      flex-direction: column;
      gap: 1rem;
    }
    button {
      width: 100%;
    }
  }
`;

type ClaimCommentReplyProps = {
  claimId: string;
  historyEventId: string;
  resolved?: boolean;
  commentId: string;
  organisationId: string;
};

export const ClaimCommentReply = (props: ClaimCommentReplyProps) => {
  const { claimId, historyEventId, commentId, resolved, organisationId } =
    props;
  const { viewMode } = useViewMode();
  const queryClient = useQueryClient();
  const [message, setMessage] = useState("");
  const { mutate, isLoading } = useMutation(
    async () => {
      const finishSaving = enqueueSavingSnackbar();
      const res = (
        await axiosClient.post(
          viewMode === "loss-adjuster"
            ? `/claims/${claimId}/historyEvents/${historyEventId}/replies/${organisationId}`
            : `/claims/${claimId}/historyEvents/${historyEventId}/replies`,
          {
            message,
          }
        )
      ).data;
      finishSaving();
      return res;
    },
    {
      onSuccess: () => {
        enqueueSuccessSnackbar();
      },
      onError: () => {
        enqueueErrorSnackbar();
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [`claimHistory${claimId}`],
        });
      },
    }
  );

  const { mutate: resolveComment } = useMutation(
    async (accepted: boolean) => {
      const finishSaving = enqueueSavingSnackbar();
      await axiosClient.put(
        viewMode === "loss-adjuster"
          ? `/claims/${claimId}/historyEvents/${historyEventId}/comments/${commentId}/${organisationId}`
          : `/claims/${claimId}/historyEvents/${historyEventId}/comments/${commentId}`,
        {
          resolved: true,
          accepted: accepted,
          rejected: !accepted,
        }
      );
      finishSaving();
    },
    {
      onSuccess: () => {
        enqueueSuccessSnackbar();
      },
      onError: () => {
        enqueueErrorSnackbar();
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [`claimHistory${claimId}`],
        });
      },
    }
  );
  return (
    <ReplyContainer id={``}>
      <TextField
        minRows={1}
        sx={{
          width: "50%",
        }}
        placeholder="Reply here"
        value={message}
        onChange={(e) => {
          setMessage(e.target.value);
        }}
      />
      <Button
        variant="contained"
        sx={{ minWidth: "200px" }}
        onClick={() => {
          mutate();
          setMessage("");
        }}
        disabled={isLoading || message.length === 0}
      >
        Submit
      </Button>
      {!resolved && (
        <Box>
          <Button
            onClick={() => {
              resolveComment(true);
            }}
          >
            Resolve
          </Button>{" "}
          /
          <Button
            onClick={() => {
              resolveComment(false);
            }}
          >
            Dismiss
          </Button>
        </Box>
      )}
    </ReplyContainer>
  );
};
