import { AdditionalStatus } from "@athena/server/src/api/types/claimStatuses";
import { Box, Chip } from "@mui/material";

interface AdditionalStatusChipsProps {
  statuses: AdditionalStatus[] | undefined;
}

export const AdditionalStatusChips = (props: AdditionalStatusChipsProps) => {
  const { statuses } = props;
  if (!statuses) return null;
  const grouped = statuses.reduce((acc, status) => {
    if (!acc[status]) {
      acc[status] = 0;
    }
    acc[status] += 1;
    return acc;
  }, {} as Record<AdditionalStatus, number>);

  return (
    <>
      {Object.entries(grouped).map(([status, count]) => (
        <div
          key={status}
          style={{
            position: "relative",
          }}
        >
          <Chip
            label={status}
            sx={{
              position: "relative",
              borderRadius: "4px",
              fontSize: "1rem",
              lineHeight: "1.5rem",
              fontWeight: "600",
            }}
          />
          {count > 1 && (
            <Box
              sx={{
                position: "absolute",

                top: 0,
                right: 0,
                transform: "translate(25%, -25%)",
                backgroundColor: "#0067DF",
                borderRadius: "100%",
                color: "white",
                fontSize: "0.75rem",
                textAlign: "center",
                lineHeight: "1rem",
                aspectRatio: "1/1",
                minWidth: "1rem",
              }}
            >
              {count}
            </Box>
          )}
        </div>
      ))}
    </>
  );
};
