import { ClaimDetailsSection, LoadingSpinner } from "@athena/components";
import { UpdateClaim } from "@athena/server/src/api/types/claimAssessment";
import { ClaimEventHistoryType } from "@athena/server/src/api/types/claimStatuses";
import { ClaimHistoryEvent } from "@athena/server/src/dynamo-db/client.ts";
import styled from "@emotion/styled";
import ForumIcon from "@mui/icons-material/Forum";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import RepeatIcon from "@mui/icons-material/Repeat";
import Timeline from "@mui/lab/Timeline";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import { Divider, Stack, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import formatDistance from "date-fns/formatDistance";
import { useMemo, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { config } from "src/lib/config";
import { downloadFile } from "src/shared/dataUtils/downloadImage";
import { getAccessTokenFromCookie } from "src/shared/dataUtils/getAccessToken";
import { useCurrentUserContext } from "src/shared/hooks/useCurrentUserContext";
import {
  enqueueErrorSnackbar,
  enqueueSavingSnackbar,
  enqueueSuccessSnackbar,
} from "src/shared/snackbar/SnackbarHelper";
import { InlineUser } from "src/shared/user/InlineUser";
import { axiosClient } from "../../lib/axiosClient";
import { formatDate } from "../../lib/date";
import { ClaimCommentReplies } from "./ClaimCommentReplies";
import { ClaimCommentReply } from "./ClaimCommentReply";
import { ReviewGrid } from "./ClaimReview";
import { useLoadSummarySources } from "./Maps/hooks/useLoadSummarySources";

const MessageContainer = styled.div`
  display: flex;
  padding: 1.5rem;
  flex-direction: column;
`;
const AttachmentContainer = styled.div`
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  border-radius: 12px;
  border: 1px solid lightgray;

  section {
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: space-between;

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
    }

    img {
      width: 2rem;
      height: 2rem;
    }

    p {
      text-align: left;
    }
  }
`;
const CommentBox = styled.div`
  border: 1px solid lightgray;
  border-radius: 12px;

  div.splitter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1rem;
  }

  @media (max-width: 900px) {
    div.splitter {
      flex-direction: column;
      gap: 1rem;
    }
  }
`;

const TopLevelCommentBox = styled.div`
  border: 1px solid lightgray;
  border-radius: 12px;
  padding: 1rem;

  div.splitter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1rem;
  }

  @media (max-width: 900px) {
    div.splitter {
      flex-direction: column;
      gap: 1rem;
    }
  }
`;

export const getTimelineEventContent = (
  event: any,
  addTopConnector = true,
  addBottomConnector = true
) => {
  const usersName = `${event.user.name}`;
  if (event.eventType === ClaimEventHistoryType.ClaimCreated)
    return timelineEventContent(
      event,
      <TimelineDot>
        <NoteAddOutlinedIcon />
      </TimelineDot>,
      <span>
        <strong>
          <a href="#">{usersName}</a>
        </strong>{" "}
        created the claim
      </span>,
      addTopConnector,
      addBottomConnector
    );

  if (event.eventType === ClaimEventHistoryType.CommentAdded) {
    return timelineEventContent(
      event,
      <TimelineDot>
        <ForumIcon />
      </TimelineDot>,
      <CommentBox>
        <Stack divider={<Divider />}>
          <MessageContainer>
            <Box sx={{ mb: 1 }}>
              <InlineUser name={usersName} />
            </Box>

            {event.details.comment.message}
          </MessageContainer>
          {event.details.comment.replies.length && (
            <ClaimCommentReplies
              replies={event.details.comment.replies}
              threadResolved={event.details.comment.resolved}
            />
          )}
          {event.details.comment.resolved && (
            <Box sx={{ pl: 3, mb: 1 }}>
              {event.details.comment.accepted ? (
                <Typography variant="subtitle1">
                  This thread has been marked as resolved.
                </Typography>
              ) : (
                <Typography variant="subtitle1">
                  This thread has been marked as rejected.
                </Typography>
              )}
            </Box>
          )}
        </Stack>

        {!event.details.comment.resolved && !event.details.comment.accepted ? (
          <ClaimCommentReply
            claimId={event.claimId}
            organisationId={event.organisationId}
            historyEventId={event.claimHistoryEventId}
            commentId={event.details.comment.claimCommentId}
            resolved={event.details.comment.resolved}
          />
        ) : (
          ""
        )}
      </CommentBox>,
      addTopConnector,
      addBottomConnector
    );
  }
  if (event.eventType === ClaimEventHistoryType.CommentReply) {
    return timelineEventContent(
      event,
      <TimelineDot>
        <ForumIcon />
      </TimelineDot>,
      <CommentBox>
        <Stack divider={<Divider />}>
          <MessageContainer>
            <Box sx={{ mb: 1 }}>
              <InlineUser name={usersName} />
            </Box>
            <p>
              {usersName} replied to{" "}
              <a href={`#comment-${event.claimHistoryEventId}`}>this thread</a>{" "}
              with the message &quot;{event.details.comment.message}&quot;
            </p>
          </MessageContainer>
        </Stack>
      </CommentBox>,
      addTopConnector,
      addBottomConnector
    );
  }
  if (event.eventType === ClaimEventHistoryType.CompletedClaimDetails)
    return timelineEventContent(
      event,
      <TimelineDot>
        <RepeatIcon />
      </TimelineDot>,
      <span>
        <strong>
          <a href="#">{usersName}</a>
        </strong>{" "}
        completed claim details - claim moved to{" "}
        <i>{event.details.statusChangedTo}</i>
      </span>,
      addTopConnector,
      addBottomConnector
    );

  if (event.eventType === ClaimEventHistoryType.EngineerAssigned)
    return timelineEventContent(
      event,
      <TimelineDot>
        <RepeatIcon />
      </TimelineDot>,
      <span>
        <strong>
          <a href="#">{event.details.assignee}</a>
        </strong>{" "}
        was assigned to this claim by{" "}
        <strong>
          <a href="#">{event.user.name}</a>
        </strong>
      </span>,
      addTopConnector,
      addBottomConnector
    );

  if (event.eventType === ClaimEventHistoryType.AssessmentStarted)
    return timelineEventContent(
      event,
      <TimelineDot sx={{ bgcolor: "#D8EAFF" }}>
        <RepeatIcon sx={{ color: "#57a4fd" }} />
      </TimelineDot>,
      <span>
        <strong>
          <a href="#">{usersName}</a>
        </strong>{" "}
        started assessing this claim - status moved to{" "}
        <i>{event.details.statusChangedTo}</i>
      </span>,
      addTopConnector,
      addBottomConnector
    );

  if (event.eventType === ClaimEventHistoryType.GeneratedDraftReport)
    return timelineEventContent(
      event,
      <TimelineDot sx={{ bgcolor: "#D8EAFF" }}>
        <ModeEditOutlineOutlinedIcon sx={{ color: "#57a4fd" }} />
      </TimelineDot>,
      <span>
        <strong>
          <a href="#">{usersName}</a>
        </strong>{" "}
        generated a Draft Report
      </span>,
      addTopConnector,
      addBottomConnector
    );

  if (event.eventType === ClaimEventHistoryType.EditedDraftReport)
    return timelineEventContent(
      event,
      <TimelineDot sx={{ bgcolor: "#D8EAFF" }}>
        <ModeEditOutlineOutlinedIcon sx={{ color: "#57a4fd" }} />
      </TimelineDot>,
      <span>
        <strong>
          <a href="#">{usersName}</a>
        </strong>{" "}
        edited the Draft Report
      </span>,
      addTopConnector,
      addBottomConnector
    );

  if (event.eventType === ClaimEventHistoryType.SubmittedDraftReportForReview)
    return timelineEventContent(
      event,
      <TimelineDot sx={{ bgcolor: "#D8EAFF" }}>
        <ModeEditOutlineOutlinedIcon sx={{ color: "#57a4fd" }} />
      </TimelineDot>,
      <span>
        <strong>
          <a href="#">{usersName}</a>
        </strong>{" "}
        submited the Draft Report for review - status moved to{" "}
        <i>{event.details.statusChangedTo}</i>
      </span>,
      addTopConnector,
      addBottomConnector
    );

  if (event.eventType === ClaimEventHistoryType.ReviewingEngineerAssigned)
    return timelineEventContent(
      event,
      <TimelineDot sx={{ bgcolor: "#D8EAFF" }}>
        <ModeEditOutlineOutlinedIcon sx={{ color: "#57a4fd" }} />
      </TimelineDot>,
      <span>
        <strong>
          <a href="#">{usersName}</a>
        </strong>{" "}
        {usersName === event.details.assignee ? (
          "assigned themselves as a Reviewing Engineer"
        ) : (
          <>
            assigned <strong>{event.details.assignee}</strong> as a Reviewing
            Engineer
          </>
        )}{" "}
        <i>{event.details.statusChangedTo}</i>
      </span>,
      addTopConnector,
      addBottomConnector
    );

  if (event.eventType === ClaimEventHistoryType.ApprovedDraftReport)
    return timelineEventContent(
      event,
      <TimelineDot sx={{ bgcolor: "#D8EAFF" }}>
        <ModeEditOutlineOutlinedIcon sx={{ color: "#57a4fd" }} />
      </TimelineDot>,
      <span>
        <strong>
          <a href="#">{usersName}</a>
        </strong>{" "}
        approved the Draft Report
      </span>,
      addTopConnector,
      addBottomConnector
    );

  if (event.eventType === ClaimEventHistoryType.LossAdjusterSentReport)
    return timelineEventContent(
      event,
      <TimelineDot sx={{ bgcolor: "#D8EAFF" }}>
        <ModeEditOutlineOutlinedIcon sx={{ color: "#57a4fd" }} />
      </TimelineDot>,
      <span>
        <strong>
          <a href="#">{usersName}</a>
        </strong>{" "}
        sent the Claim Report to <strong>{event.details.lossAdjuster}</strong>{" "}
        (Client Loss Adjuster)
      </span>,
      addTopConnector,
      addBottomConnector
    );

  if (event.eventType === ClaimEventHistoryType.LossAdjusterReceivedReport)
    return timelineEventContent(
      event,
      <TimelineDot sx={{ bgcolor: "#D8EAFF" }}>
        <ModeEditOutlineOutlinedIcon sx={{ color: "#57a4fd" }} />
      </TimelineDot>,
      <span>
        <strong>
          <a href="#">{usersName}</a>
        </strong>{" "}
        confirmed receipt of the Claim Report - status moved to{" "}
        <i>{event.details.statusChangedTo}</i>
      </span>,
      addTopConnector,
      addBottomConnector
    );

  if (event.eventType === ClaimEventHistoryType.LossAdjusterRequestedChanges) {
    const url =
      `${config.apiUrl}/${event.details.file?.url}` +
      "?accessToken=" +
      getAccessTokenFromCookie();
    const hasDocument = event.details.document ? true : event.details.file;
    return timelineEventContent(
      event,
      <TimelineDot sx={{ bgcolor: "#D8EAFF" }}>
        <ModeEditOutlineOutlinedIcon sx={{ color: "#57a4fd" }} />
      </TimelineDot>,
      <span>
        <strong>TPA</strong> requested changes -{" "}
        <strong>{event.user.name}</strong>
        <AttachmentContainer>
          {hasDocument ? (
            <section>
              <div>
                <img src="/images/icons/common-file-double.svg" alt="file" />
                <a
                  href={url}
                  onClick={(e) => {
                    e.preventDefault();
                    downloadFile(url, event.details.file?.name);
                  }}
                >
                  {event.details.file?.name}
                </a>
              </div>
            </section>
          ) : (
            <section>No supporting document, check annotations</section>
          )}
        </AttachmentContainer>
      </span>,
      addTopConnector,
      addBottomConnector
    );
  }

  if (event.eventType === ClaimEventHistoryType.SnapshotCreated) {
    const url =
      `${config.apiUrl}/${event.details.file?.url}` +
      "?accessToken=" +
      getAccessTokenFromCookie();
    return timelineEventContent(
      event,
      <TimelineDot sx={{ bgcolor: "#D8EAFF" }}>
        <ModeEditOutlineOutlinedIcon sx={{ color: "#57a4fd" }} />
      </TimelineDot>,
      <span>
        <strong>{usersName}</strong> snapshotted the assessment form
        <AttachmentContainer>
          <section>
            <div>
              <img src="/images/icons/common-file-double.svg" alt="file" />
              <a
                href={url}
                onClick={(e) => {
                  e.preventDefault();
                  downloadFile(url, event.details.file?.name);
                }}
              >
                {event.details.file?.name}
              </a>
            </div>
          </section>
        </AttachmentContainer>
      </span>,
      addTopConnector,
      addBottomConnector
    );
  }

  if (event.eventType === ClaimEventHistoryType.LossAdjusterApprovedClaimReport)
    return timelineEventContent(
      event,
      <TimelineDot sx={{ bgcolor: "#D8EAFF" }}>
        <ModeEditOutlineOutlinedIcon sx={{ color: "#57a4fd" }} />
      </TimelineDot>,
      <span>
        <strong>
          <a href="#">{usersName}</a>
        </strong>{" "}
        approved the claim report - status moved to{" "}
        <i>{event.details.statusChangedTo}</i>
      </span>,
      addTopConnector,
      addBottomConnector
    );

  if (event.eventType === ClaimEventHistoryType.EditedClaimAssessment)
    return timelineEventContent(
      event,
      <TimelineDot sx={{ bgcolor: "#D8EAFF" }}>
        <ModeEditOutlineOutlinedIcon sx={{ color: "#57a4fd" }} />
      </TimelineDot>,
      <span>
        <strong>
          <a href="#">{usersName}</a>
        </strong>{" "}
        edited the Claim Assessment
      </span>,
      addTopConnector,
      addBottomConnector
    );

  if (event.eventType === ClaimEventHistoryType.ApprovedForFinalRelease)
    return timelineEventContent(
      event,
      <TimelineDot sx={{ bgcolor: "#D8EAFF" }}>
        <ModeEditOutlineOutlinedIcon sx={{ color: "#57a4fd" }} />
      </TimelineDot>,
      <span>
        <strong>
          <a href="#">{usersName}</a>
        </strong>{" "}
        approved the claim report for final release - status moved to{" "}
        <i>{event.details.statusChangedTo}</i>
      </span>,
      addTopConnector,
      addBottomConnector
    );

  if (event.eventType === ClaimEventHistoryType.FinalReportSent)
    return timelineEventContent(
      event,
      <TimelineDot sx={{ bgcolor: "#D8EAFF" }}>
        <ModeEditOutlineOutlinedIcon sx={{ color: "#57a4fd" }} />
      </TimelineDot>,
      <span>
        <strong>
          <a href="#">{usersName}</a>
        </strong>{" "}
        sent the final report to <strong>{event.details.lossAdjuster}</strong>
      </span>,
      addTopConnector,
      addBottomConnector
    );

  if (event.eventType === ClaimEventHistoryType.StatusChange)
    return timelineEventContent(
      event,
      <TimelineDot sx={{ bgcolor: "#D8EAFF" }}>
        <ModeEditOutlineOutlinedIcon sx={{ color: "#57a4fd" }} />
      </TimelineDot>,
      <span>
        <strong>Claim Status</strong> moved to{" "}
        <strong>{event.details.statusChangedTo}</strong>
      </span>,
      addTopConnector,
      addBottomConnector
    );

  if (event.eventType === ClaimEventHistoryType.UrgencyChanged)
    return timelineEventContent(
      event,
      <TimelineDot sx={{ bgcolor: "#D8EAFF" }}>
        <ModeEditOutlineOutlinedIcon sx={{ color: "#57a4fd" }} />
      </TimelineDot>,
      <span>
        <strong>Claim Urgency</strong> changed to{" "}
        <strong>{event.details.urgencyChangedTo}</strong> by{" "}
        <strong>{event.user.name}</strong>
      </span>,
      addTopConnector,
      addBottomConnector
    );
};

export const timelineEventContent = (
  event: any,
  icon: React.ReactNode,
  message: React.ReactNode,
  addBottomConnector = true,
  addTopConnector = true
) => {
  return (
    <>
      <TimelineSeparator>
        {addTopConnector && <TimelineConnector />}
        {icon}
        {addBottomConnector && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent sx={{ py: "12px", px: 2 }}>
        <Typography variant="body1" component="p">
          {message}
        </Typography>
        <Typography variant="body1" component="p" sx={{ color: "#757575" }}>
          {formatDistance(new Date(event.created), new Date(), {
            addSuffix: true,
          })}{" "}
          - {formatDate(new Date(event.created))}
        </Typography>
      </TimelineContent>
    </>
  );
};

interface ClaimTimelineProps {
  claim: UpdateClaim;
}

export function ClaimTimeline(props: ClaimTimelineProps) {
  const { claim } = props;
  const queryClient = useQueryClient();
  const [comment, setComment] = useState("");

  const summarySources = useLoadSummarySources(
    claim?.landslips?.map((ls) => ls.landslipId) || [],
    claim?.stormAndFlood?.stormAndFloodId || ""
  );
  const { isLoading, data } = useQuery<ClaimHistoryEvent[]>(
    `claimHistory${claim?.claimId}`,
    async () =>
      (await axiosClient.get(`/claims/${claim?.claimId}/history`)).data,
    { enabled: !!claim?.claimId }
  );

  const { user } = useCurrentUserContext();
  // const { mutate: completeClaim } = useMutation(
  //   async () => {
  //     const finishSaving = enqueueSavingSnackbar();

  //     const res = (
  //       await axiosClient.put(`/claims/${claim?.claimId}/status`, {
  //         status: Status.Done,
  //       })
  //     ).data;
  //     finishSaving();
  //     return res;
  //   },
  //   {
  //     onSuccess: () => {
  //       enqueueSuccessSnackbar();
  //     },
  //     onError: () => {
  //       enqueueErrorSnackbar();
  //     },
  //     onSettled: () => {
  //       queryClient.invalidateQueries({
  //         queryKey: [`claim${claim?.claimId}`],
  //       });
  //     },
  //   }
  // );

  const { mutate: snapshotClaim } = useMutation(
    async () => {
      const finishSaving = enqueueSavingSnackbar();
      const { landslipSources, ...sources } = summarySources;
      const featureCollections = await Promise.all(
        Object.values(sources).map(async (source) => {
          return {
            url: source.getUrl(),
            collection: (await axiosClient.get(source.getUrl() as string)).data,
          };
        })
      );
      const landslips = await Promise.all(
        landslipSources.map(async (landslip) => {
          const { landslipId, ...sources } = landslip;
          const collections = await Promise.all(
            Object.values(sources).map(async (source) => {
              return {
                url: source.getUrl(),
                collection: (await axiosClient.get(source.getUrl() as string))
                  .data,
              };
            })
          );
          return { landslipId, collections };
        })
      );

      const res = (
        await axiosClient.put(`/claims/${claim?.claimId}/snapshot`, {
          gis: { featureCollections, landslips },
        })
      ).data;
      finishSaving();
      return res;
    },
    {
      onSuccess: () => {
        enqueueSuccessSnackbar();
      },
      onError: () => {
        enqueueErrorSnackbar();
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [`claim${claim?.claimId}`],
        });
      },
    }
  );

  const { mutate: saveComment } = useMutation(
    async () => {
      const finishSaving = enqueueSavingSnackbar();

      const res = (
        await axiosClient.post(`/claims/${claim?.claimId}/comments`, {
          message: comment,
        })
      ).data;
      finishSaving();
      return res;
    },
    {
      onSuccess: () => {
        enqueueSuccessSnackbar();
      },
      onError: () => {
        enqueueErrorSnackbar();
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [`claimHistory${claim?.claimId}`],
        });
      },
    }
  );

  const eventCount = useMemo(() => {
    return data?.reduce((acc, event: ClaimHistoryEvent) => {
      if (
        !event.eventType ||
        event.eventType != ClaimEventHistoryType.StatusChange
      )
        return acc;
      const status = event.details.statusChangedTo;
      if (!status) return acc;

      if (status && acc[status]) {
        acc[status]++;
      } else {
        acc[status] = 1;
      }
      return acc;
    }, {} as Record<string, number>);
  }, [data]);

  if (!claim) return <LoadingSpinner />;
  return (
    <>
      <ReviewGrid>
        <>
          <Paper elevation={4} sx={{ padding: "1.5rem" }}>
            <ClaimDetailsSection
              title="Comment"
              shared
              tooltip="This section is shared across organisations"
              id={``}
            >
              <TopLevelCommentBox>
                <TextField
                  multiline
                  sx={{ width: "100%" }}
                  placeholder="Enter a comment here"
                  value={comment}
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                />

                <div className="splitter">
                  <Button
                    variant="contained"
                    sx={{ minWidth: "200px" }}
                    disabled={comment.length === 0}
                    onClick={() => {
                      saveComment();
                      setComment("");
                    }}
                  >
                    Submit
                  </Button>
                </div>
              </TopLevelCommentBox>
            </ClaimDetailsSection>
            <Timeline
              sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
                [`& .MuiTimelineContent-root`]: {
                  paddingRight: 0,
                },
                paddingRight: "0",
              }}
            >
              {isLoading && <LoadingSpinner />}
              {data?.map((event: any, i: number) => {
                return (
                  <TimelineItem key={event.claimHistoryEventId}>
                    {getTimelineEventContent(
                      event,
                      i + 1 != data.length,
                      i != 0
                    )}
                  </TimelineItem>
                );
              })}
            </Timeline>
          </Paper>
          <Box>
            <Paper elevation={4} sx={{ padding: 2, minWidth: 300 }}>
              {/* {(user.roles.includes("coordinator") ||
                user.roles.includes("data-entry")) && (
                <Tooltip
                  title={
                    canCompleteClaim
                      ? "Click to complete the claim"
                      : "The claim needs to be in Client Accepted before it can be completed"
                  }
                >
                  <div style={{ marginBottom: 12 }}>
                    <Button
                      fullWidth
                      onClick={() => completeClaim()}
                      variant="contained"
                      disabled={!canCompleteClaim}
                    >
                      Complete Claim
                    </Button>
                  </div>
                </Tooltip>
              )} */}
              <Tooltip
                title={
                  "This will create a point in time data back up of the assessment, will which be available to download as a JSON file. This can't be automatically restored"
                }
              >
                <div>
                  <Button
                    fullWidth
                    onClick={() => {
                      snapshotClaim();
                    }}
                    variant="outlined"
                  >
                    Snapshot Assessment Form
                  </Button>
                </div>
              </Tooltip>
            </Paper>

            <Paper
              className="review-detail"
              elevation={4}
              sx={{ padding: "1.5rem", height: "fit-content", mt: 2 }}
            >
              {!!claim.assignedEngineers?.length && (
                <>
                  <ProfileHeader>Assessed by</ProfileHeader>
                  <Stack>
                    {claim.assignedEngineers.map((assignedEngineer) => (
                      <InlineUser
                        name={assignedEngineer.name}
                        key={assignedEngineer.id}
                      />
                    ))}
                  </Stack>
                </>
              )}

              {!!claim.reviewingEngineers?.length && (
                <>
                  <ProfileHeader>Reviewed by</ProfileHeader>
                  <Stack>
                    {claim.reviewingEngineers.map((reviewingEngineer) => (
                      <InlineUser
                        name={reviewingEngineer.name}
                        key={reviewingEngineer.id}
                      />
                    ))}
                  </Stack>
                </>
              )}

              <ProfileHeader>Client review by</ProfileHeader>
              {claim.lossAdjusterUserName && (
                <InlineUser name={claim.lossAdjusterUserName} />
              )}
            </Paper>
            {eventCount && (
              <Paper
                className="review-detail"
                elevation={4}
                sx={{ padding: "1.5rem", height: "fit-content", mt: 2 }}
              >
                {!!claim.assignedEngineers?.length && (
                  <>
                    <ProfileHeader>Status overview</ProfileHeader>
                    <Stack>
                      {Object.keys(eventCount).map((key) => {
                        return (
                          <ClaimStatusChip
                            key={key}
                            status={key}
                            count={eventCount[key]}
                          />
                        );
                      })}
                    </Stack>
                  </>
                )}
              </Paper>
            )}
          </Box>
        </>
      </ReviewGrid>
    </>
  );
}

export const ClaimStatusChip = ({
  status,
  count,
}: {
  status: string;
  count: number;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "0",
      }}
    >
      <h3 style={{ fontSize: "1rem" }}>{count}</h3>&nbsp;-&nbsp;{status}
    </Box>
  );
};

const ProfileHeader = styled.h4`
  font-size: 1rem;
  font-weight: 600;
  padding-top: 1.25rem;
  margin-top: 1.25rem;
  border-top: 1px solid lightgray;
  margin-bottom: 0.75rem;

  :first-of-type {
    border-top: none;
    margin-top: 0;
    padding-top: 0;
  }
`;
