import React from "react";
import {
  Annotation,
  AnnotationData,
} from "src/modules/claims/claimReport/components/Annotation.tsx";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
} from "@mui/material";
import { Box } from "@mui/system";
import { Page } from "react-pdf";
import { v4 as uuid } from "uuid";
import TextField from "@mui/material/TextField";
import { useCurrentUserContext } from "src/shared/hooks/useCurrentUserContext.ts";

interface PageContainerProps {
  page: number;
  createAnnotation: (c: AnnotationData) => void;
  setSelectedAnnotation?: (id: string) => void;
  selectedAnnotation?: string;
  annotations: AnnotationData[];
  annotating: boolean;
  onRenderFinish?: () => void;
}

export const PageContainer = (props: PageContainerProps) => {
  const {
    page,
    createAnnotation,
    annotations,
    annotating,
    onRenderFinish,
    setSelectedAnnotation,
    selectedAnnotation,
  } = props;
  const { user } = useCurrentUserContext();
  const [tempAnnotation, setTempAnnotation] = React.useState<AnnotationData>();
  const [addingAnnotation, setAddingAnnotation] = React.useState(false);
  const [annotationText, setAnnotationText] = React.useState("");

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    if (!annotations) {
      return;
    }
    setAddingAnnotation(true);
    const bounds = e.currentTarget.getBoundingClientRect();
    setTempAnnotation({
      id: uuid(),
      text: "",
      location: {
        x: e.clientX - bounds.left - 5,
        y: e.clientY - bounds.top - 5,
      },
      page,
      timestamp: Date.now(),
      createdBy: `${user?.firstName} ${user?.lastName}`,
    });
  };
  const pageComments = annotations
    .filter((a) => a.page === page)
    .concat(tempAnnotation || []);
  return (
    <Paper
      key={`page-${page}`}
      elevation={4}
      sx={{
        padding: "0.75rem",
        alignItems: "center",
        position: "relative",
        width: "fit-content",
        height: "fit-content",
        minWidth: 627,
      }}
    >
      {addingAnnotation && (
        <Dialog open={addingAnnotation}>
          <DialogTitle>Add a comment</DialogTitle>
          <DialogContent>
            <TextField
              multiline
              minRows={4}
              sx={{
                width: 500,
                fontSize: 11,
              }}
              onChange={(e) => {
                setAnnotationText(e.currentTarget.value);
              }}
            >
              {annotationText}
            </TextField>
            <DialogActions>
              <Button
                variant="outlined"
                onClick={() => {
                  if (tempAnnotation && annotationText !== "") {
                    createAnnotation({
                      ...tempAnnotation,
                      text: annotationText,
                    });
                  }
                  setAddingAnnotation(false);
                  setTempAnnotation(undefined);
                }}
              >
                Save
              </Button>
              <Button
                variant="outlined"
                onClick={() => {
                  setAddingAnnotation(false);
                  setTempAnnotation(undefined);
                }}
              >
                Cancel
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      )}
      <Box
        sx={{
          pointerEvents: annotating ? "all" : "none",
          position: "absolute",
          zIndex: 3,
          minWidth: "calc(100% - 1.5rem)",
          height: "calc(100% - 1.5rem)",
        }}
        onMouseUp={handleClick}
      >
        {pageComments.map((annotation, i) => (
          <Annotation
            key={i}
            annotation={annotation}
            selectedAnnotation={selectedAnnotation}
            setSelectedAnnotation={setSelectedAnnotation}
          />
        ))}
      </Box>
      <Page scale={1} pageNumber={page} onRenderSuccess={onRenderFinish} />
    </Paper>
  );
};
