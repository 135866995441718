import { LoadingSpinner } from "@athena/components";
import { UpdateClaim } from "@athena/server/src/api/types/claimAssessment";
import { Paper, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { axiosClient } from "src/lib/axiosClient.ts";
import { ReviewGrid } from "src/modules/claims/ClaimReview.tsx";
import { AnnotationRequest } from "src/modules/claims/claimReport/ClaimReportPDF.tsx";
import { PDFViewer } from "src/modules/claims/claimReport/components/PDFViewer.tsx";
import { AnnotationItem } from "./claimReport/components/AnnotationItem";
import { NoReportCard } from "./claimReport/components/NoReportCard";

interface ClaimPreviewProps {
  claim: UpdateClaim;
  claimReport: any;
  version: string;
  reportReady: boolean;
}

export function ClaimPreview(props: ClaimPreviewProps) {
  const { claim, version, claimReport, reportReady } = props;
  const [annotations, setAnnotations] = useState<AnnotationRequest>();
  const [selectedAnnotation, setSelectedAnnotation] = useState<string>();
  const { isLoading: loadingAnnotations, refetch: refetchAnnotations } =
    useQuery(
      `reportAnnotations`,
      async (): Promise<AnnotationRequest> =>
        (
          await axiosClient.get(
            `/claims/${claim.claimId}/${version}/annotations`
          )
        ).data,
      {
        onSuccess: (data) => {
          setAnnotations(data);
        },
      }
    );

  useEffect(() => {
    refetchAnnotations();
  }, [version]);

  if (loadingAnnotations) return <LoadingSpinner />;
  return (
    <ReviewGrid>
      <Box
        sx={{
          minWidth: "100%",
        }}
      >
        {claimReport && reportReady ? (
          <PDFViewer
            PDFLink={claimReport?.url}
            annotations={annotations?.annotations?.lossAdjuster || []}
            createAnnotation={() => {}}
            selectedAnnotation={selectedAnnotation}
            setSelectedAnnotation={setSelectedAnnotation}
            watchResize
          />
        ) : reportReady ? (
          <LoadingSpinner />
        ) : (
          <NoReportCard />
        )}
      </Box>
      <Stack spacing={0.5}>
        <Paper
          elevation={4}
          sx={{
            padding: 2,
            overflow: "auto",
            display: "block",
            position: "sticky",
            top: "4rem",
            maxHeight: "calc(60vh)",
          }}
        >
          <Stack spacing={0.5}>
            <Typography
              variant="subtitle2"
              sx={{
                fontWeight: "bold",
                marginRight: 1,
                display: "inline-block",
              }}
              component="div"
            >
              Annotations
            </Typography>
            <Stack spacing={0.5}>
              {reportReady &&
                annotations?.annotations &&
                annotations?.annotations.lossAdjuster?.map((annotation, i) => (
                  <AnnotationItem
                    key={i}
                    annotation={annotation}
                    selectedAnnotation={selectedAnnotation}
                    setSelectedAnnotation={setSelectedAnnotation}
                    readOnly
                  />
                ))}
              {(!annotations?.annotations ||
                !annotations?.annotations.lossAdjuster ||
                annotations.annotations.lossAdjuster.length === 0 ||
                !reportReady) && (
                <Typography variant="body2" component="div">
                  No annotations available
                </Typography>
              )}
            </Stack>
          </Stack>
        </Paper>
      </Stack>
    </ReviewGrid>
  );
}
