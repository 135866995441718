import { ConfirmationDialog, InputDialog } from "@athena/components";
import { TextField, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { InlineUser } from "src/shared/user/InlineUser";
import { AnnotationData } from "./Annotation";

import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";

interface AnnotationItemProps {
  annotation: AnnotationData;
  selectedAnnotation?: string;
  readOnly: boolean;
  setSelectedAnnotation: (id: string) => void;
  updateAnnotation?: (annotation: AnnotationData) => void;
  removeAnnotation?: () => void;
}

export const AnnotationItem = (props: AnnotationItemProps) => {
  const {
    annotation,
    selectedAnnotation,
    setSelectedAnnotation,
    readOnly: submitted,
    updateAnnotation,
    removeAnnotation,
  } = props;
  const selected = selectedAnnotation === annotation.id;

  return (
    <Stack
      sx={{
        padding: 1,
        backgroundColor: selected ? "rgb(233, 237, 255)" : "",
        borderRadius: "4px",

        borderTop: "1px solid #f2f2f2",
      }}
      onClick={() => {
        setSelectedAnnotation(annotation.id);
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <InlineUser name={annotation.createdBy} size="sm" />
        </Box>
        {!submitted && (
          <Box
            sx={{
              minWidth: "3rem",
              display: "flex",
              gap: "0.25rem",
            }}
          >
            <InputDialog
              title="Edit Annotation"
              initialValue={annotation.text}
              input={
                <TextField
                  multiline
                  fullWidth
                  value={annotation.text}
                  minRows={4}
                />
              }
              trigger={
                <EditIcon
                  sx={{
                    color: "#1976d2",
                    border: "1px solid #a4d0fd",

                    padding: "0.1rem",
                  }}
                />
              }
              onConfirm={(data) => {
                updateAnnotation?.({
                  ...annotation,
                  text: data,
                });
              }}
            />
            <ConfirmationDialog<AnnotationData>
              title="Delete Annotation?"
              message="Are you sure you want to delete this annotation?"
              data={annotation}
              trigger={
                <CloseIcon
                  sx={{
                    color: "#1976d2",
                    border: "1px solid #a4d0fd",
                    borderRadius: "4px",
                    padding: "0.1rem",
                  }}
                />
              }
              onConfirm={removeAnnotation}
            />
          </Box>
        )}
      </Box>
      <Typography
        variant="subtitle2"
        sx={{
          marginRight: 1,
          display: "inline-block",
        }}
        component="div"
      >
        {annotation.text}
      </Typography>
    </Stack>
  );
};
