import { Urgency } from "@athena/server/src/api/types/claim";
import {
  AdditionalStatus,
  Status,
} from "@athena/server/src/api/types/claimStatuses";
import { TRPCRootRouter } from "@athena/server/src/trpc/index";
import styled from "@emotion/styled";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Paper,
  SxProps,
  TextField,
  Theme,
  Tooltip,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { inferProcedureOutput } from "@trpc/server";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Control,
  Controller,
  ControllerRenderProps,
  FieldValues,
  Path,
  useForm,
} from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import { trpc } from "src/lib/api/trpc";
import { useViewMode } from "src/shared/hooks/useViewMode";
import { z } from "zod";

const FiltersFormSchema = z.object({
  additionalStatuses: z.array(z.nativeEnum(AdditionalStatus)).optional(),
  excludeAdditionalStatuses: z.array(z.nativeEnum(AdditionalStatus)).optional(),
  age: z.number().optional(),
  // Annoying preproccessing for MUI Autocomplete
  // Value can't be undefined otherwise AutoComplete will throw an error
  claimReference: z.preprocess((val) => {
    return val === null ? undefined : val;
  }, z.string().optional()),
  assignedEngineerId: z.string().optional(),
  eventIds: z.array(z.string()).optional(),
  insurerId: z.string().optional(),
  insurerReference: z.string().optional(),
  lossAdjusterId: z.string().optional(),
  organisationId: z.string().optional(),
  lossCauseId: z.string().optional(),
  region: z.string().optional(),
  regionalCoordinatorId: z.string().optional(),
  statuses: z.array(z.nativeEnum(Status)).optional(),
  statusGroup: z.string().optional(),
  mineOnly: z.boolean().optional(),
  includeDone: z.boolean().optional(),
  dateFrom: z.date({ coerce: true }).optional(),
  dateTo: z.date({ coerce: true }).optional(),
  urgency: z.preprocess((val) => {
    return val === null ? undefined : val;
  }, z.nativeEnum(Urgency).optional()),

  insurerName: z.string().optional(),
  lostCause: z.string().optional().nullable(),
  events: z.array(z.string()).optional().nullable(),
  regionalCoordinator: z.string().optional().nullable(),
  insurer: z.string().optional().nullable(),
  lossAdjuster: z.string().optional().nullable(),
  engineeringCompany: z.string().optional().nullable(),
  assignedEngineerName: z.string().optional().nullable(),
});

const UrlFiltersSchema = z
  .object({
    additionalStatuses: z
      .union([
        z.nativeEnum(AdditionalStatus),
        z.array(z.nativeEnum(AdditionalStatus)),
      ])
      .transform((rel) => {
        return Array.isArray(rel) ? rel : [rel];
      })
      .optional(),
    excludeAdditionalStatuses: z
      .union([
        z.nativeEnum(AdditionalStatus),
        z.array(z.nativeEnum(AdditionalStatus)),
      ])
      .transform((rel) => {
        return Array.isArray(rel) ? rel : [rel];
      })
      .optional(),
    eventIds: z
      .union([z.string(), z.array(z.string())])
      .transform((rel) => {
        return Array.isArray(rel) ? rel : [rel];
      })
      .optional(),
    statuses: z
      .union([z.nativeEnum(Status), z.array(z.nativeEnum(Status))])
      .transform((rel) => {
        return Array.isArray(rel) ? rel : [rel];
      })
      .optional(),
    events: z
      .union([z.string(), z.array(z.string())])
      .transform((rel) => {
        return Array.isArray(rel) ? rel : [rel];
      })
      .optional(),
    includeDone: z.coerce.boolean().optional(),
    mineOnly: z.coerce.boolean().optional(),
    filteredForName: z.string().optional(),
  })
  .merge(
    FiltersFormSchema.omit({
      additionalStatuses: true,
      excludeAdditionalStatuses: true,
      eventIds: true,
      statuses: true,
      events: true,
      includeDone: true,
      mineOnly: true,
    })
  );

export type FiltersValues = z.infer<typeof FiltersFormSchema>;

type NamedId = {
  name: string;
  id: string;
};

type AppliedFiltersProps = {
  filters: FiltersValues;
  availableFilters?: string[];
};

type ClaimFilterProps = {
  onChange: (filters: FiltersValues) => void;
  onFiltersClosed: VoidFunction;
  activeClaims: inferProcedureOutput<TRPCRootRouter["claims"]["getAllClaims"]>;
  isOpen: boolean;
};

interface ClaimFilterAutocompleteFieldProps<TControl extends FieldValues> {
  control: Control<TControl, any>;
  label: string;
  name: Path<TControl>;
  placeholder?: string;
  options: Array<string>;
  multiple?: boolean;
  onValueChange?: (value: string | string[] | null) => void;
}

export function ClaimFilterAutocompleteField<TControl extends FieldValues>({
  control,
  label,
  placeholder,
  name,
  options,
  onValueChange,
  multiple = true,
}: ClaimFilterAutocompleteFieldProps<TControl>) {
  const renderTextField = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      variant="outlined"
      sx={TextFieldStyle}
      label={label}
      InputLabelProps={{ shrink: true }}
      placeholder={placeholder}
    />
  );

  const renderMultiSelect = (
    field: ControllerRenderProps<TControl, Path<TControl>>
  ) => {
    const { onChange, ...props } = field;
    return (
      <Autocomplete
        disabled={options.length === 0}
        multiple={multiple}
        options={options}
        filterSelectedOptions
        {...props}
        value={props.value || []}
        onChange={(e, data) => {
          onChange(data);
          onValueChange?.(data);
        }}
        renderInput={renderTextField}
      />
    );
  };

  const renderSingleSelect = (
    field: ControllerRenderProps<TControl, Path<TControl>>
  ) => {
    const { onChange, ...props } = field;
    return (
      <Autocomplete
        disabled={options.length === 0}
        options={options}
        multiple={multiple}
        filterSelectedOptions
        {...props}
        value={props.value || null}
        onChange={(e, data) => {
          onChange(data);
          onValueChange?.(data);
        }}
        renderInput={renderTextField}
      />
    );
  };

  return (
    <Controller
      render={({ field }) => {
        return multiple ? renderMultiSelect(field) : renderSingleSelect(field);
      }}
      name={name}
      control={control}
    />
  );
}

export const NewEngineeringClaimFilters = (props: ClaimFilterProps) => {
  const { onChange, onFiltersClosed, isOpen, activeClaims } = props;
  const { viewMode } = useViewMode();
  const [params, setParams] = useSearchParams();
  const [selectedEngineeringCompany, setSelectedEngineeringCompany] = useState<
    NamedId[]
  >([]);
  const [selectedCoordinators, setSelectedCoordinators] = useState<NamedId[]>(
    []
  );
  const { data: engineeringCompanies, isLoading: isEngineeringLoading } =
    trpc.organisations.getEngineeringCompanies.useQuery();

  const paramsObject: Record<string, string | Array<string>> = Array.from(
    params.entries()
  ).reduce(
    (sum, [key, value]) => ({
      ...sum,
      [key]: value.includes(",") ? value.split(",") : value,
    }),
    {} as Record<string, string | Array<string>>
  );
  const filtersFromParams = UrlFiltersSchema.safeParse(paramsObject);

  const {
    control,
    handleSubmit,
    formState: { errors, touchedFields, dirtyFields },
    reset,
    resetField,
    setValue,
    ...rest
  } = useForm<FiltersValues>({
    resolver: zodResolver(FiltersFormSchema),
    defaultValues: {
      ...(filtersFromParams.success ? filtersFromParams.data : {}),
    },
  });

  useEffect(() => {
    if (
      filtersFromParams.success &&
      Object.keys(filtersFromParams.data).length > 0
    ) {
      onChange(filtersFromParams.data);
    }
  }, []);

  //Dirty hack to reset form when filters are cleared
  useEffect(() => {
    if (params.size === 0) {
      const fields = Object.keys({ ...touchedFields, ...dirtyFields });
      fields.forEach((field) =>
        setValue(field as Path<FiltersValues>, undefined)
      );
    }
  }, [params]);

  const onSubmit = useCallback(
    (values: FiltersValues) => {
      values = FiltersFormSchema.parse(values);
      onChange?.(values);
      const param = Object.entries(values).reduce(
        (sum, [key, value]) => ({
          ...sum,
          ...(Array.isArray(value) && value.length > 0
            ? { [key]: value.join(",") }
            : value && { [key]: value }),
        }),
        {}
      );
      setParams(param);
      onFiltersClosed();
    },
    [onChange, onFiltersClosed]
  );

  const statusOptions = useMemo(
    () =>
      activeClaims.reduce((acc, c) => {
        if (!c.status) return acc;
        if (!acc.includes(c.status)) {
          acc.push(c.status);
        }
        return acc;
      }, [] as string[]),
    [activeClaims]
  );

  const additionalStatusOptions = useMemo(
    () =>
      activeClaims.reduce((acc, x) => {
        if (!x.additionalStatuses) return acc;
        x.additionalStatuses.map((status) => {
          if (!acc.find((x) => x === status)) {
            acc.push(status);
          }
        });
        return acc;
      }, [] as string[]),
    [activeClaims]
  );

  const engineeringRefsOptions = [
    ...new Set(
      activeClaims.reduce((acc, x) => {
        if (!x.reference) return acc;
        return [...acc, x.reference];
      }, [] as string[])
    ),
  ];

  const engineeringDirectory = useMemo(() => {
    return activeClaims?.reduce((acc, claim) => {
      const { assignedEngineers, organisationId } = claim;
      if (!assignedEngineers || !assignedEngineers.length || !organisationId)
        return acc;
      const officeName = engineeringCompanies?.find(
        (org) => org.organisationId === organisationId
      )?.name;
      if (!officeName) return acc;

      if (!acc[officeName]) {
        acc[officeName] = [];
      }

      assignedEngineers.map((engineer) => {
        if (!acc[officeName].find((x) => x.name === engineer.name)) {
          acc[officeName].push(engineer);
        }
      });

      return acc;
    }, {} as Record<string, NamedId[]>);
  }, [activeClaims, engineeringCompanies]);

  const insurerOptions = useMemo(
    () =>
      activeClaims.reduce((acc, x) => {
        if (!x.insurer || !x.insurer.name || !x.insurer.id) return acc;

        if (!acc.find((y) => y.name === x.insurer!.name)) {
          acc.push({ name: x.insurer.name, id: x.insurer.id });
        }

        return acc;
      }, [] as NamedId[]),
    [activeClaims]
  );
  const insurerReferenceOptions = useMemo(
    () => [
      ...new Set(
        activeClaims.reduce((acc, x) => {
          if (!x.insurerReference) return acc;
          return [...acc, x.insurerReference];
        }, [] as string[])
      ),
    ],
    [activeClaims]
  );

  const lossTypeOptions = useMemo(
    () =>
      activeClaims.reduce((acc, x) => {
        if (!x.lossCause) return acc;
        if (!acc[x.lossCause.name]) {
          acc[x.lossCause.name] = x.lossCause.id;
        }
        return acc;
      }, {} as Record<string, string>),
    [activeClaims]
  );

  const lossAdjustorOptions = useMemo(
    () =>
      activeClaims.reduce((acc, x) => {
        if (!x.lossAdjusterId || !x.lossAdjusterName) return acc;

        if (!acc.find((y) => y.name === x.lossAdjusterName)) {
          acc.push({ name: x.lossAdjusterName, id: x.lossAdjusterId });
        }
        return acc;
      }, [] as NamedId[]),
    [activeClaims]
  );

  const regionOptions = useMemo(
    () => [...new Set(activeClaims.map((x) => x.location.region))],
    [activeClaims]
  ) as string[];

  const eventDirectory = useMemo(
    () =>
      activeClaims?.reduce((acc, claim) => {
        const { events } = claim;
        events.map((event) => {
          if (!acc[event.name]) {
            acc[event.name] = event.id;
          }
        });
        return acc;
      }, {} as Record<string, string>),
    [activeClaims]
  );

  const eventCoordinatorDirectory = useMemo(
    () =>
      activeClaims?.reduce((acc, claim) => {
        const { events, regionalCoordinator } = claim;
        if (!regionalCoordinator) return acc;

        events.map((event) => {
          if (!acc[event.name]) {
            acc[event.name] = [
              { name: regionalCoordinator.name, id: regionalCoordinator.id },
            ];
          } else {
            if (acc[event.name].find((x) => x.id === regionalCoordinator.id))
              return acc;
            acc[event.name].push({
              name: regionalCoordinator.name,
              id: regionalCoordinator.id,
            });
          }
        });
        return acc;
      }, {} as Record<string, [NamedId]>),
    [activeClaims]
  );

  const onEventChanged = (value: string | string[] | null) => {
    if (!value) {
      setValue("eventIds", undefined);
      return;
    }
    const selectedEvents: string[] = [];
    if (Array.isArray(value)) {
      value.map((x) => {
        selectedEvents.push(eventDirectory[x]);
      });
      setValue("eventIds", selectedEvents);
    }

    if (Array.isArray(value)) {
      const coordinators = [
        ...new Set(value.flatMap((x) => eventCoordinatorDirectory[x])),
      ];
      setSelectedCoordinators(coordinators);
      return;
    }
    setSelectedCoordinators(eventCoordinatorDirectory[value] || []);
  };

  if (!isOpen) {
    return <></>;
  }

  const setValueFromName = (
    name: string | string[] | null,
    options: NamedId[],
    field: Path<FiltersValues>
  ) => {
    if (!name) {
      setValue(field, undefined);
      return;
    }
    if (Array.isArray(name)) {
      const ids = name.map((n) => options.find((x) => x.name === n)?.id);
      setValue(field, ids as string[]);
      return;
    }
    const id = options.find((x) => x.name === name)?.id;
    setValue(field, id);
    return;
  };

  return (
    <Paper sx={{ borderRadius: 0, padding: "1.5rem" }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ClaimFilterSection>
          <h2 style={{ marginBottom: "1rem" }}>Filter</h2>
          <FiltersContainer>
            <ClaimFilterAutocompleteField
              multiple={false}
              placeholder="Select Insurer"
              control={control}
              label="Insurer"
              name="insurerName"
              onValueChange={(value) =>
                setValueFromName(value, insurerOptions, "insurerId")
              }
              options={insurerOptions.map((x) => x.name)}
            />
            <ClaimFilterAutocompleteField
              multiple={false}
              placeholder="Select Insurer Reference"
              control={control}
              label="Insurer Reference"
              name="insurerReference"
              options={insurerReferenceOptions}
            />

            <ClaimFilterAutocompleteField
              multiple={false}
              placeholder="Select Loss Adjustor"
              control={control}
              label="Loss Adjustor"
              name="lossAdjuster"
              onValueChange={(value) =>
                setValueFromName(value, lossAdjustorOptions, "lossAdjusterId")
              }
              options={lossAdjustorOptions.map((x) => x.name)}
            />

            <ClaimFilterAutocompleteField
              multiple={false}
              label="Engineering Reference"
              placeholder="Select Engineering Reference"
              control={control}
              name="claimReference"
              options={engineeringRefsOptions}
            />
            <ClaimFilterAutocompleteField
              multiple={false}
              placeholder="Engineering Company"
              control={control}
              label="Engineering Company"
              name="engineeringCompany"
              onValueChange={(value) => {
                if (!value || !engineeringDirectory) {
                  setSelectedEngineeringCompany([]);
                  setValue("organisationId", undefined);
                  return;
                }
                const selected = engineeringDirectory[value as string];
                setSelectedEngineeringCompany(selected);
                setValue(
                  "organisationId",
                  engineeringCompanies?.find((x) => x.name === value)
                    ?.organisationId
                );
              }}
              options={Object.keys(engineeringDirectory) || []}
            />
            <ClaimFilterAutocompleteField
              multiple={false}
              placeholder="Assigned Engineers"
              control={control}
              label="Assigned Engineers"
              name="assignedEngineerName"
              onValueChange={(value) =>
                setValueFromName(
                  value,
                  selectedEngineeringCompany,
                  "assignedEngineerId"
                )
              }
              options={selectedEngineeringCompany.map((x) => x.name)}
            />
            <ClaimFilterAutocompleteField
              placeholder="Select Status"
              control={control}
              label="Status"
              name="statuses"
              options={statusOptions}
            />
            <ClaimFilterAutocompleteField
              placeholder="Select Additional Status"
              control={control}
              label="Additional Status"
              name="additionalStatuses"
              options={additionalStatusOptions}
            />
            <ClaimFilterAutocompleteField
              multiple={false}
              label="Urgency"
              placeholder="Select Urgency"
              control={control}
              name="urgency"
              options={Object.values(Urgency)}
            />
            <ClaimFilterAutocompleteField
              placeholder="Select Event"
              control={control}
              label="Event"
              name="events"
              onValueChange={onEventChanged}
              options={Object.keys(eventCoordinatorDirectory)}
            />

            <ClaimFilterAutocompleteField
              multiple={false}
              placeholder="Select Regional Coordinator"
              control={control}
              label="Regional Coordinator"
              name="regionalCoordinator"
              onValueChange={(value) =>
                setValueFromName(
                  value,
                  selectedCoordinators,
                  "regionalCoordinatorId"
                )
              }
              options={selectedCoordinators.map((x) => x.name)}
            />

            <ClaimFilterAutocompleteField
              multiple={false}
              placeholder="Select Region"
              control={control}
              label="Region"
              name="region"
              options={regionOptions}
            />
            <ClaimFilterAutocompleteField
              multiple={false}
              placeholder="Select Loss Type"
              control={control}
              label="Loss Type"
              name="lostCause"
              onValueChange={(value) => {
                if (!value) {
                  setValue("lossCauseId", undefined);
                  return;
                }
                setValue("lossCauseId", lossTypeOptions[value as string]);
              }}
              options={Object.keys(lossTypeOptions)}
            />
            <Controller
              render={({ field }) => {
                return (
                  <DatePicker
                    sx={TextFieldStyle}
                    label="From Date:"
                    slotProps={{
                      textField: {
                        placeholder: "Date From",
                      },
                    }}
                    {...field}
                  />
                );
              }}
              name={"dateFrom"}
              control={control}
            />
            <Controller
              render={({ field }) => {
                return (
                  <DatePicker
                    sx={TextFieldStyle}
                    label="To Date:"
                    slotProps={{
                      textField: {
                        placeholder: "Date To",
                      },
                    }}
                    {...field}
                  />
                );
              }}
              name={"dateTo"}
              control={control}
            />
            <Controller
              render={({ field: props, fieldState: { error } }) => {
                return (
                  <FormControlLabel
                    sx={{ marginTop: "auto" }}
                    control={
                      <Checkbox {...props} checked={Boolean(props.value)} />
                    }
                    label="Include Done"
                  />
                );
              }}
              name={"includeDone"}
              control={control}
            />
            {viewMode === "loss-adjuster" && (
              <Controller
                render={({ field: props, fieldState: { error } }) => {
                  return (
                    <FormControlLabel
                      sx={{ marginTop: "auto" }}
                      control={
                        <Checkbox {...props} checked={Boolean(props.value)} />
                      }
                      label="Assigned to me only"
                    />
                  );
                }}
                name={"mineOnly"}
                control={control}
              />
            )}
          </FiltersContainer>
          <Button variant="contained" type="submit" sx={{ width: 200, mr: 2 }}>
            Apply
          </Button>
          <Button
            variant="outlined"
            onClick={() => {
              onFiltersClosed();
            }}
            sx={{ width: 200 }}
          >
            Cancel
          </Button>
        </ClaimFilterSection>
      </form>
    </Paper>
  );
};

export const AppliedFilters = ({
  filters,
  availableFilters = [],
}: AppliedFiltersProps) => {
  const filterAlias = {
    // claimReference: "Engineering Reference",
    // statuses: "Status",
    // additionalStatuses: "Additional Status",
    regionalCoordinatorId: "Regional Coordinator",
    assignedEngineerId: "Assigned Engineer",
    eventIds: "Events",
    // urgency: "Urgency",
    // formHelpers: "Form Helpers",

    insurerId: "Insurer",
    lossAdjusterId: "Loss Adjuster",
    lossCauseId: "Loss Type",
    region: "Region",
  } as Record<string, string>;

  if (Object.keys(filters).length <= 0) return "";

  const appliedFilters = Object.entries(filters).reduce((acc, [key, value]) => {
    if (availableFilters.includes(key) || availableFilters.length === 0) {
      if (Object.keys(filterAlias).includes(key)) return acc;
      return {
        ...acc,
        [key]: value,
      };
    }
    return acc;
  }, {} as FiltersValues);

  const count = Object.entries(appliedFilters).reduce((count, [key, value]) => {
    if (Array.isArray(value)) {
      count += value.length;
    } else if (value) {
      count++;
    }
    return count;
  }, 0);

  const TooltipComponent = () => {
    if (Object.keys(appliedFilters).length <= 0) return "Coming soon...";

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "0.2rem",
        }}
      >
        {Object.entries(appliedFilters).map(([key, value]) => {
          if (!value) return;
          if (Object.keys(filterAlias).includes(key) && !value) return;
          if (Array.isArray(value)) {
            return value.map((v) => (
              <span key={`${key}-${v}`}>{filterAlias[key] || v}</span>
            ));
          }
          if (value instanceof Date) {
            return (
              <span key={key}>
                {filterAlias[key] ||
                  `${
                    key == "dateFrom" ? "From: " : "To: "
                  }  ${value.toLocaleDateString()}`}
              </span>
            );
          }
          return <span key={key}>{filterAlias[key] || value}</span>;
        })}
      </div>
    );
  };

  const appliedStyle: SxProps = {
    color: "blue",
    fontStyle: "underline",
  };
  const notAppliedStyle: SxProps = {
    color: "black",
    fontStyle: "italic",
  };

  return (
    <Tooltip title={<TooltipComponent />}>
      <Box sx={count > 0 ? appliedStyle : notAppliedStyle}>
        {count == 0
          ? " - No filters applied"
          : ` - ${count} filter${count > 1 ? "s" : ""} applied`}
      </Box>
    </Tooltip>
  );
};

const ClaimFilterSection = styled.div`
  background-color: white;
  padding: 1.5rem;
`;

const FiltersContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  & > :not(:last-child) {
    margin-right: 8px;
  }
  & > * {
    width: 300px;
  }
  margin-bottom: 1rem;
`;

const TextFieldStyle: SxProps<Theme> = {
  marginTop: "1rem",
  ".MuiInputBase-root": {
    width: "300px",
    padding: "6px",
  },
  ".MuiChip-root": {
    height: "28px",
  },
  ".MuiInputBase-input": {},
};
