import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { axiosClient } from "src/lib/axiosClient";
import ClaimReportPDF, {
  ClaimReportInfo,
} from "src/modules/claims/claimReport/ClaimReportPDF.tsx";
import { NoReportCard } from "src/modules/claims/claimReport/components/NoReportCard";
import Menu from "../../menu/Menu";
import { EngineeringClaimDetails } from "./EngineeringClaimDetails";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export const LossAdjusterClaimPage = () => {
  const [value, setValue] = React.useState(0);
  const [params, setParams] = useSearchParams();
  const [noReport, setNoReport] = React.useState(true);
  const { claimId, organisationId } = useParams();

  useEffect(() => {
    if (!claimId || !organisationId) return;
    try {
      axiosClient
        .get<ClaimReportInfo>(
          `/claims/${claimId}/${organisationId}/report/latest`,
          {}
        )
        .then((res) => {
          if (res.data?.url) {
            setNoReport(false);
          }
        })
        .catch(() => {
          setNoReport(true);
        });
    } catch (error) {
      setNoReport(true);
    }
  }, [claimId, organisationId]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setParams({ page: `${newValue}` || "0" });
  };

  const page = Number(params.get("page") || 0);

  return (
    <Menu>
      <Box sx={{ maxWidth: "1800px", margin: "auto" }}>
        <Box sx={{ borderBottom: 2, borderColor: "divider", mt: 3 }}>
          <Tabs value={page} onChange={handleChange} variant="fullWidth">
            <Tab label="Claim Details" {...a11yProps(0)} />

            <Tab label="Report" {...a11yProps(1)} disabled={noReport} />
          </Tabs>
        </Box>
        <CustomTabPanel value={page} index={0}>
          <EngineeringClaimDetails />
        </CustomTabPanel>
        <CustomTabPanel value={page} index={1}>
          {noReport ? <NoReportCard /> : <ClaimReportPDF />}
        </CustomTabPanel>
      </Box>
    </Menu>
  );
};
