import {
  ClaimDetailsSection,
  DialogSection,
  DialogSectionBody,
  DialogSectionContentVertical,
  DialogSectionHeader,
} from "@athena/components";
import { TRPCRootRouter } from "@athena/server/src/trpc";
import { Box, Paper, Stack } from "@mui/material";
import { inferRouterOutputs } from "@trpc/server";

interface Props {
  claim: inferRouterOutputs<TRPCRootRouter>["claims"]["getClaim"];
}

export function Company({ claim }: Props) {
  return (
    <Box
      sx={{
        flexGrow: 1,
        mb: 3,
      }}
    >
      <Paper
        elevation={4}
        sx={{
          padding: "1.5rem",
          mb: "1.5rem",
          scrollMarginTop: "5.5rem",
        }}
        id="company"
      >
        <ClaimDetailsSection title="Engineering company details">
          <Stack
            direction={"column"}
            sx={{
              maxWidth: "1000px",
              mb: 2,
            }}
          >
            <DialogSection>
              <DialogSectionContentVertical>
                <DialogSectionHeader>Project number</DialogSectionHeader>
                <DialogSectionBody>
                  {claim.reference ? (
                    claim.reference
                  ) : (
                    <p style={{ color: "gray" }}>--</p>
                  )}
                </DialogSectionBody>
              </DialogSectionContentVertical>
            </DialogSection>
            <DialogSection>
              <DialogSectionContentVertical>
                <DialogSectionHeader>Claim name</DialogSectionHeader>
                <DialogSectionBody>
                  {claim.claimName ? (
                    claim.claimName
                  ) : (
                    <p style={{ color: "gray" }}>--</p>
                  )}
                </DialogSectionBody>
              </DialogSectionContentVertical>
            </DialogSection>
            <DialogSection>
              <DialogSectionContentVertical>
                <DialogSectionHeader>Assigned office</DialogSectionHeader>
                <DialogSectionBody>
                  {claim.assignedOffice?.name ? (
                    claim.assignedOffice?.name
                  ) : (
                    <p style={{ color: "gray" }}>--</p>
                  )}
                </DialogSectionBody>
              </DialogSectionContentVertical>
            </DialogSection>
            <DialogSection>
              <DialogSectionContentVertical>
                <DialogSectionHeader>Assigned Engineers</DialogSectionHeader>
                <DialogSectionBody>
                  {claim.assignedEngineers?.map((engineer) => (
                    <p key={engineer.id}>{engineer.name}</p>
                  ))}
                </DialogSectionBody>
              </DialogSectionContentVertical>
            </DialogSection>
          </Stack>
        </ClaimDetailsSection>
      </Paper>
    </Box>
  );
}
