import { Paper, Typography } from "@mui/material";

export const NoReportCard = () => {
  return (
    <Paper
      elevation={4}
      sx={{
        padding: "1.5rem",
        maxWidth: "800px",
        margin: "auto",
        mb: "1.5rem",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      <Typography variant="h5" component="h2">
        No report available
      </Typography>
      <Typography variant="body1" component="div">
        There is currently no report available review.
      </Typography>
      <Typography variant="body1" component="div">
        Please check back later or contact the assigned engineer for more info.
      </Typography>
    </Paper>
  );
};
