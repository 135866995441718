import { Feature } from "ol";
import { LineString, MultiPoint, Polygon } from "ol/geom";
import { Fill, Icon, Stroke, Style } from "ol/style";
import CircleStyle from "ol/style/Circle";
import { StyleFunction } from "ol/style/Style";
import { getMeasurement } from "src/modules/olr/OLRMeasureUtils";
import { createText } from "../../../olr/utils/getText";
import { PointIcon } from "./icons/PointIcon";
import { PolygonDashedIcon } from "./icons/PolygonDashedIcon";
import { PolygonFillIcon } from "./icons/PolygonFillIcon";
import { PolygonIcon } from "./icons/PolygonIcon";
import { PolylineDashedIcon } from "./icons/PolylineDashedIcon";
import { PolylineIcon } from "./icons/PolylineIcon";

/**
 * Style for the dot that shows under the cursor
 */
const pointerCircle = new CircleStyle({
  radius: 6,
  fill: new Fill({
    color: "#0067DF",
  }),
});

export const EditModeStyle = new Style({
  image: new CircleStyle({
    radius: 9,
    fill: new Fill({
      color: "#0067DF",
    }),
  }),
  geometry: function (feature) {
    const coordinates = (feature.getGeometry() as any)!.getCoordinates()[0];
    return new MultiPoint(coordinates);
  },
});

const assertBaseStyle = (baseStyle: Style | Style[] | void) => {
  if (!baseStyle) {
    throw new Error("baseStyle is required");
  }

  if (Array.isArray(baseStyle)) {
    throw new Error("baseStyle must be a single style");
  }

  return baseStyle;
};

/**
 *
 *
 * Handles the styles of when the user is drawing the polygon / line
 *
 *
 *
 */
export const MeasureDrawingStyle = new Style({
  fill: new Fill({
    color: "rgb(0, 103, 223, 0.2)",
  }),
  stroke: new Stroke({
    color: "#0067DF",
    width: 2,
  }),
  image: pointerCircle,
});

export const getStyleFn = (
  editStyle: StyleFunction | Style | Style[],
  presentStyle: StyleFunction | Style | Style[]
) => {
  return (feature?: Feature) => {
    if (feature) {
      return editStyle;
    }
    return presentStyle;
  };
};

export const PropertyBoundaryLegend = () => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <PolygonIcon color={"#FF0000"} />
    <span style={{ marginLeft: 4 }}>Property Boundary</span>
  </div>
);

const PropertyBoundaryBaseDrawingStyle = new Style({
  stroke: new Stroke({
    color: "#FF0000",
    width: 6,
  }),
  image: pointerCircle,
});

const PropertyBoundaryDrawingStyle: StyleFunction = (feature) => {
  const props = feature.getProperties();

  // Don't apply a style is if the feature is not visible
  if (props.visible !== undefined && !props.visible) {
    return new Style();
  }

  return PropertyBoundaryBaseDrawingStyle;
};

export const PropertyBoundaryStyle = getStyleFn(
  [PropertyBoundaryBaseDrawingStyle, EditModeStyle],
  PropertyBoundaryDrawingStyle
);

const pinBaseStyle = new Style({
  image: new Icon({
    anchor: [0.5, 0.5],
    anchorXUnits: "fraction",
    anchorYUnits: "fraction",
    src: "/images/icons/pin.png",
  }),
});

const PinDrawingStyle: StyleFunction = (feature) => {
  const props = feature.getProperties();
  if (props.visible !== undefined && !props.visible) {
    return new Style();
  }
  return pinBaseStyle;
};

export const MovablePinStyle = getStyleFn(
  [pinBaseStyle, pinBaseStyle],
  PinDrawingStyle
);

const MainDwellingBaseStyle = new Style({
  stroke: new Stroke({
    color: "#000000",
    width: 6,
  }),
  image: pointerCircle,
});

export const MainDwellingLegend = () => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <PolygonIcon color={"#000000"} />
    <span style={{ marginLeft: 4 }}>Main dwelling</span>
  </div>
);

const MainDwellingDrawingStyle: StyleFunction = (feature) => {
  const props = feature.getProperties();

  // Don't apply a style is if the feature is not visible
  if (props.visible !== undefined && !props.visible) {
    return new Style();
  }

  return MainDwellingBaseStyle;
};

export const MainDwellingStyle = getStyleFn(
  [MainDwellingBaseStyle, EditModeStyle],
  MainDwellingDrawingStyle
);

const AccesswayBaseDrawingStyle = new Style({
  stroke: new Stroke({
    color: "#808080",
    width: 6,
  }),
  image: pointerCircle,
});

const AccesswayDrawingStyle: StyleFunction = (feature) => {
  const props = feature.getProperties();

  // Don't apply a style is if the feature is not visible
  if (props.visible !== undefined && !props.visible) {
    return new Style();
  }

  return AccesswayBaseDrawingStyle;
};

export const AcceesswayLegend = () => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <PolylineIcon color={"#808080"} />
    <span style={{ marginLeft: 4 }}>Accessway</span>
  </div>
);

export const AccesswayStyle = getStyleFn(
  [AccesswayBaseDrawingStyle, EditModeStyle],
  AccesswayDrawingStyle
);

const AppurtenantStructureBaseStyle: StyleFunction = (feature, resolution) => {
  return new Style({
    stroke: new Stroke({
      color: "#CFCFCF",
      width: 2,
    }),
    fill: new Fill({ color: "rgba(223, 8,0, 0)" }),
    image: new CircleStyle({
      radius: 9,
      fill: new Fill({
        color: "#CFCFCF",
      }),
    }),
    text: createText(feature.get("name") || "", resolution),
  });
};

export const AppurtenantStructurePolyLegend = () => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <PolygonIcon color={"#CFCFCF"} />
    <span style={{ marginLeft: 4 }}>Appurtenant structure</span>
  </div>
);

export const AppurtenantStructurePointLegend = () => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <PointIcon color={"#CFCFCF"} />
    <span style={{ marginLeft: 4 }}>Appurtenant structure</span>
  </div>
);

const AppurtenantStructureDisplayStyle: StyleFunction = (
  feature,
  resolution
) => {
  const props = feature.getProperties();

  // Don't apply a style is if the feature is not visible
  if (props.visible !== undefined && !props.visible) {
    return new Style();
  }

  return AppurtenantStructureBaseStyle(feature, resolution);
};

const AppurtenantStructureEditStyle: StyleFunction = (feature, resolution) => {
  const base = assertBaseStyle(
    AppurtenantStructureDisplayStyle(feature, resolution)
  );

  return [base, EditModeStyle];
};

export const AppurtenantStructurePolyStyle = getStyleFn(
  AppurtenantStructureEditStyle,
  AppurtenantStructureDisplayStyle
);

export const AppurtenantStructurePointStyle = getStyleFn(
  AppurtenantStructureBaseStyle,
  AppurtenantStructureDisplayStyle
);

export const HeadscarpLegend = () => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <PolylineIcon color={"#df0800"} />
    <span style={{ marginLeft: 4 }}>Headscarp</span>
  </div>
);

const HeadscarpBaseStyle = new Style({
  stroke: new Stroke({
    color: "#df0800",
    width: 6,
  }),
  image: pointerCircle,
});

const HeadscarpDisplayStyle: StyleFunction = (feature) => {
  const props = feature.getProperties();

  // Don't apply a style is if the feature is not visible
  if (props.visible !== undefined && !props.visible) {
    return new Style();
  }

  return HeadscarpBaseStyle;
};

export const HeadscarpDrawingStyle = getStyleFn(
  [HeadscarpBaseStyle, EditModeStyle],
  HeadscarpDisplayStyle
);

export const EvacuatedLandLegend = () => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <PolygonFillIcon color={"#df0800"} fillColor="rgb(223, 8,0, 0.5)" />
    <span style={{ marginLeft: 4 }}>Evacuated Land</span>
  </div>
);

const EvacuatedLandBaseStyle = new Style({
  stroke: new Stroke({
    color: "#fff",
    width: 2,
  }),
  image: pointerCircle,
});

const EvacuatedLandDisplayStyle: StyleFunction = (feature) => {
  const props = feature.getProperties();
  // Don't apply a style is if the feature is not visible
  if (props.visible !== undefined && !props.visible) {
    return new Style();
  }

  if (["8m", "60m"].includes(props?.insured_state)) {
    return new Style({
      stroke: new Stroke({
        color: "#df0800",
        width: 2,
      }),
      fill: new Fill({
        color: "rgb(223, 8,0, 0.5)",
      }),
      image: pointerCircle,
    });
  }

  return new Style({
    stroke: new Stroke({
      color: "#df0800",
      width: 2,
    }),
    fill: new Fill({ color: "rgb(223, 8,0, 0)" }),
    image: pointerCircle,
  });
};

export const EvacuatedLandDrawingStyle = getStyleFn(
  [EvacuatedLandBaseStyle, EditModeStyle],
  EvacuatedLandDisplayStyle
);

export const InundatedLandLegend = () => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <PolygonFillIcon
      color={"rgb(178, 100, 178, 1)"}
      fillColor="rgb(178, 100, 178, 0.6)"
    />
    <span style={{ marginLeft: 4 }}>Inundated land</span>
  </div>
);

const InundatedLandBaseStyle = new Style({
  stroke: new Stroke({
    color: "#fff",
    width: 3,
  }),
  image: pointerCircle,
});

const InundatedLandDisplayStyle: StyleFunction = (feature) => {
  const props = feature.getProperties();

  // Don't apply a style is if the feature is not visible
  if (props.visible !== undefined && !props.visible) {
    return new Style();
  }

  if (["8m", "60m"].includes(props?.insured_state)) {
    return new Style({
      fill: new Fill({
        color: "rgba(178, 100, 178, 0.6)",
      }),
      stroke: new Stroke({
        color: "rgba(178, 100, 178, 0.6)",
        width: 3,
      }),
      image: pointerCircle,
    });
  }

  return new Style({
    stroke: new Stroke({
      color: "rgba(178, 100, 178, 0.6)",
      width: 3,
    }),
    fill: new Fill({ color: "rgba(223, 8,0, 0)" }),
    image: pointerCircle,
  });
};

export const InundatedLandDrawingStyle = getStyleFn(
  [InundatedLandBaseStyle, EditModeStyle],
  InundatedLandDisplayStyle
);
const RetainingWallBaseStyle = new Style({
  stroke: new Stroke({
    color: "rgb(0,240,255)",
    width: 2,
  }),
  image: pointerCircle,
});

export const RetainingWallLegend = () => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <PolylineIcon color={"rgb(0,240,255)"} />
    <span style={{ marginLeft: 4 }}>Retaining Wall</span>
  </div>
);

const RetainingWallDisplayStyle: StyleFunction = (feature, resolution) => {
  const props = feature.getProperties();
  if (props.visible !== undefined && !props.visible) {
    return new Style();
  }
  return new Style({
    stroke: new Stroke({
      color: "rgb(0,240,255)",
      width: 2,
    }),
    image: pointerCircle,
    text: createText(feature.get("label") || "", resolution),
  });
};

export const RetainingWallDrawingStyle = getStyleFn(
  [RetainingWallBaseStyle, EditModeStyle],
  RetainingWallDisplayStyle
);

const DwellingDamageBaseStyle = new Style({
  stroke: new Stroke({
    color: "#fff",
    width: 2,
  }),
  image: pointerCircle,
});

const DwellingDamageDisplayStyle: StyleFunction = (feature, resolution) => {
  const props = feature.getProperties();
  if (props.visible !== undefined && !props.visible) {
    return new Style({});
  }
  return new Style({
    stroke: new Stroke({
      color: "rgb(255, 214, 0)",
      width: 2,
    }),
    image: new CircleStyle({
      radius: 9,
      fill: new Fill({
        color: "rgb(255, 214, 0)",
      }),
    }),
    fill: new Fill({
      color: "rgba(0, 0, 0, 0)",
    }),
    text: createText(feature.get("label") || "", resolution),
  });
};

export const DwellingDamagePolyLegend = () => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <PolygonFillIcon
      color={"rgb(255, 214, 0)"}
      fillColor="rgba(255, 214, 0, 0.6)"
    />
    <span style={{ marginLeft: 4 }}>Dwelling damage</span>
  </div>
);

export const DwellingDamagePointLegend = () => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <PointIcon color={"rgb(255, 214, 0)"} />
    <span style={{ marginLeft: 4 }}>Dwelling damage</span>
  </div>
);

export const DwellingDamageLineLegend = () => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <PolylineIcon color={"rgb(255, 214, 0)"} />
    <span style={{ marginLeft: 4 }}>Dwelling damage</span>
  </div>
);

export const DwellingDamageStyle = getStyleFn(
  [DwellingDamageBaseStyle, EditModeStyle],
  DwellingDamageDisplayStyle
);

export const DwellingDamagePointStyle = getStyleFn(
  DwellingDamageBaseStyle,
  DwellingDamageDisplayStyle
);

const AppurtenantStructureDamageBaseStyle = new Style({
  stroke: new Stroke({
    color: "#fff",
    width: 2,
  }),
  image: pointerCircle,
});

const AppurtenantStructureDamageDisplayStyle: StyleFunction = (
  feature,
  resolution
) => {
  const props = feature.getProperties();
  if (props.visible !== undefined && !props.visible) {
    return new Style({});
  }
  return new Style({
    stroke: new Stroke({
      color: "rgb(161,135,0)",
      width: 2,
    }),
    image: new CircleStyle({
      radius: 9,
      fill: new Fill({
        color: "rgb(161,135,0)",
      }),
    }),
    fill: new Fill({
      color: "rgba(0, 0, 0, 0)",
    }),
    text: createText(feature.get("label") || "", resolution),
  });
};

export const AppurtenantStructureDamagePointStyle = getStyleFn(
  AppurtenantStructureDamageBaseStyle,
  AppurtenantStructureDamageDisplayStyle
);

export const AppurtenantStructureDamagePolyLegend = () => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <PolygonFillIcon
      color={"rgb(161,135,0)"}
      fillColor="rgba(161,135,0, 0.6)"
    />
    <span style={{ marginLeft: 4 }}>Appurtenant structure Damage</span>
  </div>
);

export const AppurtenantStructureDamagePointLegend = () => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <PointIcon color={"rgb(161,135,0)"} />
    <span style={{ marginLeft: 4 }}>Appurtenant structure Damage</span>
  </div>
);

export const AppurtenantStructureDamageLineLegend = () => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <PolylineIcon color={"rgb(161,135,0)"} />
    <span style={{ marginLeft: 4 }}>Appurtenant structure Damage</span>
  </div>
);

export const AppurtenantStructureDamageStyle = getStyleFn(
  [AppurtenantStructureDamageBaseStyle, EditModeStyle],
  AppurtenantStructureDamageDisplayStyle
);

const CrossSectionBaseStyle = new Style({
  stroke: new Stroke({
    color: "rgb(144, 238, 144)",
    width: 2,
  }),
  image: pointerCircle,
});

const CrossSectionDisplayStyle: StyleFunction = (feature, resolution) => {
  const props = feature.getProperties();
  if (props.visible !== undefined && !props.visible) {
    return new Style();
  }
  return new Style({
    stroke: new Stroke({
      color: "rgb(144, 238, 144)",
      width: 2,
    }),
    image: pointerCircle,
    text: createText(feature.get("label") || "", resolution),
  });
};

export const CrossSectionLineLegend = () => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <PolylineIcon color={"rgb(144, 238, 144)"} />
    <span style={{ marginLeft: 4 }}>Cross Section</span>
  </div>
);

export const CrossSectionStyle = getStyleFn(
  [CrossSectionBaseStyle, EditModeStyle],
  CrossSectionDisplayStyle
);

const ServiceDamageBaseStyle = new Style({
  stroke: new Stroke({
    color: "rgb(255,165,0)",
    width: 2,
  }),
  image: pointerCircle,
});

export const ServiceDamagePointLegend = () => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <PointIcon color={"rgb(255,165,0)"} />
    <span style={{ marginLeft: 4 }}>Service Damage</span>
  </div>
);

export const ServiceDamageLineLegend = () => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <PolylineIcon color={"rgb(255,165,0)"} />
    <span style={{ marginLeft: 4 }}>Service Damage</span>
  </div>
);

const ServiceDamageDisplayStyle: StyleFunction = (feature, resolution) => {
  const props = feature.getProperties();
  if (props.visible !== undefined && !props.visible) {
    return new Style({});
  }
  return new Style({
    stroke: new Stroke({
      color: "rgb(255,165,0)",
      width: 2,
    }),
    image: new CircleStyle({
      radius: 9,
      fill: new Fill({
        color: "rgb(255,165,0)",
      }),
    }),
    text: createText(feature.get("label") || "", resolution),
  });
};

export const ServiceDamagePointStyle = getStyleFn(
  ServiceDamageBaseStyle,
  ServiceDamageDisplayStyle
);

export const ServiceDamageStyle = getStyleFn(
  [ServiceDamageBaseStyle, EditModeStyle],
  ServiceDamageDisplayStyle
);

const BridgeAndCulvertBase = new Style({
  stroke: new Stroke({
    color: "rgb(0,0,0)",
    width: 2,
  }),
  fill: new Fill({
    color: "rgba(255, 215, 0, 0.6)",
  }),
  image: new CircleStyle({
    radius: 6,
    fill: new Fill({
      color: "rgba(255, 215, 0, 0.6)",
    }),
    stroke: new Stroke({
      color: "rgb(0,0,0)",
      width: 2,
    }),
  }),
});

export const BridgeAndCulvertPolyLegend = () => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <PolygonFillIcon
      color={"rgb(255, 215,0)"}
      fillColor="rgba(255, 215,0, 0.6)"
    />
    <span style={{ marginLeft: 4 }}>Bridge and Culvert</span>
  </div>
);

export const BridgeAndCulvertPointLegend = () => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <PointIcon color={"rgb(255, 215,0)"} />
    <span style={{ marginLeft: 4 }}>Bridge and Culvert</span>
  </div>
);

export const BridgeAndCulvertLineLegend = () => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <PolylineIcon color={"rgb(255, 215,0)"} />
    <span style={{ marginLeft: 4 }}>Bridge and Culvert</span>
  </div>
);

const BridgeAndCulvertDisplayStyle: StyleFunction = (feature, resolution) => {
  const props = feature.getProperties();
  if (props.visible !== undefined && !props.visible) {
    return new Style({});
  }
  return new Style({
    stroke: new Stroke({
      color: "rgb(0,0,0)",
      width: 2,
    }),
    fill: new Fill({
      color: "rgba(255, 215, 0, 0.6)",
    }),
    image: new CircleStyle({
      radius: 9,
      fill: new Fill({
        color: "rgba(255, 215, 0, 0.6)",
      }),
      stroke: new Stroke({
        color: "rgb(0,0,0)",
        width: 2,
      }),
    }),
    text: createText(feature.get("label") || "", resolution),
  });
};

export const BridgeAndCulvertPointStyle = getStyleFn(
  BridgeAndCulvertBase,
  BridgeAndCulvertDisplayStyle
);

export const BridgeAndCulvertStyle = getStyleFn(
  [BridgeAndCulvertBase, EditModeStyle],
  BridgeAndCulvertDisplayStyle
);

const ConceptualRemedialSolutionBaseStyle = new Style({
  stroke: new Stroke({
    color: "#fff",
    width: 2,
  }),
  image: pointerCircle,
});

const ConceptualRemedialSolutionDisplayStyle: StyleFunction = (
  feature,
  resolution
) => {
  const props = feature.getProperties();
  if (props.visible !== undefined && !props.visible) {
    return new Style();
  }
  return new Style({
    stroke: new Stroke({
      color: "rgb(0,0,205)",
      width: 2,
    }),
    image: new CircleStyle({
      radius: 9,
      fill: new Fill({
        color: "rgb(0,0,205)",
      }),
    }),
    fill: new Fill({
      color: "rgba(0, 0, 0, 0)",
    }),
    text: createText(feature.get("label") || "", resolution),
  });
};

export const ConceptualRemedialSolutionPolyLegend = () => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <PolygonIcon color={"rgb(0,0,205)"} />
    <span style={{ marginLeft: 4 }}>Conceptual remedial solution</span>
  </div>
);

export const ConceptualRemedialSolutionPointLegend = () => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <PointIcon color={"rgb(0,0,205)"} />
    <span style={{ marginLeft: 4 }}>Conceptual remedial solution</span>
  </div>
);

export const ConceptualRemedialSolutionLineLegend = () => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <PolylineIcon color={"rgb(0,0,205)"} />
    <span style={{ marginLeft: 4 }}>Conceptual remedial solution</span>
  </div>
);

export const GeneralObservationPolyLegend = () => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <PolygonIcon color={"rgb(173,216,230)"} />
    <span style={{ marginLeft: 4 }}>General observation</span>
  </div>
);

export const GeneralObservationPointLegend = () => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <PointIcon color={"rgb(173,216,230)"} />
    <span style={{ marginLeft: 4 }}>General observation</span>
  </div>
);

export const GeneralObservationLineLegend = () => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <PolylineIcon color={"rgb(173,216,230)"} />
    <span style={{ marginLeft: 4 }}>General observation</span>
  </div>
);

export const HistoricHeadscarpLegend = () => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <PolylineIcon color={"#008000"} />
    <span style={{ marginLeft: 4 }}>Historic headscarp</span>
  </div>
);

export const ConceptualRemedialSolutionStyle = getStyleFn(
  [ConceptualRemedialSolutionBaseStyle, EditModeStyle],
  ConceptualRemedialSolutionDisplayStyle
);

const GeneralObservationBaseStyle = new Style({
  stroke: new Stroke({
    color: "#fff",
    width: 2,
  }),
  image: pointerCircle,
});

const GeneralObservationDisplayStyle: StyleFunction = (feature, resolution) => {
  const props = feature.getProperties();
  if (props.visible !== undefined && !props.visible) {
    return new Style({});
  }
  return new Style({
    stroke: new Stroke({
      color: "#000",
      width: 2,
    }),
    image: new CircleStyle({
      radius: 9,
      fill: new Fill({
        color: "#000",
      }),
    }),
    fill: new Fill({
      color: "rgba(0, 0, 0, 0)",
    }),
    text: feature.get("label")
      ? createText(feature.get("label") || "", resolution)
      : undefined,
  });
};

export const GeneralObservationPointStyle = getStyleFn(
  GeneralObservationBaseStyle,
  GeneralObservationDisplayStyle
);

export const GeneralObservationStyle = getStyleFn(
  [GeneralObservationBaseStyle, EditModeStyle],
  GeneralObservationDisplayStyle
);

const GeneralObservationHeadscarpBaseStyle = new Style({
  stroke: new Stroke({
    color: "#008000",
    width: 6,
  }),
  image: pointerCircle,
});

const GeneralObservationHeadscarpDisplayStyle: StyleFunction = (
  feature,
  resolution
) => {
  const props = feature.getProperties();

  // Don't apply a style is if the feature is not visible
  if (props.visible !== undefined && !props.visible) {
    return new Style();
  }

  return new Style({
    stroke: new Stroke({
      color: "#008000",
      width: 6,
    }),
    image: pointerCircle,
    text: feature.get("label")
      ? createText(feature.get("label") || "", resolution)
      : undefined,
  });
};

export const GeneralObservationHeadscarpDrawingStyle = getStyleFn(
  [GeneralObservationHeadscarpBaseStyle, EditModeStyle],
  GeneralObservationHeadscarpDisplayStyle
);

const ImminentRiskOfEvacuationBaseStyle = new Style({
  stroke: new Stroke({
    color: "#fff",
    width: 2,
  }),
  image: pointerCircle,
});

export const ImminentRiskofEvacuationLegend = () => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <PolygonFillIcon
      color={"rgba(239, 89, 5, 1)"}
      fillColor="rgba(239, 89, 5, 0.6)"
    />
    <span style={{ marginLeft: 4 }}>Imminent Risk of Evacuation</span>
  </div>
);

// TODO Update this style to handle multiple types when they are
//  shared on a single layer
const ImminentRiskOfEvacuationDisplayStyle: StyleFunction = (feature) => {
  const props = feature.getProperties();
  // This poly is sharing the same source will all Iminent risks
  if (props.visible !== undefined && !props.visible) {
    return new Style();
  }

  if (["8m", "60m"].includes(props?.insured_state)) {
    return new Style({
      stroke: new Stroke({
        color: "rgba(239, 89, 5, 1)",
        width: 2,
      }),
      fill: new Fill({
        color: "rgba(239, 89, 5, 0.6)",
      }),
      image: pointerCircle,
    });
  }

  return new Style({
    stroke: new Stroke({
      color: "rgba(239, 89, 5, 1)",
      width: 2,
    }),
    fill: new Fill({ color: "rgb(0, 0,0, 0)" }),
    image: pointerCircle,
  });
};

export const ImminentRiskOfEvacuationDrawingStyle = getStyleFn(
  [ImminentRiskOfEvacuationBaseStyle, EditModeStyle],
  ImminentRiskOfEvacuationDisplayStyle
);

const ImminentRiskOfReInundationBaseStyle = new Style({
  stroke: new Stroke({
    color: "#fff",
    width: 2,
    lineDash: [10, 10],
  }),
  image: pointerCircle,
});

const ImminentRiskOfNewInundationDisplayStyle: StyleFunction = (feature) => {
  const props = feature.getProperties();

  // Don't apply a style is if the feature is not visible
  if (props.visible !== undefined && !props.visible) {
    return new Style();
  }

  if (["8m", "60m"].includes(props?.insured_state)) {
    return new Style({
      stroke: new Stroke({
        color: "rgba(70, 121, 226, 1)",
        width: 2,
      }),
      fill: new Fill({
        color: "rgba(70, 121, 226, 0.6)",
      }),
      image: pointerCircle,
    });
  }

  return new Style({
    stroke: new Stroke({
      color: "rgba(70, 121, 226, 1)",
      width: 2,
    }),
    fill: new Fill({
      color: "rgba(0, 0, 0, 0)",
    }),
    image: pointerCircle,
  });
};

const ImmediateRiskOfNewInundationBaseStyle = new Style({
  stroke: new Stroke({
    color: "#fff",
    width: 2,
  }),
  image: pointerCircle,
});

const ImminentRiskOfReInundationDrawingStyle: StyleFunction = (feature) => {
  const props = feature.getProperties();

  // Don't apply a style is if the feature is not visible
  if (props.visible !== undefined && !props.visible) {
    return new Style();
  }

  return new Style({
    stroke: new Stroke({
      color: "#4679e2",
      width: 2,
      lineDash: [10, 10],
    }),
    fill: new Fill({
      color: "rgba(0, 0, 0, 0)",
    }),
    image: pointerCircle,
  });
};

export const ImminentRiskofNewInundationLegend = () => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <PolygonFillIcon
      color={"rgba(70, 121, 226, 1)"}
      fillColor="rgba(70, 121, 226, 0.6)"
    />
    <span style={{ marginLeft: 4 }}>Imminent Risk of New Inundation</span>
  </div>
);

export const ImminentRiskOfInundationDrawingStyle = getStyleFn(
  [ImminentRiskOfReInundationBaseStyle, EditModeStyle],
  ImminentRiskOfReInundationDrawingStyle
);

export const ImminentRiskofReInundationLegend = () => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <PolygonDashedIcon color={"rgba(70, 121, 226, 1)"} />
    <span style={{ marginLeft: 4 }}>Imminent Risk of Re-inundation</span>
  </div>
);

export const ImminentRiskOfNewInundationDrawingStyle = getStyleFn(
  [ImmediateRiskOfNewInundationBaseStyle, EditModeStyle],
  ImminentRiskOfNewInundationDisplayStyle
);

const TensionCracksBaseStyle = new Style({
  stroke: new Stroke({
    color: "rgba(0,0,0,1)",
    width: 2,
    lineDash: [10, 10],
  }),
  image: pointerCircle,
});

export const TensionCracksLegend = () => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <PolylineDashedIcon color={"#000"} />
    <span style={{ marginLeft: 4 }}>Tension Cracks</span>
  </div>
);

const TensionCracksDisplayStyle: StyleFunction = (feature) => {
  const props = feature.getProperties();

  // Don't apply a style is if the feature is not visible
  if (props.visible !== undefined && !props.visible) {
    return new Style();
  }

  return TensionCracksBaseStyle;
};

export const TensionCracksDrawingStyle = getStyleFn(
  [TensionCracksBaseStyle, EditModeStyle],
  TensionCracksDisplayStyle
);

/**
 *
 *
 * Handles the styles of when the map is rendering a placed polygon / line
 *
 *
 */

const MeasureLayerBaseStyle: StyleFunction = (feature, resolution) => {
  let measurementText = "";
  if (feature.getGeometry() instanceof Polygon) {
    measurementText =
      getMeasurement(feature.getGeometry() as any).toFixed(2) + "m²";
  } else if (feature.getGeometry() instanceof LineString) {
    measurementText =
      getMeasurement(feature.getGeometry() as any).toFixed(2) + "m";
  }

  const props = feature.getProperties();

  // Don't apply a style is if the feature is not visible
  if (props.visible !== undefined && !props.visible) {
    return new Style();
  }
  return new Style({
    stroke: new Stroke({
      color: "#0067DF",
      width: 2,
    }),
    fill: new Fill({
      color: "rgba(0, 0, 0, 0)",
    }),
    text: createText(measurementText, resolution),
  });
};

const MeasureLayerBaseEditStyle: StyleFunction = (feature, resolution) => {
  let measurementText = "";
  if (feature.getGeometry() instanceof Polygon) {
    measurementText =
      getMeasurement(feature.getGeometry() as any).toFixed(2) + "m²";
  } else if (feature.getGeometry() instanceof LineString) {
    measurementText =
      getMeasurement(feature.getGeometry() as any).toFixed(2) + "m";
  }

  const props = feature.getProperties();

  // Don't apply a style is if the feature is not visible
  if (props.visible !== undefined && !props.visible) {
    return new Style();
  }
  return new Style({
    stroke: new Stroke({
      color: "#ffffff",
      width: 2,
    }),
    fill: new Fill({
      color: "rgba(0, 0, 0, 0)",
    }),
    text: createText(measurementText, resolution),
  });
};
const MeasureEditStyle: StyleFunction = (feature, resolution) => {
  const base = assertBaseStyle(MeasureLayerBaseEditStyle(feature, resolution));

  return [base, EditModeStyle];
};

export const MeasureLayerDrawingStyle = getStyleFn(
  MeasureEditStyle,

  MeasureLayerBaseStyle
);

export const InsuredLayerBaseStyle = new Style({
  stroke: new Stroke({
    color: "#ffd700",
    width: 2,
    lineDash: [10, 10],
  }),
});

export const InsuredAreaLegend = () => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <PolylineDashedIcon color={"#ffd700"} />
    <span style={{ marginLeft: 4 }}>Insured Area</span>
  </div>
);

export const InsuredLayerStyle: StyleFunction = (feature) => {
  const props = feature.getProperties();

  // Don't apply a style is if the feature is not visible
  if (props.visible !== undefined && !props.visible) {
    return new Style();
  }

  return InsuredLayerBaseStyle;
};
