import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";

export interface ConfirmationDialogProps<T> {
  title: string | React.ReactNode;
  message: string | React.ReactNode;
  trigger?: string | React.ReactNode;
  data?: T;
  onConfirm?: (data: T | undefined) => void;
  onCancel?: (data: T | undefined) => void;
}

export function ConfirmationDialog<T>(props: ConfirmationDialogProps<T>) {
  const { title, message, trigger, onConfirm, onCancel, data } = props;
  const [open, setOpen] = React.useState(false);

  const Trigger = () => {
    const handleClick = (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      setOpen(!open);
    };
    if (!trigger) return <Button onClick={handleClick}>Open</Button>;
    if (typeof trigger === "string") {
      return <Button onClick={handleClick}>{trigger}</Button>;
    }
    if (React.isValidElement(trigger)) {
      const oldClick = trigger.props.onClick;
      return React.cloneElement(trigger, {
        onClick: (event) => {
          oldClick?.(event);
          handleClick(event);
        },
      } as React.DOMAttributes<HTMLElement>);
    }
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          onCancel?.(data);
        }}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              onConfirm?.(data);
              setOpen(false);
            }}
            color="primary"
          >
            Confirm
          </Button>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              onCancel?.(data);
              setOpen(false);
            }}
            color="primary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Trigger />
    </>
  );
}
