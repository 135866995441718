import { ClaimDetailsSection, LoadingSpinner } from "@athena/components";
import { TRPCRootRouter } from "@athena/server/src/trpc";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import { Button, Paper, Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { inferRouterOutputs } from "@trpc/server";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { axiosClient } from "src/lib/axiosClient";
import { getTimelineEventContent } from "src/modules/claims/ClaimTimeline";
import { ErrorBoundary } from "src/shared/ErrorBoundary";
import { useViewMode } from "src/shared/hooks/useViewMode";
import {
  enqueueErrorSnackbar,
  enqueueSavingSnackbar,
  enqueueSuccessSnackbar,
} from "src/shared/snackbar/SnackbarHelper";
import styled from "styled-components";

interface Props {
  claim: inferRouterOutputs<TRPCRootRouter>["claims"]["getClaim"];
}

export const TimelineComponent = ({ claim }: Props) => {
  const { isLoading, data } = useQuery(
    `claimHistory${claim?.claimId}`,
    async () =>
      (
        await axiosClient.get(
          `/claims/${claim?.claimId}/${claim.organisationId}/history`
        )
      ).data,
    { enabled: !!claim?.claimId }
  );
  if (isLoading) return <LoadingSpinner />;
  if (!data) return null;

  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
        [`& .MuiTimelineContent-root`]: {
          paddingRight: 0,
        },
        paddingRight: "0",
      }}
    >
      {isLoading && <LoadingSpinner />}
      {data?.map((event: any, i: number) => {
        return (
          <TimelineItem key={event.claimHistoryEventId}>
            {getTimelineEventContent(event, i + 1 != data.length, i != 0)}
          </TimelineItem>
        );
      })}
    </Timeline>
  );
};

export const TimelineFallback = () => {
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Typography variant="h5">
        Sorry, something went wrong loading this claims timeline.
      </Typography>
      <Typography>If the problem persists, please contact us.</Typography>
    </Box>
  );
};

export const TimelineView = ({ claim }: Props) => {
  const [comment, setComment] = useState("");
  const { viewMode } = useViewMode();
  const queryClient = useQueryClient();

  const { mutate: saveComment } = useMutation(
    async () => {
      if (viewMode != "loss-adjuster") return;
      const finishSaving = enqueueSavingSnackbar();

      const res = (
        await axiosClient.post(
          `/claims/${claim?.claimId}/comments/${claim?.organisationId}`,
          {
            message: comment,
          }
        )
      ).data;
      finishSaving();
      return res;
    },
    {
      onSuccess: () => {
        enqueueSuccessSnackbar();
      },
      onError: () => {
        enqueueErrorSnackbar();
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: [`claimHistory${claim?.claimId}`],
        });
      },
    }
  );

  return (
    <Paper
      elevation={4}
      sx={{
        padding: "1.5rem",
        mb: "1.5rem",
        scrollMarginTop: "5.5rem",
      }}
      id="timeline"
    >
      {viewMode === "loss-adjuster" && (
        <ClaimDetailsSection
          title="Comment"
          shared
          tooltip="This section is shared across organisations"
          id={``}
        >
          <TopLevelCommentBox>
            <TextField
              multiline
              sx={{ width: "100%" }}
              placeholder="Enter a comment here"
              value={comment}
              onChange={(e) => {
                setComment(e.target.value);
              }}
            />

            <div className="splitter">
              <Button
                variant="contained"
                sx={{ minWidth: "200px" }}
                disabled={comment.length === 0}
                onClick={() => {
                  saveComment();
                  setComment("");
                }}
              >
                Submit
              </Button>
            </div>
          </TopLevelCommentBox>
        </ClaimDetailsSection>
      )}
      <ClaimDetailsSection title="Timeline">
        <Stack
          direction={"column"}
          sx={{
            maxWidth: "1000px",
            mb: 2,
            marginLeft: "-1.5rem",
          }}
        >
          <ErrorBoundary fallback={<TimelineFallback />}>
            <TimelineComponent claim={claim} />
          </ErrorBoundary>
        </Stack>
      </ClaimDetailsSection>
    </Paper>
  );
};

const TopLevelCommentBox = styled.div`
  border: 1px solid lightgray;
  border-radius: 12px;
  padding: 1rem;
  margin-bottom: 2rem;

  div.splitter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1rem;
  }

  @media (max-width: 900px) {
    div.splitter {
      flex-direction: column;
      gap: 1rem;
    }
  }
`;
