import { AssessmentAttachment } from "@athena/server/src/api/types/claimAssessment";
import { Tooltip } from "@mui/material";
import { config } from "src/lib/config";

type PhotographsProps = {
  photographs: AssessmentAttachment[];
  address: string;
};

export const Photographs = ({ photographs, address }: PhotographsProps) => {
  if (!photographs.length) return null;
  return (
    <span style={{ width: "100%" }}>
      <h3>
        <Tooltip
          arrow
          enterDelay={0}
          title="This is a placeholder for the photograph title, it will be replaced with the actual title from the report template on preview or export"
        >
          <span
            id="photograph_title"
            style={{
              backgroundColor: "lightgrey",
              paddingLeft: "0.25rem",
              paddingRight: "0.25rem",
              borderRadius: "4px",
            }}
          >
            Photograph Title Placeholder
          </span>
        </Tooltip>
      </h3>
      <span
        id="photograph_heading"
        style={{
          marginTop: "1rem",
          marginBottom: 0,
        }}
      >
        Photographs 1 to {photographs.length} - {address}
      </span>
      {photographs.map((photograph, i) => {
        return (
          <div
            key={photograph.imageUrl || photograph.annotatedImageUrl}
            id="photograph_container"
            style={{
              display: "flex",
              width: "100%",
              minHeight: 435,
              flexDirection: "column",
              pageBreakAfter: "auto",
              marginTop: i != 0 ? "-1px" : 0,
            }}
          >
            <div
              style={{
                padding: "0.5rem",
                flex: 1,
                display: "flex",
                justifyContent: "center",
                maxHeight: 400,
                minHeight: 400,
                width: "100%",
              }}
            >
              <img
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  objectFit: "contain",
                }}
                src={`${config.apiUrl}/${
                  photograph.annotatedImageUrl || photograph.imageUrl
                }`}
              />
            </div>
            <div
              id="photograph_caption"
              style={{
                height: "fit-content",
              }}
            >
              Photograph {i + 1}: {photograph.caption}
            </div>
          </div>
        );
      })}
    </span>
  );
};
