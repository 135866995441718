import _ from "lodash";
import { z } from "zod";

export const ReportTemplatePaddingSchema = z.object({
  top: z.string().catch("0"),
  right: z.string().catch("0"),
  bottom: z.string().catch("0"),
  left: z.string().catch("0"),
});

export const PreprocessedPaddingSchema = z.preprocess((data) => {
  const values = data as TReportTemplatePadding;
  return {
    top: values.top || "0",
    right: values.right || "0",
    bottom: values.bottom || "0",
    left: values.left || "0",
  };
}, ReportTemplatePaddingSchema);

export const ReportFontStylesSchema = z.object({
  fontFamily: z.string(),
  fontSize: z.number(),
  fontWeight: z.string(),
  color: z.string(),
  textAlign: z.string(),
  leading: z.string(),
  margin: PreprocessedPaddingSchema.optional(),
  padding: PreprocessedPaddingSchema.optional(),
});
export const ReportTableBorderStylesSchema = z.object({
  width: z.string(),
  style: z.string(),
  color: z.string(),
  collapse: z.string(),
});

export const ReportTableStylesSchema = z.object({
  width: z.string(),
  title: z.object({
    font: ReportFontStylesSchema,
    background: z.string(),
  }),
  content: z.object({
    font: ReportFontStylesSchema,
    background: z.string(),
  }),
  border: ReportTableBorderStylesSchema,
});

export const ReportTemplateOtherStylesSchema = z.object({
  superscript: ReportFontStylesSchema,
});

export const ReportTemplateStylesSchema = z.object({
  other: ReportTemplateOtherStylesSchema,
  font: ReportFontStylesSchema,
  h1: ReportFontStylesSchema,
  h2: ReportFontStylesSchema,
  h3: ReportFontStylesSchema,
  p: ReportFontStylesSchema,
  body: z.object({
    font: ReportFontStylesSchema,
    justify: z.boolean(),
  }),
  table: ReportTableStylesSchema,
  photograph: z.object({
    title: z.string(),
    font: ReportFontStylesSchema,
    width: z.string(),
    caption: ReportFontStylesSchema,
    border: ReportTableBorderStylesSchema,
  }),
});

export type TReportTemplate = z.infer<typeof ReportTemplate>;
export type TReportTemplateStyles = z.infer<typeof ReportTemplateStylesSchema>;
export type TReportFontStyles = z.infer<typeof ReportFontStylesSchema>;
export type TReportTableStyles = z.infer<typeof ReportTableStylesSchema>;
export type TReportTemplatePadding = z.infer<
  typeof ReportTemplatePaddingSchema
>;
export type TReportTableBorderStyles = z.infer<
  typeof ReportTableBorderStylesSchema
>;

export const defaultStyles: TReportTemplateStyles = {
  font: {
    fontFamily: "Calibri",
    fontSize: 10,
    fontWeight: "normal",
    color: "#000000",
    textAlign: "start",
    leading: "1.25",
    margin: { top: "28", right: "57", bottom: "57", left: "57" },
  },
  h1: {
    fontFamily: "Calibri",
    fontSize: 13,
    fontWeight: "bold",
    color: "#000000",
    textAlign: "start",
    leading: "1.25",
    margin: { top: "0", right: "0", bottom: "0", left: "0" },
    padding: { top: "0", right: "0", bottom: "0", left: "0" },
  },
  h2: {
    fontFamily: "Calibri",
    fontSize: 14,
    fontWeight: "bold",
    color: "#000000",
    textAlign: "start",
    leading: "1.25",
    margin: { top: "0", right: "0", bottom: "0", left: "0" },
  },
  h3: {
    fontFamily: "Calibri",
    fontSize: 10,
    fontWeight: "bold",
    color: "#000000",
    textAlign: "start",
    leading: "1.25",
    margin: { top: "0", right: "0", bottom: "0", left: "0" },
  },
  p: {
    fontFamily: "Calibri",
    fontSize: 10,
    fontWeight: "normal",
    color: "#000000",
    textAlign: "start",
    leading: "1.25",
  },
  body: {
    font: {
      fontFamily: "Calibri",
      fontSize: 11,
      fontWeight: "normal",
      color: "#000000",
      textAlign: "start",
      leading: "1.5",
      margin: { top: "28", right: "57", bottom: "57", left: "57" },
    },
    justify: false,
  },
  table: {
    width: "100",
    title: {
      font: {
        fontFamily: "Calibri",
        fontSize: 11,
        fontWeight: "bold",
        color: "#000000",
        textAlign: "start",
        leading: "1.25",
        padding: { top: "1.5", right: "5.7", bottom: "1.5", left: "5.7" },
      },
      background: "#C2C2C2",
    },
    content: {
      font: {
        fontFamily: "Calibri",
        fontSize: 11,
        fontWeight: "normal",
        color: "#000000",
        textAlign: "start",
        leading: "1.25",
        padding: { top: "1.5", right: "5.7", bottom: "1.5", left: "5.7" },
      },
      background: "#FFFFFF",
    },

    border: {
      width: "1",
      color: "#000000",
      style: "solid",
      collapse: "collapse",
    },
  },
  other: {
    superscript: {
      fontFamily: "Calibri",
      fontSize: 8,
      fontWeight: "normal",
      color: "#000000",
      textAlign: "start",
      leading: "1.25",
    },
  },

  photograph: {
    title: "FIGURES",
    font: {
      fontFamily: "Calibri",
      fontSize: 10,
      fontWeight: "normal",
      color: "#000000",
      textAlign: "start",
      leading: "1.25",
    },
    width: "100",
    border: {
      width: "1",
      color: "#000000",
      style: "solid",
      collapse: "collapse",
    },
    caption: {
      fontFamily: "Calibri",
      fontSize: 10,
      fontWeight: "normal",
      color: "#000000",
      textAlign: "start",
      leading: "1.25",
    },
  },
};
export const ReportTemplate = z.object({
  reportTemplateId: z.string().uuid(),
  organisationId: z.string().uuid(),
  isPrimary: z.boolean(),
  name: z.string().optional().default("Report Template"),
  isActive: z.boolean().optional().default(false),
  template: z.string(),
  header: z.string(),
  footer: z.string(),
  additionalCss: z.string().optional(),
  styles: ReportTemplateStylesSchema.catch(defaultStyles),
  images: z.array(z.object({ id: z.string().uuid() })),
  created: z.string().optional(),
  insUpdTs: z.string().optional(), // one-table managed
  footerHeight: z.string().optional(),
});

export const mergeWithDefaultStyles = (styles: TReportTemplateStyles) => {
  return _.merge({}, defaultStyles, styles);
};

export const generateStyleSheet = (
  styles: TReportTemplateStyles,
  prefix: string = ""
) => {
  const mergedStyles = mergeWithDefaultStyles(styles);

  const generatePadding = (padding: TReportFontStyles["padding"]) => {
    if (!padding) return "";
    return `padding: ${padding.top}pt ${padding.right}pt ${padding.bottom}pt ${padding.left}pt;`;
  };

  const generateMargin = (margin: TReportFontStyles["margin"]) => {
    if (!margin) return "";
    return `margin: ${margin.top}pt ${margin.right}pt ${margin.bottom}pt ${margin.left}pt;`;
  };

  const generateBorder = (border: TReportTableBorderStyles) => {
    return `border: ${border.width}pt ${border.style} ${border.color};`;
  };

  const generateFontStyles = (
    style: TReportFontStyles,
    padding = true,
    margin = true
  ) => {
    return `font-family: ${style.fontFamily};
  font-size: ${style.fontSize}pt;
  font-weight: ${style.fontWeight};
  color: ${style.color};
  text-align: ${style.textAlign};
  line-height: ${style.leading};
  ${padding ? generatePadding(style.padding) : ""}
  ${margin ? generateMargin(style.margin) : ""}`;
  };
  // prettier-ignore
  return `

${prefix || "body"} {
  ${generateFontStyles(mergedStyles.body.font)}
}
${[mergedStyles.h1, mergedStyles.h2, mergedStyles.h3]
    .map((style, i) => {
      return `
${prefix} h${i + 1} {
  ${generateFontStyles(style, true, false)}
}
${prefix} h${i + 1} #header_number {
  ${generateMargin(style.margin)}
}`;
    })
    .join("\n")}

${prefix} p {
  ${generateFontStyles(mergedStyles.p)}
}

${prefix} {
   table, th, td, tr {
    border: ${mergedStyles.table.border.width}pt ${mergedStyles.table.border.style} ${mergedStyles.table.border.color};
    border-collapse: ${mergedStyles.table.border.collapse};
  }
}

${prefix} table {
  width: ${mergedStyles.table.width}%;
  table-layout: fixed;
  overflow:hidden;
  page-break-before: avoid;
}

${prefix} table th {
    background-color: ${mergedStyles.table.title.background};
    vertical-align: top;
  }

${prefix} table th > p {
    ${generateFontStyles(mergedStyles.table.title.font)}
  }

  ${prefix} table td {
    background-color: ${mergedStyles.table.content.background};
    vertical-align: top;
  }
  ${prefix} table {
   ul {
      padding-left: 1.25rem;
      li {
        ${generateFontStyles(mergedStyles.table.content.font)}
      }
    }
    ol {
      padding-left: 1.25rem;
      margin: 0;
      li {
        ${generateFontStyles(mergedStyles.table.content.font)}
      }
    }
    }

${prefix} table td > p {
    ${generateFontStyles(mergedStyles.table.content.font)}
  }
  .selectedCell:after {
    background: rgba(200, 200, 255, 0.4);
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    pointer-events: none;
    position: absolute;
    z-index: 2;
  }
  .column-resize-handle {
    background-color: #adf;
    bottom: -2px;
    position: absolute;
    right: -2px;
    pointer-events: none;
    top: 0;
    width: 4px;
  }
.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}
${prefix} #photographs {
  break-before: page;
  page-break-before: always;
  width: ${mergedStyles.photograph.width}%;
  ${generateFontStyles(mergedStyles.photograph.font)}
}
${prefix} #photograph_title {
  ${generateFontStyles(mergedStyles.h1, false, true)}
  padding-top: 0;
}

${prefix} #photograph_container {
  ${generateBorder(mergedStyles.photograph.border)}
  border-collapse: ${mergedStyles.photograph.border.collapse};
}

${prefix} #photograph_caption {
 ${generateBorder(mergedStyles.photograph.border).replace(
    "border",
    "border-top"
  )};
 border-collapse: ${mergedStyles.photograph.border.collapse};
}

${prefix} #photograph_caption, ${prefix} #photograph_caption p{
  ${generateFontStyles(mergedStyles.photograph.caption)}
}
${prefix} #photograph_caption p{
  ${generateFontStyles(mergedStyles.photograph.caption, false, false)}
}
${prefix} #photograph_heading {
  ${generateFontStyles(mergedStyles.photograph.font, false, false)}
}

${prefix} sup {
  vertical-align: super;
  ${generateFontStyles(mergedStyles.other.superscript)}
}
`;
};
